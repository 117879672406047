/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import classes from './InputRow.module.css';

const CustomInput = ({ detail, setDetail }) => {
  const [inputValue, setInputValue] = useState(detail.value);
  const [type] = useState(detail.type === 'date' ? 'text' : detail.type);
  useEffect(() => {
    setInputValue(detail.value);
  });
  return (
    <div key={detail.name} className={classes.container}>
      <span className={classes.title}>{detail.label || detail.name}</span>
      <input
        className={classes.customInput}
        type={type}
        value={inputValue}
        placeholder={
          detail.type === 'date'
            ? 'Please add time units (Eg. 5 days, 1 year)'
            : ''
        }
        onChange={event => {
          setDetail(event.target.value);
          setInputValue(event.target.value);
        }}
      />
    </div>
  );
};

export default CustomInput;
