export const providerHeading = [
  {
    id: 1,
    label: 'Loyalty OTP Configuration'
  },
  {
    id: 2,

    label: 'Offerio OTP Configuration'
  },
  {
    id: 3,
    label: 'Flipsell OTP Configuration'
  },
  {
    id: 4,
    label: 'One Flow OTP Configuration'
  },
  {
    id: 5,
    label: 'Other OTP Configurations'
  }
];

export const otpInitialState = {
  templateId: '',
  template: ''
};
