/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState } from 'react';
import _ from 'lodash';
import {
  TableRow,
  TableCell,
  TableHead,
  Table,
  TableBody,
  Paper,
  TableContainer
} from '@material-ui/core';
import { styled } from '@material-ui/styles';
import { useTable, useSortBy } from 'react-table';
import { ColumnHiding } from '../../../constants/StoreHealth';
import classes from './index.module.scss';
import TableBodyLoader from '../../../shared/components/TableBodyLoader/TableBodyLoader';
import styles from '../TransactionWiseReport/TransactionWiseReportTable/TransactionWiseReportTable.module.scss';

const StyledTableRow = styled(TableRow)({
  '&.MuiTableCell-root': {
    borderLeft: '1px solid rgba(224, 224, 224, 1) !important'
  }
});
const StoreHealthList = ({
  columns,
  columnsHideStautus,
  data,
  setSorting,
  isLoading
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [hideColumn, setHideColumn] = useState(false);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns
  } = useTable(
    {
      columns,
      data
    },
    useSortBy
  );

  const handleClose = () => {
    setHideColumn(false);
    setAnchorEl(null);
  };

  const handleOpen = e => {
    setHideColumn(true);
    setAnchorEl(e.currentTarget);
  };

  const handleSoringdata = column => {
    const type = column.isSorted ? (column.isSortedDesc ? '' : 'desc') : 'asc';
    setSorting({
      column: column.id,
      type: type
    });
  };

  return (
    <TableContainer
      component={Paper}
      elevation={0}
      id={styles.transactionReport}
      className={classes.storeHealthList}
      style={{ margin: '30px !important' }}
    >
      {columnsHideStautus && (
        <ColumnHiding
          allColumns={allColumns}
          handleOpen={handleOpen}
          handleClose={handleClose}
          hideColumn={hideColumn}
          anchorEl={anchorEl}
        />
      )}
      <Table {...getTableProps()} className={styles.transactionReportTable}>
        <TableHead>
          {headerGroups.map((headerGroup, idx) => (
            <StyledTableRow {...headerGroup.getHeaderGroupProps()} key={idx}>
              {headerGroup.headers.map((column, index) => {
                return (
                  <TableCell
                    onClick={() => handleSoringdata(column)}
                    key={index}
                  >
                    <div
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <span
                        className={classes.columnHeadingStyle}
                        style={{ fontWeight: 600 }}
                      >
                        {column.render('Header')}
                      </span>
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' 🔽'
                            : ' 🔼'
                          : ''}
                      </span>
                    </div>
                  </TableCell>
                );
              })}
            </StyledTableRow>
          ))}
        </TableHead>
        {isLoading ? (
          <TableBodyLoader colSpan={9} rowsPerPage={10} />
        ) : _.some(data) ? (
          <TableBody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <StyledTableRow
                  {...row.getRowProps()}
                  key={i}
                  style={{ borderLeft: '1px solid rgba(224, 224, 224, 1)' }}
                >
                  {row.cells.map((cell, idx) => {
                    return (
                      <TableCell {...cell.getCellProps()} key={idx}>
                        {cell.render('Cell')}
                      </TableCell>
                    );
                  })}
                </StyledTableRow>
              );
            })}
          </TableBody>
        ) : (
          <TableBody {...getTableBodyProps()}>
            <TableRow
              style={{ borderLeft: '1px solid rgba(224, 224, 224, 1)' }}
            >
              <TableCell
                style={{ textAlign: 'center', fontWeight: 700 }}
                colSpan={headerGroups[0]?.headers?.length + 1}
              >
                OOPS No Stores Found!
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default StoreHealthList;
