import moment from 'moment';

import config from '../../config';
import Campaign from '../../models/campaign/Campaign';
import CampaignDetail from '../../models/campaign/CampaignDetail';
import CampaignFilters from '../../models/campaign/CampaignFilters';
import CampaignOverview from '../../models/campaign/CampaignOverview';

interface CampaignsResponse {
  totalRecords: number;
  campaigns: Campaign[];
}

const fetchCampaignsDetail = async <T>(
  url: string,
  filters: CampaignFilters
): Promise<T> => {
  const {
    startDate,
    endDate,
    campaignType,
    campaignStatus,
    search,
    page,
    offset,
    sort
  } = filters;

  const campaignFilters = {
    filter: {
      date: {
        startDate: moment(startDate).format('YYYY-MMM-DD'),
        endDate: moment(endDate).format('YYYY-MMM-DD')
      },
      name: search,
      attributes: {
        type: campaignType ? [campaignType] : [],
        status: campaignStatus ? [campaignStatus] : []
      }
    },
    sortOrder: sort,
    pagination: {
      page,
      offset
    }
  };

  const response = await fetch(
    `${config.ruleServerUrl}/users/published-campaigns/campaigns-data-v2${url}`,
    {
      method: 'POST',
      body: JSON.stringify(campaignFilters),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  if (!response.ok) {
    throw new Error('Something went wrong');
  }

  return response.json();
};

export const getCampaigns = async (
  filters: CampaignFilters
): Promise<CampaignsResponse> => {
  return fetchCampaignsDetail<CampaignsResponse>('/', filters);
};

export const getCampaignOverview = async (
  filters: CampaignFilters
): Promise<CampaignOverview> => {
  return fetchCampaignsDetail<CampaignOverview>('/reports', filters);
};

export const getCampaignDetail = async (
  campaignId: string
): Promise<CampaignDetail> => {
  const response = await fetch(
    `${config.ruleServerUrl}/users/published-campaigns/campaigns-data-v2/${campaignId}`,
    {
      credentials: 'include'
    }
  );

  if (!response.ok) {
    throw new Error('Something went wrong!');
  }

  return response.json();
};

export const getCampaignSmsStatus = async (
  campaignId: string
): Promise<{ targetCount: number; sentCount: number }> => {
  const response = await fetch(
    `${config.ruleServerUrl}/users/published-campaigns/campaign-status/${campaignId}/sms`,
    {
      credentials: 'include'
    }
  );

  if (!response.ok) {
    throw new Error('Something went wrong!');
  }

  const data = await response.json();

  const statusSms = data.aggregations.dates.buckets.sort(
    (a: any, b: any) =>
      new Date(b.key_as_string).getTime() - new Date(a.key_as_string).getTime()
  )[0];

  return {
    sentCount: statusSms.nested.message_delivered_count.doc_count || 0,
    targetCount: statusSms.nested.message_sent_count.doc_count || 0
  };
};
