import StoreIcon from '../components/Assets/generalSetting/Storeicon.svg';
import ProductIcon from '../components/Assets/generalSetting/Product_Icon.svg';
import RoleIcon from '../components/Assets/generalSetting/Role_Icon.svg';
import UserIcon from '../components/Assets/generalSetting/User_Man_icon.svg';
import ChannelIcon from '../components/Assets/generalSetting/Channel_icon.svg';
import ReportIcon from '../components/Assets/generalSetting/ReportIcon.svg';
import OtpIcon from '../components/Assets/generalSetting/otpconfig.svg';
import config from '../config';
export const generalSetting = [
  {
    id: 1,
    imgSrc: StoreIcon,
    title: 'Store Management',
    subtitle: 'Bring in customer queries from various sources',
    pathName: '/store-management'
  },
  {
    id: 2,
    imgSrc: ProductIcon,
    title: 'Product Mangement',
    subtitle: 'Bring in customer queries from various sources',
    pathName: '/product-management'
  },
  {
    id: 8,
    imgSrc: ReportIcon,
    title: 'Bill Upload',
    subtitle: 'Edit config and upload Bills',
    pathName: `${config.dashboardUrlV2}/bill-upload`,
    external: true
  },
  {
    id: 3,
    imgSrc: OtpIcon,
    title: 'OTP Configuration',
    subtitle: 'Bring in customer queries from various sources',
    pathName: '/otp-configuration'
  },
  {
    id: 4,
    imgSrc: RoleIcon,
    title: 'Role Configuration',
    subtitle: 'Bring in customer queries from various sources',
    pathName: '/role-config-v1'
  },
  {
    id: 5,
    imgSrc: UserIcon,
    title: 'User Management',
    subtitle: 'Bring in customer queries from various sources',
    pathName: '/user-list'
  },
  {
    id: 6,
    imgSrc: ChannelIcon,
    title: 'Channels',
    subtitle: 'Bring in customer queries from various sources',
    pathName: '/channels'
  },
  {
    id: 7,
    imgSrc: ReportIcon,
    title: 'Live Reports',
    subtitle: 'Bring in customer queries from various sources',
    pathName: '/live-reports'
  }
];

export const settingContent = {
  title: 'Settings Configuration',
  generalTitle: 'General Setting',
  generalParagraph: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
  moduleTitle: 'Module Setup',
  moduleParagraph: ' Set up your ticket routing and resolution process'
};

export const moduleSetup = {
  bannerTitle: 'Need to Explore? Check out the Product Guide.',
  bannerSubTitle: 'Get step-by-step instructions to make CASA work for you.',
  moduleTitle: 'Model Setup',
  generalTitle: 'General Settings'
};
