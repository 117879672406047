/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState } from 'react';
import * as classes from './TemplateInput.module.css';
import TextField from '@material-ui/core/TextField';
import { Delete } from '@material-ui/icons';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { tags } from './tags';
import _ from 'lodash';

class TemplateData {
  constructor() {
    this.id = '';
    this.order = '';
    this.name = '';
    this.value = '';
  }
  setTemplateId(templateId) {
    this.id = templateId;
  }
  setOrder(order) {
    this.order = order;
  }
  setName(name) {
    this.name = name;
  }
  setValue(value) {
    this.value = value;
  }
}

const Input = ({
  templateId,
  template,
  templateData,
  setTemplateData,
  setDetail,
  idx
}) => {
  const [order, setOrder] = useState(template.order);
  const [name, setName] = useState(template.name);
  return (
    <div className={classes.textContainer}>
      <TextField
        className={classes.textField}
        id="standard-basic"
        label="Order"
        value={order}
        type="number"
        onChange={e => {
          setOrder(e.target.value);
          template.setOrder(e.target.value);
          setDetail(
            _.map(templateData, template => {
              template.setTemplateId(templateId);
              return template;
            })
          );
        }}
      />
      <TextField
        className={classes.textField}
        id="standard-basic"
        label="Name"
        value={name}
        onChange={e => {
          setName(e.target.value);
          template.setName(e.target.value);
          setDetail(
            _.map(templateData, template => {
              template.setTemplateId(templateId);
              return template;
            })
          );
        }}
      />
      <Autocomplete
        freeSolo
        disableClearable
        className={classes.textField}
        value={template.value}
        options={tags.map(option => option.title)}
        style={{ width: 200 }}
        onChange={(e, val) => {
          const tag = _.find(tags, tag => tag.title === val);
          template.setValue(tag.value);
          setDetail(
            _.map(templateData, template => {
              template.setTemplateId(templateId);
              return template;
            })
          );
        }}
        renderInput={params => (
          <TextField
            fullwidth
            {...params}
            label="Value"
            margin="normal"
            variant="standard"
            fullWidth
            onChange={e => {
              template.setValue(e.target.value);
              setDetail(
                _.map(templateData, template => {
                  template.setTemplateId(templateId);
                  return template;
                })
              );
            }}
            InputProps={{ ...params.InputProps, type: 'search' }}
          />
        )}
      />
      <AddCircleIcon
        style={
          _.size(templateData) > 1
            ? { cursor: 'pointer' }
            : { marginRight: '100px', cursor: 'pointer' }
        }
        onClick={() => {
          setTemplateData([...templateData, new TemplateData()]);
        }}
      />

      {_.size(templateData) > 1 && (
        <Delete
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setTemplateData(templateData.filter((_, index) => index !== idx));
          }}
          fontSize="small"
        />
      )}
    </div>
  );
};

export const TemplateInput = ({ detail, setDetail }) => {
  const [templateData, setTemplateData] = useState(
    _.isEmpty(detail.messageTemplateData)
      ? [new TemplateData()]
      : detail.messageTemplateData
  );
  const [templateId, setTemplateId] = useState(
    _.isEmpty(detail.messageTemplateData)
      ? ''
      : detail.messageTemplateData[0].id
  );

  return _.includes(['SmsRecipient', 'WhatsappRecipient'], detail.recipient) ? (
    <div className={classes.container}>
      <TextField
        className={classes.templateIdField}
        id="standard-basic"
        label="Message Template Id"
        value={templateId}
        onChange={e => {
          setTemplateId(e.target.value);
          setTemplateData(
            _.map(templateData, template => {
              template.setTemplateId(templateId);
              return template;
            })
          );
          setDetail(templateData);
        }}
      />
      {_.map(templateData, (template, idx) => (
        <Input
          key={idx}
          templateId={templateId}
          template={template}
          templateData={templateData}
          setTemplateData={setTemplateData}
          setDetail={setDetail}
          idx={idx}
        />
      ))}
    </div>
  ) : (
    <> </>
  );
};
