/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { FC, useEffect, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { getUnblockLoyaltyReport } from '../../../services/TenantReports/UnblockLoyaltyReport.service';
import moment from 'moment';
import { Button, FormControl, InputLabel, MenuItem, Select, Tooltip } from '@material-ui/core';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { get, isEqual, isUndefined, last, map } from 'lodash';

interface UnblockLoyaltyReport {
  startDate: string;
  endDate: string;
  businessId?: number;
}

const billInitialState: UnblockLoyaltyReport = {
  startDate: moment()
    .subtract(1, 'day')
    .format('YYYY-MM-DD'),
  endDate: moment().format('YYYY-MM-DD'),
  businessId: undefined
};

interface ReportActionsProps {
  setRefreshView: (id: string) => void;
}

const UnblockLoyaltyReport: FC<ReportActionsProps> = ({ setRefreshView }) => {
  const [filters, setFilters] = useState<UnblockLoyaltyReport>(billInitialState);
  const [disableButton, setDisableButton] = useState(false);
  const user = useCurrentUser();

  const handleDateChange = (date: any, name: string) => {
    setFilters({ ...filters, [name]: date });
  };

  useEffect(() => {
    if (isUndefined(filters.businessId) && !isEqual(user.id, 0)) {
      const business = user.getBusinesses();
      const headBusiness = last(business);
      const defaultBusinessId = get(headBusiness, 'id', 0);
      setFilters({ ...filters, businessId: defaultBusinessId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleGenerateReport = (): void => {
    getUnblockLoyaltyReport(filters).then((res: any) => {
      const jobId = get(res, 'jobId', '');
      setRefreshView(jobId);
    });
    setDisableButton(true);
  };

  const handleBusinessChange = (event: any) => {
    setFilters({ ...filters, businessId: event.target.value });
  };

  useEffect(() => {
    if (disableButton) {
      setTimeout(() => setDisableButton(false), 5000);
    }
  }, [disableButton]);

  return (
    <div
      style={{
        margin: '30px 50px',
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      <div>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            label="Data Fetched From"
            value={filters.startDate}
            onChange={e => handleDateChange(e, 'startDate')}
            variant="inline"
            format="dd-MM-yyyy"
          />
          <KeyboardDatePicker
            label="Data Fetched To"
            value={filters.endDate}
            onChange={e => handleDateChange(e, 'endDate')}
            variant="inline"
            format="dd-MM-yyyy"
            style={{ marginLeft: '20px' }}
          />
        </MuiPickersUtilsProvider>
      </div>
      <FormControl style={{ width: 150 }}>
        <InputLabel>Business Unit</InputLabel>
        {filters.businessId && (
          <Select
            labelId="businessLabel"
            value={filters.businessId}
            onChange={event => handleBusinessChange(event)}
          >
            {map(user?.getBusinesses(), b => (
              <MenuItem value={b.id} key={b.id}>
                {b.name}
                {console.log(filters.businessId)}
              </MenuItem>
            ))}
          </Select>
        )}
      </FormControl>
      {disableButton && (
        <Tooltip title={'Please wait 5 seconds to regenerate report'}>
          <div>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              onClick={handleGenerateReport}
              disabled={disableButton}
            >
              Generate Report
            </Button>
          </div>
        </Tooltip>
      )}
      {!disableButton && (
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={handleGenerateReport}
          disabled={disableButton}
        >
          Generate Report
        </Button>
      )}
    </div>
  );
};

export default UnblockLoyaltyReport;
