import React from 'react';
import { Offer } from '../../models/offer/Offer.model';
import _ from 'lodash';
import { OfferExpiry } from '../OfferExpiry';
import Button from '@material-ui/core/Button';
import { CssStyles } from '../CartBucket/CartBucketProps';

const styles: { [name: string]: CssStyles } = {
  offerName: {
    font: 'bold',
    fontSize: '15px',
    fontFamily: 'camphor',
    color: '#404040',
    lineHeight: '18px',
    whiteSpace: 'pre-wrap !important'
  },
  offerCodeHeader: {
    fontWeight: 'bold',
    fontSize: '12px',
    fontFamily: 'camphor',
    color: '#767676',
    lineHeight: '15px'
  },
  offerCode: {
    fontWeight: 'bold',
    fontSize: '13px',
    fontFamily: 'camphor',
    color: '#000000',
    lineHeight: '15px'
  }
};

interface CustomerOfferTileProps {
  offer: Offer;
  className?: string;
  businessUnitId: number;
  offerDefaultImage: string | null;
  onClick?: (offer: Offer) => void;
}

export const CustomerOfferTile: React.FC<CustomerOfferTileProps> = ({
  offer,
  className = '',
  onClick = _.noop,
  offerDefaultImage
}) => {
  const getOfferImageUrl = () => {
    return offer.imageUrl || offerDefaultImage
  }

  return (
    <div
      className={`d-flex justify-content-center flex-column ${className} p-0`}
      style={{
        border: '1px solid #DEDEDE',
        borderRadius: '8px',
        backgroundColor: 'white',
        overflow: 'hidden',
        maxWidth: '300px'
      }}
    >
      <div
        className={'d-flex justify-content-center align-items-center'}
        style={{ height: '10em' }}
      >
       {getOfferImageUrl() && (
            <img
              className="img-fluid my-auto"
              src={getOfferImageUrl()!}
              alt={'offer'}
              style={{ maxHeight: '8em' }}
            />
          )}
      </div>
      <div
        className={
          'd-flex flex-column justify-content-around align-items-center'
        }
        style={{ height: '10em', margin: '.6rem 0rem' }}
      >
        <div className={'text-center'} style={styles.offerName}>
          {offer.name}
        </div>
        <div
          className={'d-flex justify-content-center align-items-center mb-2'}
        >
          <div style={styles.offerCodeHeader}>CODE:</div>
          <div style={styles.offerCode} className={'mx-1 text-truncate'}>
            {offer.code}
          </div>
        </div>
        <OfferExpiry
          offer={offer}
          className={'d-flex justify-content-center align-items-center'}
        />
        <div className={'d-flex justify-content-center'}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => onClick(offer)}
          >
            Redeem Now
          </Button>
        </div>
      </div>
    </div>
  );
};
