/* eslint-disable @typescript-eslint/explicit-function-return-type */
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  addLMSDropDown,
  getLMSDropDowns,
  removeLMSDropDown
} from '../../../services/lmsDropDown.service';
import MasterCard from './MasterCard';
import styles from './MasterCard.module.css';

const DropDownConfig = () => {
  const [lmsDropdowns, setLmsDropdowns] = useState([]);
  const [newMaster, setNewMaster] = useState('');
  const [showCreateMaster, setShowCreateMaster] = useState(false);

  const fetchDropdowns = async () => {
    const data = await getLMSDropDowns();
    setLmsDropdowns(data);
  };

  useEffect(() => {
    (async () => await fetchDropdowns())();
  }, []);

  const onAddHandler = async data => {
    await addLMSDropDown(data);
    await fetchDropdowns();
  };

  const onRemoveHandler = async id => {
    await removeLMSDropDown(id);
    window.location.reload();
  };

  const createMaster = () => {
    return (
      showCreateMaster && (
        <MasterCard
          key={'new master'}
          title={newMaster}
          options={[]}
          add={data => {
            onAddHandler(data);
            setNewMaster('');
            setShowCreateMaster(false);
          }}
          remove={onRemoveHandler}
        />
      )
    );
  };

  const createNewMaster = () => {
    return (
      <div className={styles.newMasterContainer}>
        <div>
          <span style={{ fontSize: '18px', marginRight: '10px' }}>&#8853;</span>
          <b> Create a New Master</b>
        </div>
        <div className={styles.newMasterCreate}>
          <input
            className={styles.customInput}
            value={newMaster}
            placeholder="Enter new master name"
            onChange={e => setNewMaster(e.target.value)}
          />
          <button
            disabled={!newMaster}
            onClick={() =>
              !_.isEmpty(newMaster) && setShowCreateMaster(!showCreateMaster)
            }
          >
            CREATE
          </button>
        </div>
      </div>
    );
  };

  return (
    <div>
      {createNewMaster()}
      <div className={styles.listContainer}>
        {createMaster()}
        {Object.keys(lmsDropdowns).map(cat => (
          <MasterCard
            key={cat}
            title={cat}
            options={lmsDropdowns[cat]}
            add={onAddHandler}
            remove={id => onRemoveHandler(id)}
          />
        ))}
      </div>
      <div className={styles.warningText}>
        * Deleting all the options under a master will delete the master
      </div>
    </div>
  );
};

export default DropDownConfig;
