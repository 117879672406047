/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState, useEffect } from 'react';
import '../LoyaltyConfig/index.scss';
import { ParentLink } from '../../utils/ParentLink';
import { useFormik } from 'formik';
import { TextFieldComponent } from '../../reusable/TextFieldComponent';
import ButtonField from '../../reusable/ButtonField';
import { initialState } from '../../constants/adhocPoints';
import AdHocPoints from '../Assets/adHocPoints.png';
import { adHocPointsToSchema } from '../../validation/adHocPointsToSchema';
import ConfigService from '../../services/config.service';
import { useDispatch } from 'react-redux';
import { toastMessageAction } from '../../store/action';
import { MenuItem, Select } from '@material-ui/core';
import { Business } from '../../models/User.model';
import useCurrentUser from '../../hooks/useCurrentUser';
import _ from 'lodash';

const LoyaltyPoints = () => {
  const dispatch = useDispatch();
  const [business, setBusiness] = useState(new Business());
  const user = useCurrentUser();
  const onSubmit = (data, { resetForm }) => {
    const workData = {
      password: data.newPassword,
      business: business?.name || ''
    };
    ConfigService.loylatySavePasswordConfig(workData).then(() => {
      dispatch(
        toastMessageAction({
          severity: 'success',
          message: `Password Add successfully!!!`,
          messageStatus: true
        })
      );
      resetForm();
    });
  };

  //handle business change
  const handleBusinessChange = event => {
    const selectedBusiness = _.find(user.getBusinesses(), {
      id: event.target.value
    });
    setBusiness(selectedBusiness);
  };

  useEffect(() => {
    setBusiness(
      _(user.getBusinesses())
        .orderBy(['id'])
        .first() || new Business()
    );
  }, [user]);

  //to handle submit form
  const formik = useFormik({
    initialValues: initialState,
    validationSchema: adHocPointsToSchema,
    onSubmit
  });
  const { values, errors, handleChange, touched, resetForm } = formik;
  return (
    <>
      <div className={'retenseeContainer'}>
        <ParentLink to={'/loyalty-tiers'} className={'heading'}>
          Loyalty Tier Configuration
        </ParentLink>
        <ParentLink to={'/loyalty-config'} className={'heading'}>
          Loyalty Offer Configuration
        </ParentLink>
        <ParentLink to={'/loyalty-exception'} className={'heading'}>
          Loyalty exclusions Configuration
        </ParentLink>
        <ParentLink to={'/ad-hoc-points'} className={'activeHeading'}>
          Ad Hoc Points
        </ParentLink>
        <ParentLink to={'/loyalty-redeem-configuration'} className={'heading'}>
          Loyalty Redemption Configuration
        </ParentLink>
        <ParentLink to={'/referral-configuration'} className={'heading'}>
          Referral Configuration
        </ParentLink>
      </div>
      <span className="adhocBusiness">
        <div id="businessLabel" style={{ color: '#f26e5f' }}>
          Business unit
        </div>
        <Select
          defaultValue=""
          labelId="businessLabel"
          value={business.id}
          onChange={handleBusinessChange}
          style={{ width: '180px' }}
        >
          {_.map(user.getBusinesses(), b => (
            <MenuItem value={b.id} key={b.id}>
              {b.name}
            </MenuItem>
          ))}
        </Select>
      </span>
      <div className={'adHocPointsContainer'}>
        <img src={AdHocPoints} alt="AdHocPoints" className="adhocPointsImage" />
        <h4 className="adHocPointsTitle">
          Set password to access Ad Hoc Points
        </h4>
        <form onSubmit={formik.handleSubmit} className="formSubmitation">
          <TextFieldComponent
            label={'New Password'}
            variant="outlined"
            onChange={handleChange}
            name={'newPassword'}
            showEyeIcon
            value={values.newPassword}
            error={touched.newPassword && errors.newPassword}
            className="textFieldStyle"
          />
          <TextFieldComponent
            label={'Confirm New Password'}
            variant="outlined"
            onChange={handleChange}
            name={'confirmNewPassword'}
            showEyeIcon
            value={values.confirmNewPassword}
            error={touched.confirmNewPassword && errors.confirmNewPassword}
            className="textFieldStyle"
          />
          <div className={'submitButtonContainer'}>
            <ButtonField
              variant="contained"
              label="Clear"
              type="button"
              onClick={() => resetForm()}
              className="clearButton"
            />
            <ButtonField
              variant="contained"
              label="Save"
              type="sumbit"
              className="saveButton"
            />
          </div>
        </form>
      </div>
    </>
  );
};
export default LoyaltyPoints;
