/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useContext, useEffect, useState } from 'react';
import cancelIcon from '../../../Assets/images/icons/cancel_icon.svg';
import {
  Button,
  Card,
  createStyles,
  FormControl,
  FormControlLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Theme
} from '@material-ui/core';
import productTemplateIcon from '../../../Assets/images/product_template_icon.svg';
import ProductMasterService from '../../../../services/productMaster.service';
import _, { compact, isUndefined } from 'lodash';
import BulkUpload from '../../PageGenerators/BulkUpload';
import { LayoutType } from '../../../../models/FlipbookV2/FlipbookV2Layout.model';
import {
  FlipbookLayoutTile,
  TileType
} from '../../../../models/FlipbookV2/FlipbookV2LayoutTile.model';
import { makeStyles } from '@material-ui/core/styles';
import { BusinessUnitContext } from '../../../OfferDefinition/BusinessUnitContext';
import { ProductSelection } from '../../ProductSelection/ProductSelection';
import FlipbookLayoutTileServiceV2 from '../../../../services/Flipbook/flipbookV2LayoutTile.service';
import { FlipbookPagesContext } from '../../Hooks/FlipbookPagesContext';
import {
  buildSkusWithPath,
  getSKUsFromProductsWithPath
} from './ProductSerializerUtil';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3)
    },
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '30ch'
      }
    }
  })
);

export const CHOOSE_PRODUCTS = 'chooseProducts';
export const BULK_UPLOAD_PRODUCTS = 'bulkUploadProducts';

interface AddCollectionFormProps {
  layoutType: LayoutType;
  flipbookLayoutTile: FlipbookLayoutTile;
  handleAddCollectionModalClose: () => void;
  isAddCollectionOpen: boolean;
  flipbookTileType: TileType;
}

export const AddCollectionForm: FC<AddCollectionFormProps> = ({
  layoutType,
  flipbookLayoutTile,
  handleAddCollectionModalClose,
  isAddCollectionOpen,
  flipbookTileType
}) => {
  const isTileOneRestrict =
    flipbookTileType === TileType.TileOne && layoutType === LayoutType.DOUBLE;
  const classes = useStyles();
  const [tileData, setTileData] = React.useState<any>(
    flipbookLayoutTile.tileData
  );
  const [isWarningEnabled, setIsWarningEnabled] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [selectProductType, setSelectProductType] = React.useState({
    title: '',
    subTitle: ''
  });
  const [selectedTabType, setSelectedTabType] = React.useState(CHOOSE_PRODUCTS);
  const [pickProductsBy, setPickProductsBy] = React.useState('');
  const [selectedMatchingProducts, setSelectedMatchingProducts] = useState<
    any[]
  >();
  const [
    isPickProductByHierarchyOpen,
    setIsPickProductByHierarchyOpen
  ] = React.useState(false);

  const businessUnitContext = useContext(BusinessUnitContext);
  const productHierarchy = _.get(
    businessUnitContext,
    'business.productHierarchyNew',
    []
  );

  const handleChooseProductsBtn = () => {
    if (pickProductsBy === 'hierarchy') {
      setIsPickProductByHierarchyOpen(true);
    }
  };

  useEffect(() => {
    if (!isUndefined(tileData.skus) && isUndefined(selectedMatchingProducts)) {
      ProductMasterService.listProductsBySku(tileData.skus).then(res => {
        const productsWithPath = buildSkusWithPath(res, productHierarchy);
        setSelectedMatchingProducts(productsWithPath);
      });
    }
  }, [productHierarchy, selectedMatchingProducts, tileData.skus]);
  const handleAddCollectionSave = (isRestricted: boolean) => {
    if (isRestricted && tileData.skus.length > 2) {
      setIsWarningEnabled(true);
      setErrorMessage('*Please select only 2 products');
    } else if (_.isEmpty(tileData.title)) {
      setIsWarningEnabled(true);
      setErrorMessage('*Title cannot be blank');
    } else {
      handleAddCollectionModalClose();
      createOrUpdateLayout();
    }
  };

  const handleChangeTileData = (type: string, value: any) => {
    const updatedTileData = { ...tileData, [type]: value };
    setTileData(updatedTileData);
  };

  const flipbookPagesContext = useContext(FlipbookPagesContext);
  const createOrUpdateLayout = () => {
    const updatedFlipbookLayoutTile: FlipbookLayoutTile = flipbookLayoutTile;
    updatedFlipbookLayoutTile.tileData = tileData;
    if (isUndefined(flipbookLayoutTile?.id)) {
      FlipbookLayoutTileServiceV2.createFlipbookLayoutTile(
        updatedFlipbookLayoutTile
      ).then(r => {
        flipbookPagesContext.updateFlipbookPages();
      });
    } else {
      FlipbookLayoutTileServiceV2.updateFlipbookLayoutTile(
        updatedFlipbookLayoutTile
      ).then(r => {
        flipbookPagesContext.updateFlipbookPages();
      });
    }
  };

  const getSelectOption = (type: string, value: string) => {
    return _.find(productHierarchy, {
      [type]: value
    });
  };

  const handleChange = (e: any, type: string): void => {
    const option = getSelectOption('displayName', e.target.value);
    handleChangeTileData('productCardTemplate', {
      ...tileData.productCardTemplate,
      ...{
        [type]: option.casaFieldName
      }
    });

    setSelectProductType({ ...selectProductType, [type]: e.target.value });
  };

  useEffect(() => {
    const { productCardTemplate } = tileData;
    if (productCardTemplate?.title && productCardTemplate?.subTitle) {
      const getTitle = getSelectOption(
        'casaFieldName',
        productCardTemplate?.title
      );
      const getSubTitle = getSelectOption(
        'casaFieldName',
        productCardTemplate?.subTitle
      );
      setSelectProductType({
        ...selectProductType,
        title: getTitle.displayName,
        subTitle: getSubTitle.displayName
      });
    }
  }, []);

  return (
    <Modal
      open={isAddCollectionOpen}
      onClose={() => handleAddCollectionModalClose()}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modal}
    >
      <div className={'add-collections-modal-container text-center'}>
        <div className={'popup-header-section'}>
          <h2 className={'mail-header'}>{'Add your collections'}</h2>
          <img
            className={'popup-close-section'}
            src={cancelIcon}
            alt={'close'}
            onClick={handleAddCollectionModalClose}
          />
        </div>
        <div className={'add-collection-modal-content'}>
          <form className={classes.root} noValidate autoComplete="off">
            <TextField
              id="standard-basic"
              label="Title"
              value={tileData.title}
              onChange={event =>
                handleChangeTileData('title', event.target.value)
              }
            />
            {/* ------------ Removing Titile from the page ----------------- */}
            {/*<FormControlLabel*/}
            {/*  className={'m-0 flipbook-text-label'}*/}
            {/*  control={*/}
            {/*    <Checkbox*/}
            {/*      checked={isShowTitleInFlipbook}*/}
            {/*      onChange={() =>*/}
            {/*        setIsShowTitleInFlipbook(!isShowTitleInFlipbook)*/}
            {/*      }*/}
            {/*      name="checkedA"*/}
            {/*      color="primary"*/}
            {/*    />*/}
            {/*  }*/}
            {/*  label="Display in flipbook"*/}
            {/*/>*/}
          </form>
          <h2 className={'flipbook-text-header m-2 my-4'}>
            {'Choose Product detail template (optional)'}
          </h2>
          <Card className={'add-collection-modal-card mx-2'}>
            <div>
              <div>
                <img
                  src={productTemplateIcon}
                  alt={'product_temp'}
                  style={{ width: '8rem', height: '8rem' }}
                />
              </div>
              <div className={'m-1 mx-2'} style={{ textAlign: 'left' }}>
                <Select
                  disableUnderline
                  className={'product-template-select'}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectProductType.title}
                  onChange={e => handleChange(e, 'title')}
                >
                  {_.map(productHierarchy, (p, i) => (
                    <MenuItem key={i} value={p.displayName}>
                      {p.displayName}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className={'m-1 mx-2'} style={{ textAlign: 'left' }}>
                <Select
                  disableUnderline
                  className={'product-template-select'}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectProductType.subTitle}
                  onChange={e => handleChange(e, 'subTitle')}
                >
                  {_.map(productHierarchy, (p, i) => (
                    <MenuItem key={i} value={p.displayName}>
                      {p.displayName}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div>
                <div className={'m-1'} style={{ float: 'left' }}>
                  <p
                    className={
                      'm-0 px-2 text-primary-color product-template-text'
                    }
                  >
                    {'[Price]'}
                  </p>
                </div>
              </div>
            </div>
          </Card>
          <div className={'m-2'}>
            <Card className={'product-selection-card-container'}>
              <div className={'product-selection-tab'}>
                <div
                  className={`custom-tab-button ${
                    selectedTabType === CHOOSE_PRODUCTS ? 'active' : ''
                  }`}
                  onClick={() => setSelectedTabType(CHOOSE_PRODUCTS)}
                >
                  Pick and choose
                </div>
                <div
                  className={`custom-tab-button ${
                    selectedTabType === BULK_UPLOAD_PRODUCTS ? 'active' : ''
                  }`}
                  onClick={() => setSelectedTabType(BULK_UPLOAD_PRODUCTS)}
                >
                  Bulk Upload
                </div>
              </div>
              <div className={'text-center'}>
                {selectedTabType === CHOOSE_PRODUCTS && (
                  <div className={'product-selection-section'}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        row
                        aria-label="position"
                        name="position"
                        defaultValue="top"
                        value={pickProductsBy}
                        onChange={event =>
                          setPickProductsBy(event.target.value)
                        }
                      >
                        <FormControlLabel
                          style={{ fontSize: 12, color: '#707070' }}
                          value="hierarchy"
                          control={<Radio color="primary" />}
                          label="products by hierarchy"
                        />
                        <FormControlLabel
                          disabled={true}
                          style={{ fontSize: 12, color: '#707070' }}
                          value="search"
                          control={<Radio color="primary" />}
                          label="products by search"
                        />
                      </RadioGroup>
                    </FormControl>
                    <div className={'choose-products-section'}>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleChooseProductsBtn()}
                      >
                        Choose Products
                      </Button>
                      <p className={'selected-products-label'}>
                        <span
                          className={'selected-products-label-highlight'}
                        >{`${tileData?.skus?.length} products`}</span>
                        {'selected'}
                      </p>
                    </div>
                  </div>
                )}
                {selectedTabType === BULK_UPLOAD_PRODUCTS && (
                  <BulkUpload
                    productHierarchy={productHierarchy}
                    tileData={tileData}
                    setTileData={setTileData}
                    selectedMatchingProducts={tileData.skus}
                  />
                )}
                {isWarningEnabled && (
                  <p className={'selected-products-label'}>
                    <span className={'products-label-warning'}>
                      {errorMessage}
                    </span>
                  </p>
                )}
              </div>
            </Card>
          </div>
          <div className={'text-center m-2'}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleAddCollectionSave(isTileOneRestrict)}
              style={{ padding: '.5rem 3rem' }}
            >
              {'save'}
            </Button>
          </div>
        </div>
        <Modal
          open={isPickProductByHierarchyOpen}
          onClose={() => setIsPickProductByHierarchyOpen(false)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className={classes.modal}
        >
          <ProductSelection
            currentTileData={tileData}
            productHierarchyTreeChecked={tileData.productHierarchyTreeChecked}
            setProductHierarchyTreeChecked={(e: string[]) => {
              handleChangeTileData('productHierarchyTreeChecked', e);
            }}
            productHierarchyTreeExpanded={tileData.productHierarchyTreeExpanded}
            setProductHierarchyTreeExpanded={(e: string[]) => {
              handleChangeTileData('productHierarchyTreeExpanded', e);
            }}
            closeModal={() => setIsPickProductByHierarchyOpen(false)}
            setSelectedMatchingProducts={(e: any) => {
              const selectedProducts = getSKUsFromProductsWithPath(compact(e));
              handleChangeTileData('skus', selectedProducts);
            }}
            selectedMatchingProducts={selectedMatchingProducts}
            productsFilteredByFirstHierarchy={
              tileData.productsFilteredByFirstHierarchy
            }
            setProductsFilteredByFirstHierarchy={(e: string[]) =>
              handleChangeTileData('productsFilteredByFirstHierarchy', e)
            }
          />
        </Modal>
      </div>
    </Modal>
  );
};
