import React, { useContext, useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button
} from '@material-ui/core';
import SelectProductHierarchy from './Components/SelectProductHierarchy';
import SelectProductSkus from './Components/SelectProductSkus';
import { BusinessUnitContext } from '../../BusinessUnitContext';
import _ from 'lodash';
import update from 'immutability-helper';
import { OptionType } from './index';
import {
  ProductDiscount,
  FieldCondition,
  Operator
} from '../../../../models/offer/OfferRule.model';

const DiscountSelectProducts = (props: {
  readOnly: boolean;
  productFieldOptions: any;
  discount: ProductDiscount;
  updateDiscount: any;
  isDialogOpen: boolean;
  setDialogOpen: any;
}) => {
  let {
    readOnly,
    productFieldOptions,
    discount,
    updateDiscount,
    isDialogOpen,
    setDialogOpen
  } = props;
  const businessUnitContext = useContext(BusinessUnitContext);
  // const [skusList, setSkusList] = useState([]);
  const [productDiscount, setDiscount] = useState(discount);

  const [
    selectedProductFilterOptions,
    setSelectedProductFilterOptions
  ] = useState<{
    [key: string]: OptionType[];
  }>({});

  useEffect(
    function() {
      setDiscount(discount);
    },
    [discount]
  );

  const productFilter = businessUnitContext.business.productFilter;

  function toggleSelectionType(event: any) {
    setSelectedProductFilterOptions({});
    // setSkusList([]);
    setDiscount(
      update(productDiscount, {
        conditionType: {
          $set: event.target.value
        }
      })
    );
  }

  return (
    <Dialog
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={isDialogOpen}
      maxWidth="lg"
      scroll="paper"
    >
      <DialogTitle id="alert-dialog-slide-title" className={'text-center'}>
        Select Products
      </DialogTitle>
      <DialogContent
        style={{ width: `${1000}px`, minWidth: 300, minHeight: 300 }}
      >
        <RadioGroup
          aria-label="selection type"
          name="selectionType"
          value={productDiscount.conditionType}
          onChange={toggleSelectionType}
        >
          <FormControlLabel
            value="all"
            control={<Radio disabled={readOnly} />}
            label="All Items"
          />
          <FormControlLabel
            value="productHierarchy"
            control={<Radio disabled={readOnly} />}
            label="Select product(s) by hierarchy"
          />
          <SelectProductHierarchy
            productFilter={productFilter}
            selectedProductFilterOptions={selectedProductFilterOptions}
            setSelectedProductFilterOptions={setSelectedProductFilterOptions}
            isHierarchyBased={
              productDiscount.conditionType === 'productHierarchy'
            }
            productFieldOptions={productFieldOptions}
          />
          <FormControlLabel
            value="productSkus"
            control={<Radio disabled={readOnly} />}
            label="Select product(s) using sku"
          />
          <SelectProductSkus
            setSelectedProductFilterOptions={setSelectedProductFilterOptions}
          />
        </RadioGroup>
      </DialogContent>
      <DialogActions className={'mb-3'}>
        <Button
          onClick={() => {
            setDialogOpen(false);
          }}
          color="primary"
        >
          Cancel
        </Button>
        <div className={'mr-3'}>&nbsp;</div>
        <div className={'pr-3'}>
          <Button
            onClick={() => {
              let updatedDiscount = _.clone(productDiscount);
              if (updatedDiscount.conditionType !== 'all') {
                const fieldConditions = _(selectedProductFilterOptions)
                  .entries()
                  .filter(([fieldName, fieldNameOptions]) =>
                    _.some(fieldNameOptions)
                  )
                  .map(([fieldName, fieldNameOptions]) => {
                    return new FieldCondition(
                      fieldName,
                      _.map(fieldNameOptions, 'value'),
                      Operator.isInOperator
                    );
                  })
                  .value();
                updatedDiscount.condition.conditions = fieldConditions;
              } else updatedDiscount.condition.conditions = [];

              updateDiscount(updatedDiscount);
              setDialogOpen(false);
            }}
            variant="contained"
            color="primary"
            className={'mr-3'}
          >
            Add
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default DiscountSelectProducts;
