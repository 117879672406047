import React, { FC, useState } from 'react';
import TransactionFilter from './Filter';
import TransactionDownloadTable from './List';
import usePagination from '../../../shared/hooks/usePagination';
import { getTransactionWiseReportDownload } from '../../../services/TenantReports/TransactionwiseReport.service';

const TransactionReport: FC = () => {
  const [refreshView, setRefreshView] = useState<string>('');
  const {
    data,
    isLoading,
    page,
    offset,
    setPage,
    setOffset
  } = usePagination(getTransactionWiseReportDownload, [refreshView]);
  return (
    <div>
      <TransactionFilter setRefreshView={setRefreshView} />
      <TransactionDownloadTable
        reports={data || []}
        isLoading={isLoading}
        page={page}
        offset={offset}
        setPage={setPage}
        setOffset={setOffset}
      />
    </div>
  );
};

export default TransactionReport;
