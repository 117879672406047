import { TableBody } from '@material-ui/core';
import React, { FC } from 'react';

import Campaign from '../../../../../../models/campaign/Campaign';
import CampaignTableBodyRow from '../CampaignTableBodyRow/CampaignTableBodyRow';

interface CampaignTableBodyProps {
  campaigns: Campaign[];
  colSpan: number;
}

const CampaignTableBody: FC<CampaignTableBodyProps> = ({
  campaigns,
  colSpan
}) => {
  return (
    <TableBody>
      {campaigns.map(campaign => (
        <CampaignTableBodyRow
          key={campaign.campaignId}
          colSpan={colSpan}
          campaign={campaign}
        />
      ))}
    </TableBody>
  );
};
export default CampaignTableBody;
