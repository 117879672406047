import React from 'react';
import _ from 'lodash';
import Select, { ValueType } from 'react-select';
import { OptionType } from '../index';
import {
  ProductCategory,
  ProductFilter
} from '../../../../../models/User.model';

const SelectProductHierarchy = (props: any) => {
  let {
    productFilter,
    isHierarchyBased,
    selectedProductFilterOptions,
    productFieldOptions,
    setSelectedProductFilterOptions
  } = props;
  let { hierarchyNew, productFieldsNew } = productFilter as ProductFilter;
  const hierarchy = hierarchyNew || [];
  const productFields = productFieldsNew || [];

  const defaultValue: OptionType[] = [];

  const selectFilter = (
    isDisabled: boolean,
    h: ProductCategory,
    dropDependententFilters: any = () => {}
  ) => {
    return (
      <div
        key={h.casaFieldName}
        className={'col-3'}
        style={{
          marginRight: '10px'
        }}
      >
        <label
          htmlFor={`${h.casaFieldName}Select`}
          className={isDisabled ? 'disabledText' : 'primaryText'}
        >
          <h6 className={'m-0'}>{h.displayName}</h6>
        </label>
        <Select
          name={`${h.casaFieldName}Select`}
          className={'pr-1'}
          key={h.casaFieldName}
          isMulti
          value={selectedProductFilterOptions[h.casaFieldName] || defaultValue}
          options={productFieldOptions[h.casaFieldName]}
          isDisabled={isDisabled}
          menuPlacement="auto"
          maxMenuHeight={200}
          onChange={(v: ValueType<OptionType, boolean>) => {
            const selectedOptions = (v as OptionType[]) || defaultValue;
            setSelectedProductFilterOptions((o: any) => ({
              ...o,
              [h.casaFieldName]: selectedOptions
            }));
            dropDependententFilters();
          }}
        />
      </div>
    );
  };

  return (
    <div
      className="clearfix d-flex"
      style={{
        marginRight: '10px'
      }}
    >
      {hierarchy.map((h: ProductCategory, hIndex: number) => {
        const isDisabled =
          !isHierarchyBased ||
          !(
            hIndex === 0 ||
            _.some(
              selectedProductFilterOptions[hierarchy[hIndex - 1].casaFieldName]
            )
          );
        const dropDependententFilters = (selectedOptions: any[]) => {
          if (_.isEmpty(selectedOptions)) {
            _(hierarchy)
              .drop(hIndex + 1)
              .each(dependentField => {
                setSelectedProductFilterOptions((o: any) => ({
                  ...o,
                  [dependentField.casaFieldName]: defaultValue
                }));
              });
          }
        };
        return selectFilter(isDisabled, h, dropDependententFilters);
      })}
      {productFields.map((h: ProductCategory) =>
        selectFilter(!isHierarchyBased, h)
      )}
    </div>
  );
};

export default SelectProductHierarchy;
