export const dataFormator = (priorityName, data) => {
  const apiStructureData = {
    name: priorityName,
    priorityOrder: data.priorityOrder,
    authkey: data.authkey,
    showCampaignNameInReport: data.showCampaignNameInReport
  };
  if (priorityName === 'OneXtelSendSms') {
    return {
      ...apiStructureData,
      additionalParams: {
        entityId: data.entityId,
        authKeyServiceExplicit: data.authKeyServiceExplicit,
        authKeyPromotional: data.authKeyPromotional
      }
    };
  }
  if (priorityName === 'STMSendSms') {
    return {
      ...apiStructureData,
      additionalParams: {
        clientId: data?.clientId,
        principleEntityId: data.principleEntityId
      }
    };
  }
  if (priorityName === 'ValueFirstSendSms') {
    return {
      name: priorityName,
      priorityOrder: data.priorityOrder,
      username: data.username,
      password: data.password,
      showCampaignNameInReport: data.showCampaignNameInReport
    };
  }
  if (priorityName === 'Msg91SendSms' || priorityName === 'AmeyoWhatsAppMsg') {
    return apiStructureData;
  }
  if (priorityName === 'SesSendEmail') {
    return {
      ...apiStructureData,
      additionalParams: {
        awsAccessKeyId: data.awsAccessKeyId,
        awsSecretAccessKey: data.awsSecretAccessKey,
        awsRegion: data.awsRegion
      }
    };
  }
  if (priorityName === 'SendEmail') {
    return {
      name: 'msg91Mail',
      priorityOrder: data.priorityOrder,
      authkey: data.authkey,
      fromName: data.fromName,
      domain: data.domain,
      showCampaignNameInReport: data.showCampaignNameInReport
    };
  }
  if (priorityName === 'SendWhatsAppMsg') {
    return {
      ...apiStructureData,
      additionalParams: { from: data?.from }
    };
  }
  return {};
};

export const getPriorityList = (list, business, priorityName) => {
  let max = 1;
  list.forEach(item => {
    if (business === item.businessUnit && item?.priorityOrder > max)
      max = item?.priorityOrder;
  });
  const priority = [];
  for (let i = 1; i <= max; i++) {
    priority.push(i);
  }
  return priority;
};
