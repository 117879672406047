import _ from 'lodash';
import React from 'react';

import { SectionFieldParams } from '../../../models/SectionField';
import SectionProps from '../../../models/SectionProps';
import summaryUtils from '../../../utils/ComputedFields';
import classes from './OrderSummary.module.css';

const OrderSummary: React.FC<SectionProps> = ({ data, billData }) => {
  function getInvoiceBasedFields(fields: SectionFieldParams[], billData: any) {
    const invoiceType = _.get(billData, 'bill.invoiceType', {});
    if (invoiceType === 'IN') {
      return fields;
    }
    
    const processedFields = _.map(fields, field => ({
      ...field,
      ..._.get(field, `meta.${invoiceType}`, {})
    }));
    return processedFields;
  }

  function renderPriceSummaryRow(field: SectionFieldParams) {
    const value = summaryUtils.computedFieldGetterMap[field.getter](
      _.get(billData, 'bill', {})
    );
    return (
      <tr className={classes.summaryRow}>
        <td className={classes.summaryField}>{field.label}</td>
        <td>{value}</td>
      </tr>
    );
  }

  function renderPriceSummary() {
    return (
      <table className={[classes.table, classes.summary].join(' ')}>
        <tbody>
          {_.map(
            getInvoiceBasedFields(data.fields, billData),
            renderPriceSummaryRow
          )}
        </tbody>
      </table>
    );
  }
  return <div className={classes.container}>{renderPriceSummary()}</div>;
};
export default OrderSummary;
