import React, { FC, useState } from 'react';
import FraudReportFilter from './FraudReportFilter';
import FraudReportDownloadTable from './FraudReportTable';
import usePagination from '../../../shared/hooks/usePagination';
import { getFraudReportDownload } from '../../../services/TenantReports/FraudReport.service';

const FraudReport: FC = () => {
  const [refreshView, setRefreshView] = useState<string>('');
  const {
    data,
    isLoading,
    page,
    offset,
    setPage,
    setOffset
  } = usePagination(getFraudReportDownload, [refreshView]);
  return (
    <div>
      <FraudReportFilter setRefreshView={setRefreshView} />
      <FraudReportDownloadTable
        reports={data || []}
        isLoading={isLoading}
        page={page}
        offset={offset}
        setPage={setPage}
        setOffset={setOffset}
      />
    </div>
  );
};

export default FraudReport;
