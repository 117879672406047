import React, { useEffect, useState } from 'react';
import { ParentLink } from '../../utils/ParentLink';
import './index.scss';
import ReportMail from '../Assets/ReportMail.png';
import { TextFieldComponent } from '../../reusable/TextFieldComponent';
import ButtonField from '../../reusable/ButtonField';
import { useFormik } from 'formik';
import { reportInitialState } from '../../constants/configuration';
import useCurrentUser from '../../hooks/useCurrentUser';
import { Business } from '../../models/User.model';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { FormControl, MenuItem, InputLabel, Select } from '@material-ui/core';
import LiveReportService from '../../services/liveReport';
import { liveReportToSchema } from '../../validation/liveReportToSchema';
import { useDispatch } from 'react-redux';
import { toastMessageAction } from '../../store/action';
import AutoCompleteChip from '../../reusable/AutoCompleteChip';

const useStyles = makeStyles(theme => ({
  root: {
    width: 370,
    '& > * + *': {
      marginTop: theme.spacing(3)
    }
  },
  businessUnitStyle: {
    position: 'absolute',
    right: 50,
    top: 80
  }
}));

const LiveReports = () => {
  const classes = useStyles();
  const user = useCurrentUser();
  const [business, setBusiness] = useState(new Business());
  const dispatch = useDispatch();

  const onSubmit = data => {
    LiveReportService.saveLiveReportConfig(business?.id, data).then(() => {
      dispatch(
        toastMessageAction({
          severity: 'success',
          message: `Live report send successfully !!!`,
          messageStatus: true
        })
      );
    });
  };

  const formik = useFormik({
    initialValues: reportInitialState,
    validationSchema: liveReportToSchema,
    onSubmit
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    setFieldValue,
    setValues
  } = formik;

  //handle business change
  const handleBusinessChange = event => {
    const selectedBusiness = _.find(user.getBusinesses(), {
      id: event.target.value
    });
    setBusiness(selectedBusiness);
  };

  useEffect(() => {
    setBusiness(
      _(user.getBusinesses())
        .orderBy(['id'])
        .first() || new Business()
    );
  }, [user]);

  useEffect(() => {
    if (business?.id) {
      LiveReportService.getLiveReportConfig(business?.id).then(res => {
        setValues(res[0]);
      });
    }
  }, [business, setValues]);
  return (
    <div>
      <div className="settingTitle">
        <ParentLink
          color="inherit"
          to={'/general-setting'}
          className={'breadcrumbLink'}
        >
          General Setting
        </ParentLink>
        &gt; Live Reports
      </div>
      <div className={classes.businessUnitStyle}>
        <FormControl>
          <InputLabel id="businessLabel">Business unit</InputLabel>
          <Select
            defaultValue=""
            labelId="businessLabel"
            value={business.id}
            onChange={handleBusinessChange}
            style={{ width: '200px' }}
          >
            {_.map(user.getBusinesses(), b => (
              <MenuItem value={b.id} key={b.id}>
                {b.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="reportContainer">
        <img
          src={ReportMail}
          alt={'report mail'}
          style={{ width: '150px', height: '150px' }}
        />
        <h6 className="reportTitle">Engage with our exciting daily reports!</h6>
        <div className="reportParagraph">
          Sign up with your email address to receive detailed report insights
          right now.
        </div>
        <form onSubmit={formik.handleSubmit} className="formContainer">
          <TextFieldComponent
            label={'Receiver Mail'}
            name={'to'}
            onChange={handleChange}
            value={values.to}
            error={touched.to && errors.to}
            style={{ marginBottom: '30px', width: '375px' }}
            className="custom-input"
          />

          <AutoCompleteChip
            label={'Add One or More CC'}
            placeholder="mail cc"
            name="cc"
            setFieldValue={setFieldValue}
            onChange={handleChange}
            value={values.cc}
            style={{ marginBottom: '30px', width: '375px' }}
            error={errors.cc}
            touched={touched.cc}
          />
          <div className="submitButton">
            <ButtonField
              variant="contained"
              label={'Done'}
              type="submit"
              style={{ background: '#F07663', color: 'white' }}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default LiveReports;
