import React, { useEffect, useState } from 'react';
import Form from './Form';
import customerGroupClass from '../index.module.scss';
import List from './List';
import { FormControl, MenuItem, InputLabel, Select } from '@material-ui/core';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { Business } from '../../../models/User.model';
import _ from 'lodash';

const CreateCustomerGroup = () => {
  const [business, setBusiness] = useState(new Business());
  const [status, setStatus] = useState(false);
  const user = useCurrentUser();

  useEffect(() => {
    const selectedBusinessId = +sessionStorage.getItem('selectedBusinessId');
    setBusiness(business => {
      const businesses = _.chain(user.getBusinesses()).value();

      return selectedBusinessId && businesses.length > 0
        ? businesses.find(({ id }) => id === selectedBusinessId)
        : businesses[0] || new Business();
    });
  }, [user]);

  //handle business change
  const handleBusinessChange = event => {
    const selectedBusiness = _.find(user.getBusinesses(), {
      id: event.target.value
    });
    sessionStorage.setItem('selectedBusinessId', selectedBusiness.id);
    setBusiness(selectedBusiness);
  };

  return (
    <div className={customerGroupClass.container}>
      <div className={customerGroupClass.businessId}>
        <FormControl style={{ width: '200px' }}>
          <InputLabel id="businessLabel">Business unit</InputLabel>
          <Select
            defaultValue=""
            labelId="businessLabel"
            value={business.id}
            onChange={handleBusinessChange}
          >
            {_.map(user.getBusinesses(), b => (
              <MenuItem value={b.id} key={b.id}>
                {b.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className={customerGroupClass.subContainer}>
        <Form business={business} status={status} setStatus={setStatus} />
        <List business={business} status={status} />
      </div>
    </div>
  );
};

export default CreateCustomerGroup;
