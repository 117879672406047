import { Paper, TableContainer } from '@material-ui/core';
import React, { FC } from 'react';
import { Table } from 'react-bootstrap';

import CampaignReport from '../../../../../models/campaign/CampaignReport';
import TableBodyLoader from '../../../shared/components/TableBodyLoader/TableBodyLoader';
import TableHeader from '../../../shared/components/TableHeader/TableHeader';
import styles from './CampaignReportTable.module.scss';
import CampaignReportTableBody from './CampaignReportTableBody/CampaignReportTableBody';
import CampaignReportTableFooter from './CampaignReportTableFooter/CampaignReportTableFooter';
import { CampaignReportType } from '../../../shared/utils/contants';

interface CampaignReportTableInterface {
  reports: CampaignReport[];
  isLoading: boolean;
  page: number;
  rowsPerPage: number;
  count: number;
  reportType: CampaignReportType;
  setPage: (pageNumber: number) => void;
  setRowsPerPage: (pageNumber: number) => void;
}

const tableHeaders = [
  'Date Triggered',
  'Start Date',
  'End Date',
  'Status',
  'Download'
];

const CampaignReportTable: FC<CampaignReportTableInterface> = props => {
  const {
    reports,
    isLoading,
    page,
    rowsPerPage,
    reportType,
    count,
    setPage,
    setRowsPerPage
  } = props;

  return (
    <TableContainer
      id={styles.campaignReportTableContainer}
      component={Paper}
      elevation={0}
    >
      <Table className={styles.campaignReportTable}>
        <TableHeader headers={tableHeaders} textAlign="center" />
        {isLoading ? (
          <TableBodyLoader
            colSpan={tableHeaders.length}
            rowsPerPage={rowsPerPage}
            height={32}
          />
        ) : (
          <CampaignReportTableBody reports={reports} reportType={reportType} />
        )}
        <CampaignReportTableFooter
          colSpan={tableHeaders.length}
          count={count || 0}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={setPage}
          onRowsPerPageChange={setRowsPerPage}
        />
      </Table>
    </TableContainer>
  );
};
export default CampaignReportTable;
