import React, { Context } from "react";

type CampaignFilterContext = {
  startDate: string;
  endDate: string;
};
export const CampaignFilterContext: Context<CampaignFilterContext> = React.createContext<
  CampaignFilterContext
>({
  startDate: '',
  endDate: ''
});
