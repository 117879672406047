import { WithClassName } from './WithClassName.model';
import _ from 'lodash';
import update from 'immutability-helper';
import { OfferType } from '../../constants';

export enum LogicalOperator {
  AND = 'AND',
  OR = 'OR'
}

export const DiscountType = {
  percent: {value: 'percent', presentation: 'Percent Discount'},
  fixed: {value: 'fixed', presentation: 'Fixed Discount'},
  slash: {value: 'slash', presentation: 'Slashed Price'}
}

export type OperatorType = 'number' | 'string' | 'date';

export class Operator extends WithClassName {
  public operatorType: OperatorType = 'string';
  public name?: string;

  constructor(operatorType: OperatorType, name: string) {
    super('Operator');
    this.operatorType = operatorType;
    this.name = name;
  }

  public isDefined(): boolean {
    return !!this.name;
  }

  public static readonly isInOperator = new Operator('string', 'isIn');
}

export abstract class BaseCondition extends WithClassName {
  public abstract get isDefined(): boolean;
}

export class FieldCondition extends BaseCondition {
  public fieldAccessor?: string;
  public value: any;
  public operator?: Operator;

  constructor(fieldAccessor: string, value: any, operator: Operator) {
    super('FieldCondition');
    this.fieldAccessor = fieldAccessor;
    this.value = value;
    this.operator = operator;
  }

  public get isDefined(): boolean {
    return !!(this.fieldAccessor && _.some(this.value) && this.operator);
  }
}

export class JoinCondition extends BaseCondition {
  public conditions: BaseCondition[] = [];
  public joinType: LogicalOperator = LogicalOperator.AND;

  constructor() {
    super('JoinCondition');
  }

  public get isDefined(): boolean {
    return (
      _.some(this.conditions) && _.every(this.conditions, c => c.isDefined)
    );
  }
}

export class DiscountRule extends WithClassName {
  public type: string;
  public value: number;

  constructor(type: string = "all", value: number = 1){
    super('DiscountRule')
    this.type = type;
    this.value = value;
  }
}

export class ApplicationMethod extends WithClassName {
  public method: string;
  public value: any;

  public constructor(method: string = 'all', value: Number = 1) {
    super('ApplicationMethod')
    this.method = method
    this.value = value
  }
}

export class Discount extends WithClassName {
  public discountType: string = DiscountType.percent.value;
  public maxDiscount = 0;
  public value = 0;
  public offerType: string = "";
  public rule: DiscountRule = new DiscountRule()
  public applicationMethod: ApplicationMethod = new ApplicationMethod("all")

  constructor(className = 'Discount') {
    super(className);
  }

  public get hasDiscount(): boolean {
    return this.offerType === OfferType.BILL_VALUE || this.offerType === OfferType.PRODUCT_IN_CART;
  }

  getDiscountAmount(price: number): number {
    if (this.discountType === DiscountType.fixed.value) {
      return this.value;
    } else {
      const billDiscountAmount = (price / 100) * this.value;
      if (this.maxDiscount !== 0 && billDiscountAmount <= this.maxDiscount) {
        return billDiscountAmount;
      } else if (this.maxDiscount === 0) {
        return billDiscountAmount;
      } else if (
        this.maxDiscount !== 0 &&
        billDiscountAmount > this.maxDiscount
      ) {
        return this.maxDiscount;
      } else {
        throw new Error('Throw new error - percent calculation not needed!');
      }
    }
  }
}

export class ProductDiscount extends Discount {
  public condition: JoinCondition = new JoinCondition();
  public conditionType: 'all' | 'productSkus' | 'productHierarchy' = 'all'
  
  constructor() {
    super('ProductDiscount')
  }

}

export abstract class Bucket extends WithClassName {
  public bucketId = 0;

  public abstract get isDefined(): boolean;

  public abstract getProductBuckets(): ProductBucket[];

  protected constructor(bucketId = 0, className: string) {
    super(className);
    this.bucketId = bucketId;
  }

  public replace(updatedBucket: Bucket): Bucket {
    return this.bucketId === updatedBucket.bucketId ? updatedBucket : this;
  }

  public remove(bucket: Bucket): Bucket | undefined {
    return this.bucketId === bucket.bucketId ? undefined : this;
  }
}

export class ProductBucket extends Bucket {
  public condition: JoinCondition = new JoinCondition();
  public aggregateCondition: JoinCondition = new JoinCondition();
  public discount: ProductDiscount = new ProductDiscount();


  constructor(bucketId = 0, className = 'ProductBucket') {
    super(bucketId, className);
  }

  static newBucket(bucketId = parseInt(_.uniqueId())): ProductBucket {
    return new ProductBucket(bucketId);
  }

  public get isDefined(): boolean {
    return this.condition.isDefined;
  }

  getProductBuckets(): ProductBucket[] {
    return [this];
  }

  static createFromProductBucket(bucket: ProductBucket): ProductBucket {
    let newBucket = this.newBucket(bucket.bucketId)
    newBucket.condition = bucket.condition;
    newBucket.aggregateCondition = bucket.aggregateCondition;
    newBucket.discount = bucket.discount;
    return newBucket
  }
}

export class FamilyValidator extends WithClassName {
  public id: number
  public minimumQuantity: number = 0
  public total: number = 0
  public static staticClass = "FamilyValidator"

  constructor(id: number, className = "FamilyValidator") {
    super(className)
    this.id = id
  }

  static newValidator(id: number) {
    return new this(id, this.staticClass)
  }

  static createFromFamilyValidator(validator: FamilyValidator) {
    let newValidator = this.newValidator(validator.id)
    newValidator.minimumQuantity = validator.minimumQuantity
    return newValidator
  }

  static convertValidator(validator: FamilyValidator, code: string) {
    return this.validatorClass(code).createFromFamilyValidator(validator)
  }

  static validatorClass(code: string): typeof FamilyValidator {
    switch(code) {
      case("ProductHierarchy"): return ProductHierarchyValidator
      case("ProductSkus"): return ProductSkusValidator
      case("All"): return AllValidator
      case("DistinctSkus"): return DistinctSkusValidator
      default: return AnyValidator
    }
  }

  code() {
    return this.className.replace("Validator", "")
  }
}

export class AnyValidator extends FamilyValidator {
  constructor(id: number) {
    super(id, "AnyValidator")
  }
}

export class DistinctSkusValidator extends FamilyValidator {
  constructor(id: number) {
    super(id, "DistinctSkusValidator")
  }
}

export class AllValidator extends FamilyValidator {
  constructor(id: number) {
    super(id, "AllValidator")
  }
}

export class ProductValidator extends FamilyValidator {
  conditions: FieldCondition[] = []

  constructor(id: number, validatorType = "ProductValidator") {
    super(id, validatorType)
  }
}

export class ProductHierarchyValidator extends ProductValidator {
  constructor(id: number) {
    super(id, "ProductHierarchyValidator")
  }
}

export class ProductSkusValidator extends ProductValidator {
  constructor(id: number) {
    super(id, "ProductSkusValidator")
  }
}
export class ProductFamilyBucket extends ProductBucket {

  public conditions: FamilyValidator[] = []
  public familyDiscount: ProductDiscount = new ProductDiscount();

  constructor(bucketId = 0) {
    super(bucketId, 'ProductFamilyBucket');
  }

  addValidator(): ProductFamilyBucket {
    return update(this as ProductFamilyBucket, {
      conditions: { $set: [...this.conditions, new AnyValidator(this.conditions.length)] }
    });
  }

  static newBucket(bucketId = parseInt(_.uniqueId())): ProductFamilyBucket {
    return new ProductFamilyBucket(bucketId);
  }

}

export class JoinBucket extends Bucket {
  public joinType: LogicalOperator = LogicalOperator.AND;
  public buckets: Bucket[] = [];

  constructor(bucketId = 0) {
    super(bucketId, 'JoinBucket');
  }

  public getProductBuckets(): ProductBucket[] {
    return _.flatMap(this.buckets, b => b.getProductBuckets());
  }

  addProductBucket(): JoinBucket {
    return update(this as JoinBucket, {
      buckets: { $set: [...this.buckets, ProductBucket.newBucket()] }
    });
  }

  addJoinBucket(): JoinBucket {
    return update(this as JoinBucket, {
      buckets: { $set: [...this.buckets, JoinBucket.newBucket()] }
    });
  }

  replace(updatedBucket: Bucket): JoinBucket {
    if (
      this.bucketId === updatedBucket.bucketId &&
      updatedBucket instanceof JoinBucket
    ) {
      return updatedBucket;
    }
    return update(this as JoinBucket, {
      buckets: { $set: _.map(this.buckets, b => b.replace(updatedBucket)) }
    });
  }

  remove(bucket: Bucket): JoinBucket | undefined {
    if (super.remove(bucket) === undefined) return undefined;
    return update(this as JoinBucket, {
      buckets: {
        $set: _(this.buckets)
          .map(b => b.remove(bucket))
          .compact()
          .value()
      }
    });
  }

  public get isDefined(): boolean {
    return _.some(this.buckets) && _.every(this.buckets, b => b.isDefined);
  }

  static newBucket(): JoinBucket {
    return new JoinBucket(parseInt(_.uniqueId()));
  }
}

export class OfferRule extends WithClassName {
  public billCondition: JoinCondition = new JoinCondition();
  public billAggregateCondition: { joinType: string, className: string } = new JoinCondition();
  public billDiscount: Discount = new Discount();
  public bucket: JoinBucket = JoinBucket.newBucket();

  constructor() {
    super('OfferRule');
  }

  public get isDefined(): boolean {
    return this.bucket.isDefined || this.billDiscount.hasDiscount;
  }
}
