import React, { FC } from 'react';
import './flipbookPage.css';

type FlipbookPageProps = {
  width: string | number;
  height: string | number;
} & React.HTMLAttributes<HTMLDivElement>

export type FlipbookPageComponent = FC<FlipbookPageProps>;
export type FlipbookPage = ReturnType<FlipbookPageComponent>;

export const FlipbookPage: FlipbookPageComponent = ({
  width,
  height,
  children,
  style,
  ...otherProps
}) => {
  return (
    <div
      className={'page-container'}
      style={{
        position: 'relative',
        backgroundColor: 'white',
        backgroundSize: 'cover',
        height: height,
        ...style
      }}
      {...otherProps}
    >
      {children}
    </div>
  );
};
