/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState, useEffect } from 'react';
import {
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell
  // withStyles,
  // Switch
} from '@material-ui/core';
import { Business } from '../../models/User.model';
import _ from 'lodash';
import useCurrentUser from '../../hooks/useCurrentUser';
import { useFormik } from 'formik';
import ButtonField from '../../reusable/ButtonField';
import { TextFieldComponent } from '../../reusable/TextFieldComponent';
import { migrateCustomerInitialState } from '../../constants/migrateCustomer';
import classes from './index.module.scss';
import { migrateCusotmerToSchema } from '../../validation/migrateCusotmerToSchema';
import migrateCustomersService from '../../services/migrateCustomers.service';
// import { useDispatch } from 'react-redux';
// import { toastMessageAction } from '../../store/action';
import { toast } from 'react-toastify';
import TableHeader from '../Campaign/shared/components/TableHeader/TableHeader';
import TableBodyLoader from '../Campaign/shared/components/TableBodyLoader/TableBodyLoader';
import { faliedMigrateCustomer } from '../../services/failedMigrateCustomer.service';
import { toastMessageAction } from '../../store/action';
import { useDispatch } from 'react-redux';
// const PurpleSwitch = withStyles({
//   switchBase: {
//     color: 'white',
//     '&$checked': {
//       color: 'white'
//     },
//     '&$checked + $track': {
//       backgroundColor: 'red'
//     }
//   },
//   root: {
//     marginLeft: 20
//   },
//   checked: {},
//   track: {}
// })(Switch);

const MigrateCustomer = () => {
  const [business, setBusiness] = useState(new Business());
  const [isFetching, setIsFetching] = useState(true);
  const [migrate, setMigrate] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [buttonText, setButtonText] = useState('Migrate');
  const dispatch = useDispatch();
  const user = useCurrentUser();
  // const dispatch = useDispatch();
  //handle business change
  const handleBusinessChange = event => {
    const selectedBusiness = _.find(user.getBusinesses(), {
      id: event.target.value
    });
    setBusiness(selectedBusiness);
  };

  const migration = async () => {
    const migratedData = await faliedMigrateCustomer();
    if (migratedData.length) {
      setMigrate(migratedData);
    }
    setIsFetching(false);
  };

  useEffect(() => {
    if (user.id) {
      migration();
    }
  }, [user, isSubmit]);

  useEffect(() => {
    setBusiness(
      _(user.getBusinesses())
        .orderBy(['id'])
        .first() || new Business()
    );
  }, [user]);

  //to handle form submit
  const onSubmit = async (workData, { resetForm }) => {
    setDisableSubmit(true);
    const saveMigrate = await migrateCustomersService.saveMigrateCustomers(
      workData,
      business.id
    );
    // toast.promise(saveMigrate, {
    //   pending: 'Processing...',
    //   theme: 'dark'
    // });
    if (Object.keys(saveMigrate).length === 0) {
      dispatch(
        toastMessageAction({
          severity: 'error',
          message: 'DB error Remigrate',
          messageStatus: true
        })
      );
      setButtonText('Remigrate');
    }
    if (saveMigrate.status === 'failure') {
      dispatch(
        toastMessageAction({
          severity: 'error',
          message: saveMigrate.errors[0].errorType,
          messageStatus: true
        })
      );
    }
    if (saveMigrate.status === 'success') {
      const { data } = saveMigrate;
      data
        ? toast.error(data.errors[0].errorType)
        : toast.success('migrate successfully completed');

      !data && resetForm();
      setButtonText('Migrate');
    }
    setIsSubmit(!isSubmit);
    setDisableSubmit(false);
  };

  const formik = useFormik({
    initialValues: migrateCustomerInitialState,
    validationSchema: migrateCusotmerToSchema,
    onSubmit
  });
  const { values, errors, touched, handleChange, resetForm } = formik;

  //to handle close button
  const handleClose = () => {
    resetForm();
  };
  return (
    <div>
      <div className={classes.alignContainer}>
        <div className={classes.businessUnitStyle}>
          <div>
            <div className={classes.headerTitle}>Customer Migrate</div>
            <p>Migrate customer from old mobile number to new mobile number</p>
          </div>
          <FormControl>
            <InputLabel id="businessLabel">Business unit</InputLabel>
            <Select
              defaultValue=""
              labelId="businessLabel"
              value={business.id}
              onChange={handleBusinessChange}
              style={{ width: '200px' }}
            >
              {_.map(user.getBusinesses(), b => (
                <MenuItem value={b.id} key={b.id}>
                  {b.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      <div className={classes.alignContainer}>
        <Paper className={classes.migrateModalContainer} elevation={3}>
          <form
            onSubmit={formik.handleSubmit}
            className={classes.formContainer}
          >
            <div className={classes.formField}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h6"
                className="createStoreTitle"
                sx={{ textAlign: 'left' }}
              >
                Customer Migrate Configuration
              </Typography>
            </div>
            <div className={classes.formField}>
              <TextFieldComponent
                label={'Old Mobile number*'}
                name={'oldMobileNumber'}
                onChange={handleChange}
                value={values.oldMobileNumber}
                error={touched.oldMobileNumber && errors.oldMobileNumber}
                type="number"
              />
              <TextFieldComponent
                label={'New mobile number*'}
                name={'newMobileNumber'}
                onChange={handleChange}
                value={values.newMobileNumber}
                error={touched.newMobileNumber && errors.newMobileNumber}
                type="number"
              />
            </div>
            {/* <div style={{ marginTop: 30 }}>
            <PurpleSwitch checked={true} />
            <span>Migrate Loyalty</span>
            <PurpleSwitch checked={true} />
            <span>Migrate NPS</span>
            <PurpleSwitch checked={true} />
            <span>Migrate Offers</span>
          </div> */}
            <div className={classes.submitButton}>
              <ButtonField
                variant="contained"
                label={'Cancel'}
                type="button"
                onClick={handleClose}
                style={{
                  color: '#F07663',
                  background: 'white',
                  marginRight: 15
                }}
              />
              <ButtonField
                variant="contained"
                label={buttonText}
                type="submit"
                style={{ background: '#F07663', color: 'white' }}
                disabled={disableSubmit}
              />
            </div>
          </form>
          <div
            className={classes.alignContainer}
            style={{ margin: '24px', padding: '24px' }}
          >
            <TableContainer
              className={classes.formField}
              style={{ height: migrate.length > 10 ? '600px' : 'auto' }}
            >
              <Table>
                <TableHeader
                  headers={['Old Number', 'New Number', 'Status']}
                  textAlign="center"
                />
                {isFetching ? (
                  <TableBodyLoader rowsPerPage={10} colSpan={3} />
                ) : (
                  <TableBody className={classes.tableBody}>
                    {migrate.map((customer, index) => (
                      <TableRow key={index}>
                        <TableCell align="center">
                          {customer.fromMobile}
                        </TableCell>
                        <TableCell align="center">
                          {customer.toMobile}
                        </TableCell>
                        <TableCell align="center">
                          {customer.externalSyncDone
                            ? 'Complete'
                            : 'Incomplete'}
                        </TableCell>
                      </TableRow>
                    ))}
                    {!migrate.length && (
                      <TableRow>
                        <TableCell colSpan={3} align="center">
                          No Data found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default MigrateCustomer;
