import config from '../../config';
import CampaignReportDownload from '../../models/TenantReports/TransactionWiseReportDownload';
import formatDate from '../../shared/utils/formatDate';

export const createCampaignReport = ({
  businessId,
  startDate,
  endDate
}: any): Promise<{ message: string; jobId: string }> => {
  return fetch(
    `${
      config.ruleServerUrl
    }/users/reports/download/campaign_report/${businessId}?startDate=${formatDate(
      startDate,
      'YYYY-MMM-DD'
    )}&endDate=${formatDate(endDate, 'YYYY-MMM-DD')}&type=DATEFILTER`,
    {
      credentials: 'include',
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    }
  ).then(r => r.json());
};

export const getCampaignReportDownload = ({
  page,
  offset
}: {
  page: number;
  offset: number;
}): Promise<CampaignReportDownload[]> => {
  return fetch(
    `${config.ruleServerUrl}/users/reports/view/downloads/campaign_report?offset=${offset}&page=${page}&columnName=filter.type&columnValue=DATEFILTER`,
    { credentials: 'include' }
  ).then(res => res.json());
};
