import React, { FC, useContext } from 'react';
import addIcon from '../../Assets/images/add_icon.svg';
import unchecked from '../../Assets/images/unchecked_icon.svg';
import customCheckedIcon from '../../Assets/images/check_box_icon.svg';
import minimizeIcon from '../../Assets/images/minimize_icon.svg';
import _, { get } from 'lodash';
import CheckboxTree from 'react-checkbox-tree';
import { BusinessUnitContext } from '../../OfferDefinition/BusinessUnitContext';
import SelectWithAutoComplete from '../SelectWithAutoComplete/SelectWithAutoComplete';
import { getDefaultOptions, isOptionValueAvailable } from './utility';

interface CategoryTreeProps {
  allProducts: any;
  productHierarchyTreeChecked: any;
  setProductHierarchyTreeChecked: any;
  productHierarchyTreeExpanded: any;
  setProductHierarchyTreeExpanded: any;
  setSelectedMatchingProducts: any;
  productsFilteredByFirstHierarchy: any;
  setProductsFilteredByFirstHierarchy: any;
  setSelectedProductsByPath: any;
}

const CategoryTree: FC<CategoryTreeProps> = ({
  allProducts,
  productHierarchyTreeChecked,
  setProductHierarchyTreeChecked,
  productHierarchyTreeExpanded,
  setProductHierarchyTreeExpanded,
  setSelectedMatchingProducts,
  productsFilteredByFirstHierarchy,
  setProductsFilteredByFirstHierarchy,
  setSelectedProductsByPath
}) => {
  const businessUnitContext = useContext(BusinessUnitContext);
  const productHierarchy = _.get(
    businessUnitContext,
    'business.productHierarchyNew',
    []
  );
  const setRecursiveHierarchyNodes: any = (
    hierarchyLevel: number,
    products: any,
    path: string
  ) => {
    if (hierarchyLevel === productHierarchy.length - 1) {
      return null;
    } else {
      console.log(products, 'product');
      return _.map(
        _.groupBy(
          products,
          `extendedData.${get(
            productHierarchy[hierarchyLevel],
            'casaFieldName'
          )}`
        ),
        (groupedProducts, node) => {
          // TODO: Need to refactor absolutePath logic
          const splitBy =
            hierarchyLevel === productHierarchy.length - 2 ? '' : '/';
          const absolutePath = path.concat(node, splitBy);
          return {
            label: node,
            value: absolutePath,
            children: setRecursiveHierarchyNodes(
              hierarchyLevel + 1,
              groupedProducts,
              absolutePath
            )
          };
        }
      );
    }
  };

  const getFirstProductHierarchy = () => {
    return _.head(productHierarchy);
  };
  const onCheckedTreeNode = (checked: any) => {
    setProductHierarchyTreeChecked(checked);
  };

  const onExpandedTreeNode = (expanded: any) => {
    setProductHierarchyTreeExpanded(expanded);
  };

  function getProductsByHierarchyNodes() {
    const getFirstHierarchy = get(getFirstProductHierarchy(), 'casaFieldName');
    const filteredProducts = _.filter(allProducts, p => {
      return !_.isEmpty(
        _.find(productsFilteredByFirstHierarchy, {
          value: _.get(p, `extendedData.${getFirstHierarchy}`, '')
        })
      );
    });
    return setRecursiveHierarchyNodes(0, filteredProducts, '');
  }

  const onChangeFirstHierarchySelect = (currentSelected: any) => {
    if (isOptionValueAvailable(currentSelected, 'Select All')) {
      currentSelected = getAllFirstHierarchyProductOptions();
    } else if (
      _.isEmpty(currentSelected) ||
      isOptionValueAvailable(currentSelected, 'Clear All')
    ) {
      setProductHierarchyTreeChecked([]);
      setSelectedProductsByPath([]);
      setSelectedMatchingProducts([]);
      setProductHierarchyTreeExpanded([]);
      currentSelected = [];
    }
    setProductsFilteredByFirstHierarchy(currentSelected);
  };
  function getUnionProductsFilteredByFirstHierarchy() {
    return _.concat(getDefaultOptions(), getAllFirstHierarchyProductOptions());
  }

  function getAllFirstHierarchyProductOptions() {
    const firstHierarchy: any = get(
      getFirstProductHierarchy(),
      'casaFieldName'
    );
    const firstLevelUnionProducts = _.unionBy(
      _.map(allProducts, v => v['extendedData']),
      firstHierarchy
    );
    return _.map(firstLevelUnionProducts, v => {
      return {
        label: _.get(v, firstHierarchy, ''),
        value: _.get(v, firstHierarchy, '')
      };
    });
  }
  console.log(getProductsByHierarchyNodes(), 'test');

  return (
    <div className={'product-hierarchy-tree-container'}>
      <p className={'product-hierarchy-main-header'}>{`${get(
        getFirstProductHierarchy(),
        'displayName'
      )}`}</p>
      <div>
        <div className={'px-5 py-2 product-select'}>
          <SelectWithAutoComplete
            options={getUnionProductsFilteredByFirstHierarchy()}
            values={productsFilteredByFirstHierarchy}
            onChange={v => onChangeFirstHierarchySelect(v)}
            label={get(getFirstProductHierarchy(), 'displayName')}
            size={'auto'}
          />
        </div>
      </div>
      <div className={'product-hierarchy-tree mx-4 px-4'}>
        <CheckboxTree
          nodes={getProductsByHierarchyNodes()}
          checked={productHierarchyTreeChecked}
          expanded={productHierarchyTreeExpanded}
          onCheck={checked => onCheckedTreeNode(checked)}
          onExpand={expanded => onExpandedTreeNode(expanded)}
          iconsClass="fa5"
          showNodeIcon={false}
          icons={{
            check: (
              <img
                src={customCheckedIcon}
                style={{ width: 12 }}
                alt={'checkedIcon'}
              />
            ),
            uncheck: (
              <img src={unchecked} style={{ width: 12 }} alt={'unchecked'} />
            ),
            halfCheck: (
              <img
                src={customCheckedIcon}
                alt={'halfCheckedIcon'}
                style={{ opacity: 0.5, width: 12 }}
              />
            ),
            expandClose: (
              <img src={addIcon} style={{ width: 12 }} alt={'addIcon'} />
            ),
            expandOpen: (
              <img
                src={minimizeIcon}
                style={{ width: 12 }}
                alt={'minimizeIcon'}
              />
            )
          }}
        />
      </div>
    </div>
  );
};

export default CategoryTree;
