/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState, useEffect } from 'react';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import filterStyle from './index.module.scss';
import _ from 'lodash';
import { Grid, Button } from '@material-ui/core';
import {
  getStoreTagOptionsByStoreOptions,
  getStoresWithAnyTags
} from '../../../services/store.service';
import { storeHealthDashboardGenerateReport } from '../../../services/TenantReports/StoreHealthDashboard.service';
import { ParentLink } from '../../../utils/ParentLink';
import { getCurrentUser } from '../../../services/user.service';
import Select, { components } from 'react-select';
import moment from 'moment';

const MoreSelectedBadge = ({ items }) => {
  const style = {
    marginLeft: 'auto',
    background: '#d4eefa',
    borderRadius: '4px',
    fontFamily: 'Open Sans',
    fontSize: '11px',
    padding: '3px',
    order: 99
  };

  const title = items.join(', ');
  const length = items.length;
  const label = `+ ${length} item${length !== 1 ? 's' : ''} selected`;

  return (
    <div style={style} title={title}>
      {label}
    </div>
  );
};

const Filter = ({ filters, setFilters, setJobId, setStores, setIsLoading }) => {
  // const user = useCurrentUser();
  const [getStores, setGetStores] = useState([]);
  const [storeOption, setStoreOption] = useState([]);
  const [tenantStore, setTenantStore] = useState([]);
  const [storeTagsOption, setStoreTagsOption] = useState([]);
  const [selectedTagsValues, setSelectedTagsValues] = useState([]);
  const [selectedStoreValues, setSelectedStoreValues] = useState([]);
  const [filterTags, setFilterTags] = useState({});
  const handleDateChange = (date, name) => {
    setFilters({ ...filters, [name]: moment(date).format('YYYY-MM-DD') });
    setIsLoading(true);
  };

  const handleGenerateReport = () => {
    const stores = selectedStoreValues
      .filter(function(item) {
        return item.value !== '*';
      })
      .map(function(obj) {
        return obj.value;
      });
    setStores(stores);
    storeHealthDashboardGenerateReport(
      filters.startDate,
      filters.endDate,
      stores
    ).then(res => {
      setJobId(res?.jobId);
    });
  };

  useEffect(() => {
    getCurrentUser().then(res => {
      return setGetStores([...res.tenant_stores]);
    });
  }, []);

  const changeTenantStoreFormat = () => {
    const options = getStores.map(store => {
      return {
        id: store.id,
        label: _.capitalize(store.storeNameAlias || store.store),
        value: store.store,
        tags: _.compact(
          _.map(store.tags, tag => {
            return { value: tag.value, type: tag.type };
          })
        )
      };
    });
    return options;
  };

  useEffect(() => {
    const data = changeTenantStoreFormat();
    setTenantStore([...data]);
    const getStore = getStores.map(store => {
      return {
        id: store.id,
        label: store.store,
        value: store.store
      };
    });

    setStoreOption([...getStore]);
    const getOptions = getStoreTagOptionsByStoreOptions(data);
    setStoreTagsOption([...getOptions]);
  }, [getStores]);

  const handleSelect = storeTags => {
    if (storeTags && storeTags.length > 0) {
      const data = getStoresWithAnyTags(tenantStore, storeTags);
      setStoreOption(data || []);
    } else {
      const data = changeTenantStoreFormat();
      setStoreOption(data || []);
    }
    setSelectedTagsValues(storeTags || []);
  };

  const MultiValue = ({ index, getValue, ...props }) => {
    const maxToShow = 1;
    const overflow = getValue()
      .slice(maxToShow)
      .map(x => x.label);

    return index < maxToShow ? (
      <components.MultiValue {...props} />
    ) : index === maxToShow ? (
      <MoreSelectedBadge items={overflow} />
    ) : null;
  };

  useEffect(() => {
    selectedTagsValues.forEach(item => {
      if (_.has(filterTags, item.type)) {
        setFilterTags({
          ...filterTags,
          [item?.type]: [...filterTags[item?.type], item?.value]
        });
      } else {
        setFilterTags({ ...filterTags, [item?.type]: [item?.value] });
      }
    });
  }, [selectedTagsValues]);

  function handleSelectedChange(value, event) {
    if (event.action === 'remove-value') {
      event.removedValue.value === '*'
        ? this.setState([])
        : this.setState(
            _.differenceBy(
              value,
              [{ value: event.removedValue.value }],
              'value'
            )
          );
    } else if (event.action === 'select-option' && event.option.value === '*') {
      this.setState(this.options);
    } else if (
      (event.action === 'deselect-option' || event.action === 'remove-value') &&
      event.option.value === '*'
    ) {
      this.setState([]);
    } else if (event.action === 'deselect-option') {
      this.setState(value.filter(o => o.value !== '*'));
    } else if (value.length === this.options.length - 1) {
      this.setState(this.options);
    } else {
      this.setState(value);
    }
    setIsLoading(true);
  }
  useEffect(() => {
    const stores = selectedStoreValues
      .filter(function(item) {
        return item.value !== '*';
      })
      .map(function(obj) {
        return obj.value;
      });
    setStores(stores);
  }, [selectedStoreValues]);

  const customStyles = {
    option: (base, state) => ({
      ...base,
      color: state.isSelected ? '#fff' : '#1e2022',
      backgroundColor: state.isSelected ? '#f2755fc2' : 'white',
      paddingLeft: '10px',
      marginBottom: state.isSelected && '3px',
      padding: '.5rem 3rem .5rem .5rem',
      cursor: 'pointer'
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    }
  };
  return (
    <div className={filterStyle.filterContainer}>
      <Grid
        container
        spacing={3}
        justifyContent="space-evenly"
        alignItems="center"
      >
        <Grid item xs={4} sm={4} md={3} lg={2}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              label="Start Date"
              value={filters.startDate}
              onChange={e => handleDateChange(e, 'startDate')}
              variant="inline"
              format="yyyy-MM-dd"
              maxDate={filters.endDate}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={6} sm={4} md={3} lg={2}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              label="End Date"
              value={filters.endDate}
              onChange={e => handleDateChange(e, 'endDate')}
              variant="inline"
              format="yyyy-MM-dd"
              style={{ marginLeft: '20px' }}
            />
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid item xs={6} sm={4} md={3} xl={3}>
          <Select
            isMulti={true}
            options={[{ label: 'All', value: '*' }, ...storeOption]}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            value={selectedStoreValues}
            placeholder={'Store'}
            onChange={handleSelectedChange}
            setState={setSelectedStoreValues}
            components={{ MultiValue }}
            styles={customStyles}
          />
        </Grid>
        <Grid item xs={4} lg={2}>
          <Select
            isMulti={true}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            value={selectedTagsValues}
            placeholder={'Filter by tags'}
            options={storeTagsOption}
            onChange={handleSelect}
            components={{ MultiValue }}
            styles={customStyles}
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={handleGenerateReport}
          >
            Generate
          </Button>
        </Grid>
        <Grid item>
          <ParentLink
            className={filterStyle.viewReportButton}
            to={`/reports/store-health-dashboard/view-report`}
          >
            <Button variant="contained" color="primary" disableElevation>
              View Report
            </Button>
          </ParentLink>
        </Grid>
      </Grid>
    </div>
  );
};

export default Filter;
