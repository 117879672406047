import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow
  } from '@material-ui/core';
  import React, { FC } from 'react';
  import LoyaltyReportDownload from '../../../models/TenantReports/TransactionWiseReportDownload';
  import TableBodyLoader from '../../../shared/components/TableBodyLoader/TableBodyLoader';
  import TableFooter from '../../../shared/components/TableFooter/TableFooter';
  import TableHeader from '../../../shared/components/TableHeader/TableHeader';
  import formatDate from '../../../shared/utils/formatDate';
  import Chip from '../../../shared/components/Chip/Chip';
  import { GetApp } from '@material-ui/icons';
  import config from '../../../config';
  import _, { isNull } from 'lodash';
import moment from 'moment';
import { downloadCSVHandler } from '../../downloadCSVHandler/downloadCSVHandler';
  interface UnblockLoyaltyReportDownloadTableProps {
    reports: LoyaltyReportDownload[];
    isLoading: boolean;
    page: number;
    offset: number;
    setPage: (page: number) => void;
    setOffset: (offset: number) => void;
  }
  
  const headings = [
    'S.No',
    'Report Generated on',
    'Data From',
    'Data To',
    'Status',
    'Download'
  ];
  
  const UnblockLoyaltyReportDownloadTable: FC<UnblockLoyaltyReportDownloadTableProps> = props => {
    const { reports, isLoading, page, offset, setPage, setOffset } = props;
    const getDownloadReportUrl = (url: string) => {
      if (isNull(url)) {
        return '';
      }
      const indexOfReportUrl = url.indexOf('/report') + 1;
      const date = url.substring(url.length - 17).slice(0, -4);
      downloadCSVHandler(
      `${config.ruleServerUrl}/download-file/${url.slice(indexOfReportUrl)}`,
      moment(parseInt(date)).format('DD-MM-YYYY')
    );
    };
    return (
      <TableContainer component={Paper} elevation={0}>
        <Table>
          <TableHeader headings={headings} align="center" />
          {isLoading ? (
            <TableBodyLoader colSpan={headings.length} rowsPerPage={offset} />
          ) : (
            <TableBody>
              {reports.map((report, index) => (
                <TableRow key={report.id}>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">
                    {' '}
                    {formatDate(report.createdAt)}{' '}
                  </TableCell>
                  <TableCell align="center">
                    {' '}
                    {formatDate(_.get(report?.data,'startDate'))}{' '}
                  </TableCell>
                  <TableCell align="center">
                    {formatDate(_.get(report?.data,'endDate'))}
                  </TableCell>
                  <TableCell align="center">
                    <Chip
                      label={report.status}
                      color={
                        report.status === 'COMPLETED'
                          ? 'success'
                          : report.status === 'FAILED'
                          ? 'error'
                          : 'primary-light'
                      }
                    />
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      disabled={report.status !== 'COMPLETED'}
                      component="button"
                    onClick={() =>
                      getDownloadReportUrl(report.errorFileLocation)
                    }>
                      <GetApp />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
          <TableFooter
            colSpan={headings.length}
            count={-1}
            page={page}
            rowsPerPage={offset}
            onPageChange={setPage}
            onRowPerPageChange={setOffset}
          />
        </Table>
      </TableContainer>
    );
  };
  
  export default UnblockLoyaltyReportDownloadTable;
  