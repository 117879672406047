import _ from 'lodash';
import React from 'react';
import { UserTracking } from '../UserTracking/UserTracking';

export default function DecisionsTray({ templateName, count }) {
  // console.log('COUNT', count);
  return (
    _.size(templateName) > 0 && (
      <div>
        <div
          style={{
            marginBottom: '20px',
            width: 'fit-content',
            height: 'fit-content',
            border: '1px solid #ff0637',
            backgroundColor: '#ffffff',
            borderRadius: '10px',
            transform: 'translateX(-35%)'
          }}
        >
          {_.map(templateName, name => (
            <div
              key={name}
              style={{
                margin: '20px',
                border: '2px solid #f0f0f0',
                backgroundColor: '#F9F9F9'
              }}
            >
              {name}
            </div>
          ))}
        </div>
        <UserTracking style={{ marginTop: '-20px' }} count={count} />
      </div>
    )
  );
}
