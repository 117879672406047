import React, { FC, useCallback, useContext, useEffect } from 'react';
import { ProductCardPopUp } from '../../../../ProductCard/ProductCardPopUp';
import noop from 'lodash/noop';
import { createStyles, Drawer, Theme } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import _, { isEmpty, set } from 'lodash';
import { BusinessUnitContext } from '../../../../../OfferDefinition/BusinessUnitContext';
import { makeStyles } from '@material-ui/core/styles';
import {
  getActualPrice,
  getHeadImage,
  getSavePrice,
  getSellingPrice
} from '../../../../Utiles/FlipbookProducts';
import { TileDataCollection } from '../../../../../../models/FlipbookV2/TileData/TileDataCollection.model';
import { FlipbookContext } from '../../../../Hooks/FlipbookContext';
import { CheckoutOption } from '../../../../../../models/FlipbookV2/flipbookV2.model';

interface ProductPopUpActionItemProps {
  setIsProductPopUpOpen: (state: boolean) => void;
  isProductPopUpOpen: boolean;
  cartItems: any;
  allProducts: any;
  setCartItems: (data: any) => void;
  setActionItemData: (data: any) => void;
}
const useModalStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  })
);

export const ProductPopUpActionItem: FC<ProductPopUpActionItemProps> = ({
  isProductPopUpOpen,
  setIsProductPopUpOpen,
  cartItems,
  allProducts,
  setCartItems,
  setActionItemData
}) => {
  const classes = useModalStyles();
  const [data, setData] = React.useState<any>();
  // const [selectedProduct, setSelectedProduct] = React.useState<any>();
  const flipbookContext = useContext(FlipbookContext);
  const currentFlipbookData = flipbookContext.flipbookContext;
  const businessUnitContext = useContext(BusinessUnitContext);
  const productHierarchy = _.get(
    businessUnitContext,
    'business.productHierarchy',
    []
  );

  const isMobileView = window.screen.width <= 600;

  const initialiseProductData = useCallback(firstProduct => {
    // setSelectedProduct(firstProduct);
    const newData = {
      selectedProduct: firstProduct,
      choosenHierarchy: []
    };
    const savePrice = !_.isEmpty(firstProduct) ? getSavePrice(firstProduct) : 0;
    const sellingPrice = !_.isEmpty(firstProduct)
      ? getSellingPrice(firstProduct)
      : 0;
    const actualPrice = !_.isEmpty(firstProduct)
      ? getActualPrice(firstProduct)
      : 0;
    const productImage = getHeadImage(firstProduct) || '';
    set(newData, 'savePrice', savePrice);
    set(newData, 'sellingPrice', sellingPrice);
    set(newData, 'actualPrice', actualPrice);
    set(newData, 'productImage', productImage);
    set(newData, 'inStock', _.get(firstProduct, 'inStock', ''));
    set(newData, 'countOnHand', _.get(firstProduct, 'countOnHand', 0));
    setData(newData);
  }, []);

  useEffect(() => {
    if (isEmpty(data) && !isEmpty(allProducts)) {
      initialiseProductData(allProducts);
    }
  }, [allProducts, data, initialiseProductData]);
  const handleProductPopUpClose = () => {
    setIsProductPopUpOpen(false);
    setData({});
    setActionItemData({});
  };
  const getProductCardPopUp = () => {
    return (
      <ProductCardPopUp
        tileDataCollection={
          new TileDataCollection(
            '',
            [],
            false,
            [],
            [],
            [],
            { title: '', subTitle: '' },
            [{ sku: allProducts?.extendedData?.sku, path: '' }]
          )
        }
        data={data}
        setSelectedProduct={noop}
        productHierarchy={productHierarchy}
        allProducts={[allProducts]}
        cartItems={cartItems}
        setCartItems={setCartItems}
        checkoutFlow={
          currentFlipbookData?.checkoutOption || CheckoutOption.FlipbookCheckout
        }
        handleProductPopUpClose={handleProductPopUpClose}
        isStockLoaded={false}
      />
    );
  };
  if (isEmpty(data)) {
    return <></>;
  }

  return isMobileView ? (
    <React.Fragment key={'bottom'}>
      <Drawer
        anchor={'bottom'}
        open={isProductPopUpOpen}
        onClose={() => handleProductPopUpClose()}
      >
        {getProductCardPopUp()}
      </Drawer>
    </React.Fragment>
  ) : (
    <Modal
      open={isProductPopUpOpen}
      onClose={() => handleProductPopUpClose()}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modal}
    >
      {getProductCardPopUp()}
    </Modal>
  );
};
