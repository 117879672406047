/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { FC, useEffect, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import moment from 'moment';
import { Button } from '@material-ui/core';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { get, head, isEqual, isUndefined } from 'lodash';
import { createTierDowngradeReport } from '../../../services/TenantReports/TierDowngradeReport.service';

interface TierDowngradeReport {
  startDate: string;
  businessId?: number;
}

const tierDowngradeInitialState: TierDowngradeReport = {
  startDate: moment()
    .subtract(1, 'month')
    .subtract(1, 'day')
    .format('YYYY-MM-DD'),
  businessId: undefined
};

interface ReportActionsProps {
  setRefreshView: (id: string) => void;
}

const TierDowngradeReport: FC<ReportActionsProps> = ({ setRefreshView }) => {
  const [filters, setFilters] = useState<TierDowngradeReport>(
    tierDowngradeInitialState
  );
  const user = useCurrentUser();

  const handleDateChange = (date: any, name: string) => {
    setFilters({ ...filters, [name]: date });
  };

  useEffect(() => {
    if (isUndefined(filters.businessId) && !isEqual(user.id, 0)) {
      const business = user.getBusinesses();
      const headBusiness = head(business);
      const defaultBusinessId = get(headBusiness, 'id', 0);
      setFilters({ ...filters, businessId: defaultBusinessId });
    }
  }, [filters, user]);

  const handleGenerateReport = (): void => {
    createTierDowngradeReport(filters).then((res: any) => {
      const jobId = get(res, 'jobId', '');
      setRefreshView(jobId);
    });
  };
  const maxDate = moment(new Date())
    .subtract('days', 1)
    .toDate();
  return (
    <div
      style={{
        margin: '30px 50px',
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      <div>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            label="Date"
            maxDate={maxDate}
            value={filters.startDate}
            onChange={e => handleDateChange(e, 'startDate')}
            variant="inline"
            views={['year', 'month']}
            format="yyyy-MM-dd"
          />
        </MuiPickersUtilsProvider>
      </div>
      <Button
        variant="contained"
        color="primary"
        disableElevation
        onClick={handleGenerateReport}
      >
        Generate Report
      </Button>
    </div>
  );
};

export default TierDowngradeReport;
