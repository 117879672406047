/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { FC, useEffect, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { getMemberLoyaltyReport } from '../../../../services/TenantReports/MemberLoyalty.service';
import moment from 'moment';
import { Button } from '@material-ui/core';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import { get, head, isEqual, isUndefined } from 'lodash';

interface LoyaltyReport {
  startDate: string;
  endDate: string;
  businessId?: number;
}

const loyaltyInitialState: LoyaltyReport = {
  startDate: moment()
    .subtract(1, 'day')
    .format('YYYY-MM-DD'),
  endDate: moment().format('YYYY-MM-DD'),
  businessId: undefined
};

interface ReportActionsProps {
  setRefreshView: (id: string) => void;
}

const MemberLoyaltyReport: FC<ReportActionsProps> = ({ setRefreshView }) => {
  const [filters, setFilters] = useState<LoyaltyReport>(loyaltyInitialState);
  const user = useCurrentUser();

  const handleDateChange = (date: any, name: string) => {
    setFilters({ ...filters, [name]: date });
  };

  useEffect(() => {
    if (isUndefined(filters.businessId) && !isEqual(user.id, 0)) {
      const business = user.getBusinesses();
      const headBusiness = head(business);
      const defaultBusinessId = get(headBusiness, 'id', 0);
      setFilters({ ...filters, businessId: defaultBusinessId });
    }
  }, [filters, user]);

  const handleGenerateReport = (): void => {
    getMemberLoyaltyReport(filters).then((res: any) => {
      const jobId = get(res, 'jobId', '');
      setRefreshView(jobId);
    });
  };
  return (
    <div
      style={{
        margin: '30px 50px',
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          label="Date"
          value={filters.startDate}
          onChange={e => handleDateChange(e, 'startDate')}
          variant="inline"
          format="yyyy-MM-dd"
        />
      </MuiPickersUtilsProvider>
      <Button
        variant="contained"
        color="primary"
        disableElevation
        onClick={handleGenerateReport}
      >
        Generate Report
      </Button>
    </div>
  );
};

export default MemberLoyaltyReport;
