import { DependencyList, useEffect, useRef, useState } from 'react';
import { QueryFn } from './types';

interface UseFetch<T> {
  data: T | null;
  isLoading: boolean;
  error: string | null;
}

const useFetch = <T>(
  queryFn: QueryFn<T>,
  deps: DependencyList = []
): UseFetch<T> => {
  const queryFnRef = useRef<QueryFn<T>>(queryFn);
  const [data, setData] = useState<T | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    queryFnRef.current = queryFn;
  }, [queryFn]);

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        setIsLoading(true);
        const response = await queryFnRef.current();
        setData(response);
      } catch (error) {
        setError('Something went wrong!');
      } finally {
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return { data, isLoading, error };
};

export default useFetch;
