import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@material-ui/core';
import React, { FC } from 'react';
import FraudReportDownload from '../../../models/TenantReports/MemberWiseReport';
import TableBodyLoader from '../../../shared/components/TableBodyLoader/TableBodyLoader';
import TableFooter from '../../../shared/components/TableFooter/TableFooter';
import TableHeader from '../../../shared/components/TableHeader/TableHeader';
import formatDate from '../../../shared/utils/formatDate';
import Chip from '../../../shared/components/Chip/Chip';
import { GetApp } from '@material-ui/icons';
import config from '../../../config';
import { isNull } from 'lodash';

interface FraudReportDownloadTableProps {
  reports: FraudReportDownload[];
  isLoading: boolean;
  page: number;
  offset: number;
  setPage: (page: number) => void;
  setOffset: (offset: number) => void;
}

const headings = [
  'S.No',
  'Date Triggered',
  'Start Date',
  'End Date',
  'Status',
  'Download'
];

const FraudReportDownloadTable: FC<FraudReportDownloadTableProps> = props => {
  const { reports, isLoading, page, offset, setPage, setOffset } = props;
  const getDownloadReportUrl = (url: string): string => {
    if (isNull(url)) {
      return '';
    }
    const indexOfReportUrl = url.indexOf('/report') + 1;

    return `${config.ruleServerUrl}/download-file/${url.slice(
      indexOfReportUrl
    )}`;
  };
  return (
    <TableContainer component={Paper} elevation={0}>
      <Table>
        <TableHeader headings={headings} align="center" />
        {isLoading ? (
          <TableBodyLoader colSpan={headings.length} rowsPerPage={offset} />
        ) : (
          <TableBody>
            {reports.map((report, index) => (
              <TableRow key={report.id}>
                <TableCell align="center">{index + 1}</TableCell>
                <TableCell align="center">
                  {' '}
                  {formatDate(report.createdAt)}{' '}
                </TableCell>
                <TableCell align="center">
                  {' '}
                  {formatDate(report.data.startDate)}{' '}
                </TableCell>
                <TableCell align="center">
                  {formatDate(report.data.endDate)}
                </TableCell>
                <TableCell align="center">
                  <Chip
                    label={report.status}
                    color={
                      report.status === 'COMPLETED'
                        ? 'success'
                        : report.status === 'FAILED'
                        ? 'error'
                        : 'primary-light'
                    }
                  />
                </TableCell>
                <TableCell align="center">
                  <IconButton
                    disabled={report.status !== 'COMPLETED'}
                    component="a"
                    href={getDownloadReportUrl(report.errorFileLocation)}
                    download
                  >
                    <GetApp />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
        <TableFooter
          colSpan={headings.length}
          count={-1}
          page={page}
          rowsPerPage={offset}
          onPageChange={setPage}
          onRowPerPageChange={setOffset}
        />
      </Table>
    </TableContainer>
  );
};

export default FraudReportDownloadTable;
