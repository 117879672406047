import config from '../../config/index';
import moment from 'moment';
import KPIReportDownloadInterface from '../../models/TenantReports/KPIReportDownload';
import { lowerCase } from 'lodash';

export const getKpiReport = (filters: any, type: string) => {
  type = lowerCase(type);
  return fetch(
    `${config.ruleServerUrl}/users/reports/download/kpi_report/${
      filters.businessId
    }?date=${moment(filters.reportFilterDate).format('YYYY-MM-DD')}&type=${type}
    `,
    {
      credentials: 'include',
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    }
  ).then(r => r.json());
};

export const getKpiReportDownload = ({
  page,
  offset,
  filters
}: {
  page: number;
  offset: number;
  filters?: any;
}): Promise<KPIReportDownloadInterface[]> => {
  const columnValue = lowerCase(filters);
  return fetch(
    `${config.ruleServerUrl}/users/reports/view/downloads/kpi_report?offset=${offset}&page=${page}&columnName=type&columnValue=${columnValue}`,
    { credentials: 'include' }
  ).then(res => res.json());
};
