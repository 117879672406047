/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { Box, Typography, Modal, Tooltip } from '@material-ui/core';
import ButtonField from '../../reusable/ButtonField';
import InfoLogo from '../Assets/infoicon.svg';
import storeClass from './index.module.scss';
import TenentStoreService from '../../services/tenentStore.service';

const boxStyle = {
  position: 'absolute',
  top: '47%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  bgcolor: 'background.paper',
  outline: 'none',
  boxShadow: 24,
  fontFamily: 'nunito'
};

const IOSSwitch = withStyles(theme => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1)
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#f26e5f',
        opacity: 1,
        border: 'none'
      }
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff'
    }
  },
  thumb: {
    width: 24,
    height: 24
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border'])
  },
  checked: {},
  focusVisible: {}
}))(({ classes, ...props }) => {
  return (
    <div>
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked
        }}
        {...props}
      />
    </div>
  );
});

export default function TableSwitch(props) {
  const {
    earnlabel,
    checkedValue,
    blockLable,
    statusCheck,
    disabled,
    original,
    storeStatus,
    setStoreStatus,
    type,
    loyaltyEarn,
    disableSwitch
  } = props;
  const [switchState, setSwitchState] = React.useState(checkedValue);
  const [open, setOpen] = React.useState(false);

  const handleClose = () => setOpen(false);

  const handleChange = event => {
    if (disableSwitch) {
      return;
    }
    if (statusCheck && switchState) {
      setOpen(true);
    } else if (loyaltyEarn && switchState) {
      let workData = { ...original, [type]: false };
      if (loyaltyEarn)
        workData = {
          ...workData,
          blockLoyaltyEarn: !workData.blockLoyaltyEarn
        };
      TenentStoreService.saveTenentStoreConfig(workData.tenantBrandBusinessId, [
        workData
      ]).then(() => {
        setStoreStatus(!storeStatus);
      });
      setSwitchState(event.target.checked);
    } else {
      let workData = { ...original, [type]: true };
      if (loyaltyEarn)
        workData = {
          ...workData,
          blockLoyaltyEarn: !workData.blockLoyaltyEarn
        };
      TenentStoreService.saveTenentStoreConfig(workData.tenantBrandBusinessId, [
        workData
      ]).then(() => {
        setStoreStatus(!storeStatus);
      });
      setSwitchState(event.target.checked);
    }
  };
  const handleSubmit = () => {
    const workData = { ...original, [type]: false };
    TenentStoreService.saveTenentStoreConfig(workData.tenantBrandBusinessId, [
      workData
    ]).then(() => {
      setStoreStatus(!storeStatus);
    });
    setSwitchState(false);
    setStoreStatus(!storeStatus);
    setOpen(false);
  };

  return (
    <FormGroup>
      <Tooltip
        title={
          disableSwitch
            ? 'The button would be enabled once the feature is completed. Currently in Progress'
            : ''
        }
      >
        <FormControlLabel
          control={
            <IOSSwitch
              checked={switchState}
              onChange={handleChange}
              disabled={disabled}
            />
          }
          label={switchState ? earnlabel : blockLable}
          style={{ color: disableSwitch ? 'gray' : '' }}
        />
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={boxStyle} style={{ padding: '20px 30px', borderRadius: 4 }}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h6"
            className={storeClass.modalTitle}
          >
            <img src={InfoLogo} alt="info" style={{ width: 25, height: 25 }} />
            <span style={{ marginLeft: 15 }}>Hi there...Just a heads up!</span>
          </Typography>
          <div style={{ fontSize: 16, marginTop: 15 }}>
            Deactivating a store would have impact on graphs, analytics for that
            particular store.
          </div>
          <div style={{ fontSize: 16, margin: '15px 0', fontWeight: 600 }}>
            Would you still wish to deactivate the store?
          </div>
          <div className={storeClass.buttonStyle}>
            <ButtonField
              variant="text"
              label={'Cancel'}
              type="button"
              style={{ color: '#F2755F' }}
              onClick={handleClose}
            />
            <ButtonField
              variant="contained"
              label={'Deactivate'}
              type="button"
              style={{ background: '#FFE8E3', color: '#F2755F' }}
              onClick={handleSubmit}
            />
          </div>
        </Box>
      </Modal>
    </FormGroup>
  );
}
