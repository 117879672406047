export const loyaltyList = [
  {
    path: '/loyalty-tiers',
    title: 'Loyalty Tier Configuration'
  },
  {
    path: '/loyalty-config',
    title: 'Loyalty Offer Configuration'
  },
  {
    path: '/loyalty-exception',
    title: 'Loyalty exclusions Configuration'
  },
  {
    path: '/ad-hoc-points',
    title: 'Ad Hoc Points'
  },
  {
    path: '/loyalty-redeem-configuration',
    title: 'Loyalty Redemption Configuration',
    className: 'activeHeading'
  },
  {
    path: '/referral-configuration',
    title: 'Referral Configuration'
  }
];

export const referralConfiguration = [
  {
    path: '/loyalty-tiers',
    title: 'Loyalty Tier Configuration'
  },
  {
    path: '/loyalty-config',
    title: 'Loyalty Offer Configuration'
  },
  {
    path: '/loyalty-exception',
    title: 'Loyalty exclusions Configuration'
  },
  {
    path: '/ad-hoc-points',
    title: 'Ad Hoc Points'
  },
  {
    path: '/loyalty-redeem-configuration',
    title: 'Loyalty Redemption Configuration'
  },
  {
    path: '/referral-configuration',
    title: 'Referral Configuration',
    className: 'activeHeading'
  }
];
