import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@material-ui/core';
import React, { FC } from 'react';
import TableBodyLoader from '../../../shared/components/TableBodyLoader/TableBodyLoader';
import TableFooter from '../../../shared/components/TableFooter/TableFooter';
import TableHeader from '../../../shared/components/TableHeader/TableHeader';
import formatDate from '../../../shared/utils/formatDate';
import Chip from '../../../shared/components/Chip/Chip';
import { isNull } from 'lodash';
import { toast } from 'react-toastify';
import zipDownloadIcon from '../Assets/zip-download.png';
import { downloadReportFile } from '../../../services/TenantReports/common';
import moment from 'moment';
import LinearProgressWithLabel from '../Shared/LinearProgressWithLabel';
import OfferReportDownload from '../../../models/TenantReports/OfferReportDownload';

interface OfferReportDownloadTableProps {
  reports: OfferReportDownload[];
  isLoading: boolean;
  page: number;
  offset: number;
  setPage: (page: number) => void;
  setOffset: (offset: number) => void;
}

const headings = [
  'S.No',
  'Date Triggered',
  'Start Date',
  'End Date',
  'Progress',
  'Status',
  'Download'
];

const OfferReportDownloadTable: FC<OfferReportDownloadTableProps> = props => {
  const { reports, isLoading, page, offset, setPage, setOffset } = props;

  const getFileNameForDownload = () => {
    return `Offer_Report_${moment().valueOf()}.zip`;
  };
  const getDownloadReportUrl = async (
    report: OfferReportDownload
  ): Promise<any> => {
    const url = report.errorFileLocation;
    try {
      if (isNull(url)) {
        return;
      }
      const fileName = getFileNameForDownload();
      await downloadReportFile(url, fileName);
    } catch (e) {
      toast.error('Something went wrong!!!');
    }
  };
  return (
    <TableContainer component={Paper} elevation={0}>
      <Table>
        <TableHeader headings={headings} align="center" />
        {isLoading ? (
          <TableBodyLoader colSpan={headings.length} rowsPerPage={offset} />
        ) : (
          <TableBody>
            {reports.map((report, index) => (
              <TableRow key={report.id}>
                <TableCell align="center">{index + 1}</TableCell>
                <TableCell align="center">
                  {' '}
                  {formatDate(report.createdAt)}{' '}
                </TableCell>
                <TableCell align="center">
                  {' '}
                  {formatDate(report.data.date.startDate)}{' '}
                </TableCell>
                <TableCell align="center">
                  {formatDate(report.data.date.endDate)}
                </TableCell>
                <TableCell align="center">
                  <LinearProgressWithLabel
                    total={report.total}
                    currentValue={report.processedRecords}
                  />
                </TableCell>
                <TableCell align="center">
                  <Chip
                    label={report.status}
                    color={
                      report.status === 'COMPLETED'
                        ? 'success'
                        : report.status === 'FAILED'
                        ? 'error'
                        : 'primary-light'
                    }
                  />
                </TableCell>
                <TableCell align="center">
                  <Button
                    onClick={() => getDownloadReportUrl(report)}
                    disabled={report.status !== 'COMPLETED'}
                  >
                    <img
                      src={zipDownloadIcon}
                      alt={'download'}
                      style={{
                        width: '3rem',
                        opacity: `${
                          report.status !== 'COMPLETED' ? '50%' : '100%'
                        }`
                      }}
                    />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
        <TableFooter
          colSpan={headings.length}
          count={-1}
          page={page}
          rowsPerPage={offset}
          onPageChange={setPage}
          onRowPerPageChange={setOffset}
        />
      </Table>
    </TableContainer>
  );
};

export default OfferReportDownloadTable;
