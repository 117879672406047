import React, { FC } from 'react';
import BookIcon from '../Assets/images/icons/undraw_book.svg';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import LinkIcon from '@material-ui/icons/Link';
import FlipbookIcon from '../Assets/images/Icon feather-book-open@2x.png';
import StoreIcon from '../Assets/images/Icon material-store@2x.png';
import EcommerceIcon from '../Assets/images/Icon material-laptop-chromebook@2x.png';
import _ from 'lodash';
import { AVAILABLE_CHECKOUTS } from './Utiles/FlipbookConfig';

interface FlipbookCardProds {
  flipbook: any;
  redirectToEdit: (flipbookId: number) => void;
  redirectToView: (flipbookId: number) => void;
  getFlipbookURL: (flipbookId: number) => void;
  isEditable: boolean;
  hasPermission: (permission: string) => boolean;
}
const CHECKOUT_ICONS = {
  FlipbookCheckout: FlipbookIcon,
  Ecommerce: EcommerceIcon,
  Store: StoreIcon
};

const FlipbookCard: FC<FlipbookCardProds> = ({
  flipbook,
  redirectToEdit,
  redirectToView,
  getFlipbookURL,
  isEditable,
  hasPermission
}) => {
  return (
    <div
      style={{
        margin: '1rem',
        padding: '0.5rem',
        borderRadius: '0.5rem',
        border: '1px solid #DEDEDE',
        boxShadow: '0px 1px 3px #00000029'
      }}
    >
      <div className="flipbookCardTitle">{flipbook.description}</div>
      {/*<div style={{ marginBottom: "1rem" }}><span style={{ color: "#404040" }}>Short Code: </span><span style={{ fontWeight: "bolder" }}>{flipbook.shortCode}</span></div>*/}
      <div className="mb-3 text-center">
        <img className={'flipbookIcon'} src={BookIcon} alt="" />
      </div>
      <div>
        <div className={'d-flex'}>
          <div
            onClick={() => redirectToView(flipbook.id)}
            className={`flpbk-action-btn flipbookCardBtn ${
              hasPermission('view_flipbook') ? '' : 'flipbook-edit-btn-block'
            }`}
          >
            <VisibilityIcon
              fontSize="small"
              className="uploadIcon flipbookCardBtnIcon"
            />
            View
          </div>
          <div
            onClick={() => redirectToEdit(flipbook.id)}
            className={`flpbk-action-btn flipbookCardBtn ${
              isEditable ? '' : 'flipbook-edit-btn-block'
            }`}
          >
            <EditIcon
              fontSize="small"
              className="uploadIcon flipbookCardBtnIcon"
            />
            Edit
          </div>
        </div>
        <div className={'flipbook-type'}>
          <div className={'flipbook-checkout-icon-container'}>
            <img
              src={_.get(CHECKOUT_ICONS, flipbook.checkoutOption, undefined)}
              alt={flipbook.checkoutOption}
              className={'flipbook-checkout-icon'}
              title={_.get(
                AVAILABLE_CHECKOUTS,
                `${flipbook.checkoutOption}.name`,
                ''
              )}
            />
          </div>
          {hasPermission('get_flipbook_link') && (
            <div
              className={`flipbookLink`}
              onClick={() => getFlipbookURL(flipbook.id)}
            >
              Copy Link
              <LinkIcon fontSize="small" className="ml-1" />
            </div>
          )}  
        </div>
      </div>
    </div>
  );
};

export default FlipbookCard;
