/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import DetailInput from './DetailInput/DetailInput';
import NodeTypes from '../../BaseNodeModel/NodeTypes';
import detailsInput from './DetailInput/DetailInput.module.css';
import inputRow from './InputRow/InputRow.module.css';
import DecisionsInput from '../DetailInputComponents/DecisionsInput/DecisionsInput';
import { TemplateInput } from '../DetailInputComponents/TemplateInput/TemplateInput';

export const DetailsPanel = ({
  nodeDetails,
  repaintCanvas,
  setShow,
  isReadOnly
}) => {
  const { ruleTemplates, setInputFields } = nodeDetails;
  const [specs, setSpecs] = useState([]);
  const [templateId, setTemplateId] = useState(nodeDetails.templateId);
  const [templateName, setTemplateName] = useState();
  const [messageTemplateData, setMessageTemplateData] = useState(
    nodeDetails.node.messageTemplateData
  );

  function getDateCheckRegex() {
    const timeUnits = ['day', 'week', 'month', 'minute', 'min', 'year'];
    const timeUnitGroups = timeUnits
      .map(tu => {
        const timeUnitRegex = tu
          .split('')
          .map(c => `(${c.toUpperCase()}|${c.toLowerCase()})`)
          .join('');
        return `(${timeUnitRegex})`;
      })
      .join('|');
    return new RegExp(`^[0-9]+ (${timeUnitGroups})(S|s)?$`);
  }

  useEffect(() => {
    if (!_.isEmpty(nodeDetails.specs)) {
      setSpecs(nodeDetails.specs);
    }
  }, []);

  // const canSave = specs => {
  //   const isAllFieldsFilled = _.map(specs, spec => {
  //     if (!_.isEmpty(spec.value)) {
  //       if (!_.isEmpty(spec.children)) {
  //         return canSave(spec.children[spec.value]);
  //       }
  //       return true;
  //     }
  //
  //     return false;
  //   });
  //
  //   return _.every(isAllFieldsFilled);
  // };
  const changeValue = (arr, idx, value) => {
    const newArr = [...arr];
    newArr[idx].value = value;
    return newArr;
  };

  const changeChildren = (arr, idx, children) => {
    const newArr = [...arr];
    newArr[idx].children = children;
    return newArr;
  };

  const displaySpecs = value => {
    let currentRuleTemplates = [];
    if (nodeDetails.type === NodeTypes.DECISION_NODE) {
      const templates = _.flatMap(
        _.map(
          Object.keys(ruleTemplates),
          templateCategory => ruleTemplates[templateCategory]
        )
      );
      currentRuleTemplates = templates.filter(template => {
        return (
          _.includes(value, template.name) || _.includes(value, template.id)
        );
      });
    } else {
      currentRuleTemplates = ruleTemplates.filter(template => {
        return (
          _.includes(value, template.name) || _.includes(value, template.id)
        );
      });
    }
    const specs = _.flatMap(
      _.map(currentRuleTemplates, template => template.specs)
    );
    const uniqueSpecs = [
      ...new Map(
        specs.map(spec => [spec['templatePlaceholder'], spec])
      ).values()
    ];
    setSpecs(uniqueSpecs);
    setTemplateId(value);
  };

  useEffect(() => {
    if (_.includes([NodeTypes.TRIGGER_NODE], nodeDetails.type)) {
      if (_.isEmpty(ruleTemplates)) {
        setSpecs([]);
      } else {
        let ruleTemplate = ruleTemplates[0];
        if (!_.isEmpty(nodeDetails.templateId)) {
          ruleTemplate = _.filter(ruleTemplates, r =>
            _.includes(nodeDetails.templateId, r.id)
          );
          setSpecs(_.flatMap(nodeDetails.specs));
        } else {
          setSpecs(ruleTemplate.specs);
        }
        setTemplateId(ruleTemplate.id);
        setTemplateName(ruleTemplate.name);
      }
    } else {
      setTemplateId(nodeDetails.node.templateId);
      setSpecs(nodeDetails.specs);
    }
  }, [nodeDetails]);

  function runTestsOnSpecs() {
    const errors = [];
    let allPass = true;
    _.forEach(specs, ({ label, value, type }) => {
      const isPass =
        type === 'date' ? getDateCheckRegex().test(value) : !_.isEmpty(value);
      if (!isPass) {
        errors.push(
          type === 'date'
            ? `'${label}' has an incorrect value. Eg. 5 days.`
            : `'${label}' can't be empty`
        );
        allPass = false;
      }
    });
    return [allPass, errors];
  }

  function validateMsgTemplateData() {
    const errors = [];
    let allPass = true;
    if (_.isEmpty(messageTemplateData)) {
      allPass = false;
      errors.push(`Please enter Message Template Id`);
    }
    _.forEach(messageTemplateData, template => {
      if (
        _.isEmpty(template.id) ||
        _.isEmpty(template.order) ||
        _.isEmpty(template.name) ||
        _.isEmpty(template.value)
      ) {
        errors.push(`Order, Name or Value can't be empty`);
        allPass = false;
      }
    });
    return [allPass, errors];
  }

  function validateSMSNode() {
    const isSMSNode = nodeDetails.node.name === 'Send SMS';
    if (isSMSNode) {
      return validateMsgTemplateData();
    }
    return [true, []];
  }

  function validateActionNode() {
    const testsToRun = [runTestsOnSpecs, validateSMSNode];
    const results = _.map(testsToRun, test => test());
    return _.reduce(
      results,
      (acc, result) => {
        const [isPass, errors] = result;
        acc[0] = acc[0] && isPass;
        acc[1] = [...acc[1], ...errors];
        return acc;
      },
      [true, []]
    );
  }

  function validateDecisionNode() {
    if (_.isEmpty(templateName)) {
      return [false, ['Please choose at least 1 decision.']];
    }
    return [true, []];
  }

  function validateSpecs() {
    // format to return [isPass, errors]
    if (nodeDetails.type === NodeTypes.DECISION_NODE) {
      return validateDecisionNode();
    }
    if (nodeDetails.type === NodeTypes.ACTION_NODE) {
      return validateActionNode();
    }
    return runTestsOnSpecs();
  }

  return (
    <div>
      <div style={isReadOnly ? { pointerEvents: 'none', opacity: '0.5' } : {}}>
        {ruleTemplates.length > 0 &&
          nodeDetails.type !== NodeTypes.DECISION_NODE && (
            <div className={detailsInput.container}>
              <span style={{ width: '240px' }}>Templates</span>

              <select
                className={[
                  inputRow.customInput,
                  inputRow.customDropdownArrow
                ].join(' ')}
                onChange={e => {
                  setTemplateName(e.target.value);
                  displaySpecs(e.target.value);
                }}
                value={templateId}
              >
                {ruleTemplates?.map(template => (
                  <option value={template.id} key={template.id}>
                    {template.name}
                  </option>
                ))}
              </select>
            </div>
          )}
        {nodeDetails.type === NodeTypes.DECISION_NODE && (
          <DecisionsInput
            options={ruleTemplates}
            placeholder="Decisions"
            values={_.map(templateId, id => {
              const templates = _.flatMap(
                _.map(
                  Object.keys(ruleTemplates),
                  templateCategory => ruleTemplates[templateCategory]
                )
              );
              const template = _.find(templates, template => {
                if (id === template.id) {
                  return template.name;
                }
              });

              return template.name;
            })}
            height="75px"
            onChange={values => {
              const templates = _.flatMap(
                _.map(
                  Object.keys(ruleTemplates),
                  templateCategory => ruleTemplates[templateCategory]
                )
              );
              const selectedDecisions = templates.filter(template => {
                return _.includes(values, template.name);
              });

              displaySpecs(values);
              setTemplateName(
                _.map(
                  selectedDecisions,
                  selectedDecision => selectedDecision.name
                )
              );
              setTemplateId(
                _.map(
                  selectedDecisions,
                  selectedDecision => selectedDecision.id
                )
              );
            }}
            isSearchEnabled={false}
          />
        )}

        {specs?.map((spec, idx) => (
          <DetailInput
            key={`${spec.name}-${spec.templatePlaceholder}`}
            detail={spec}
            setDetail={value => setSpecs(changeValue(specs, idx, value))}
            setChildren={value => setSpecs(changeChildren(specs, idx, value))}
          />
        ))}

        <TemplateInput
          detail={nodeDetails.node}
          setDetail={value => {
            setMessageTemplateData(value);
          }}
        />
      </div>
      {/* ---------------- */}
      <div className={detailsInput.buttonsContainer}>
        <div className={detailsInput.save} onClick={() => setShow(false)}>
          close
        </div>
        <div
        // style={
        //   canSave(specs) ? {} : { cursor: 'not-allowed', opacity: '0.4' }
        // }
        >
          <div
            className={detailsInput.save}
            // style={canSave(specs) ? {} : { pointerEvents: 'none' }}
            style={isReadOnly ? { pointerEvents: 'none', opacity: '0.5' } : {}}
            onClick={() => {
              const [isPass, errors] = validateSpecs();
              if (isPass) {
                setInputFields({
                  templateName,
                  templateId,
                  specs,
                  messageTemplateData
                });
                setShow(false);
                repaintCanvas();
              } else {
                alert(errors.join('\n'));
              }
            }}
          >
            Save Details
          </div>
        </div>
      </div>
    </div>
  );
};
