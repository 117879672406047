import _ from 'lodash';
import { SectionType } from '../../DynamicInvoiceGenerator/InvoiceGenerator';
import BusinessDetails from '../components/section/BusinessDetails/BusinessDetails';
import MiscInfo from '../components/section/MiscInfo/MiscInfo';
import Preview from '../components/section/Preview/preview';
import CustomerDetails from '../components/section/CustomerDetails/CustomerDetails';
import OrderDetails from '../components/section/OrderDetails/OrderDetails';
import InvoiceDetails from '../components/section/InvoiceDetails/InvoiceDetails';
import OrderSummary from '../components/section/OrderSummary/OrderSummary';
import HsnSummary from '../components/section/HsnSummary/HsnSummary';

const stepperFields = {
  [SectionType.TENANT_INFO]: 'Business Details',
  [SectionType.CUSTOMER_INFO]: 'Customer Details',
  [SectionType.ORDER_INFO]: 'Invoice Details',
  [SectionType.ORDER_LIST]: 'Order Summary',
  [SectionType.MISC_INFO]: 'Terms and Condition'
};

const stepperTypeComponentMap = {
  [SectionType.TENANT_INFO]: [BusinessDetails],
  [SectionType.CUSTOMER_INFO]: [CustomerDetails],
  [SectionType.ORDER_INFO]: [InvoiceDetails],
  [SectionType.ORDER_LIST]: [OrderDetails, OrderSummary, HsnSummary],
  [SectionType.MISC_INFO]: [MiscInfo],
  [SectionType.PREVIEW]: [Preview]
};
const defaultSections = _.keys(stepperTypeComponentMap);
export const PREVIEW_INDEX = defaultSections.length - 1;
export const DEFAULT_SECTION_TYPE = SectionType.TENANT_INFO
export const CONFIG_TYPE = "invoiceGenerator";
const INVOICE_CONFIG_TYPE = 'invoiceGenerator';
const standardBill = {
  tenantName: 'Mufti - from bill',
  walkInInterests: null,
  externalCustomerId: '9310611597',
  loyalty: {
    businessUnit: {
      name: 'crimsouneclub',
      id: 94,
      tier: 'Silver',
      points: 1231.9800000000002,
      expiries: []
    },
    group: {
      points: 1231.9800000000002
    }
  },
  upsertId: 'earbud',
  daysSinceLastBill: 3,
  billsCount: 1,
  sourceType: 'user',
  contact: {
    mobile: '9310611597',
    name: 'Vishnu',
    email: 'vishnu@ajirasoft.com'
  },
  name: '  ',
  lastBillDate: '2022-08-25T23:07:05Z',
  id: '9310611597',
  updatedVersion: 1662983627127,
  pwaDetails: {
    email: 'it1@crimsouneclub.com'
  },
  bill: {
    date: '2022-09-03T06:35:13Z',
    totalDiscountAmount: 3947.5,
    billType: 'Retail',
    isTierUpgraded: false,
    channel: 'POS',
    generatedBillId: 'B58_531_09_22_Mangla_(Kundli)_2022_09_03T12_05_13.887Z',
    earnedPoints: 197.38,
    burnedPoints: 0,
    paymentSplits: [
      {
        mode: 'EDC',
        name: 'CARD',
        excludeLoyaltyEarn: false,
        shortcode: 'CC',
        value: 3948
      },
      {
        mode: 'loyalty',
        excludeLoyaltyEarn: true,
        value: 0
      },
      {
        mode: 'Couponcode',
        excludeLoyaltyEarn: false,
        value: 0
      }
    ],
    currentTier: 'Silver',
    products: [
      {
        IGSTRate: 0,
        discountAmount: 0,
        section: 'CrimsouneClub',
        hsnSacCode: '61091000',
        IGSTAmt: 0,
        division: 'Mens',
        loyaltyDiscount: 0,
        total: 549.5,
        CESSRate: 0,
        SGSTAmt: 13.08,
        price: 1099,
        productDiscount: 549.5,
        department: 'Tshirt',
        CESSAmt: 0,
        CGSTAmt: 13.08,
        quantity: 1,
        articleName: 'MENS-CRIMSOUNECLUB-T-SHIRT',
        cname6: 'SS22',
        billDiscount: 0,
        netAmount: 549.5,
        cname1: 'TSH2351',
        mrp: 0,
        cname5: '  L/40',
        itemdesc1: 'CCOK-SS22-804',
        cname4: '2',
        cname3: 'H/S',
        itemdesc2: 'KNITTED',
        cname2: 'PRINT',
        itemdesc3: 'PEACH',
        itemdesc4: 'ROUND NECK',
        SGSTRate: 2.5,
        itemdesc5: '1.07M',
        offerDiscount: 549.5,
        itemdesc6: '95% COTTON 5% SPANDEX',
        CGSTRate: 2.5,
        name: 'MENS-CRIMSOUNECLUB-T-SHIRT',
        udfstring05: 'KNITTED',
        taxAmount: 26.16,
        udfstring02: 'REGULAR',
        skuCode: 'CC364517',
        udfstring01: '1N'
      },
      {
        IGSTRate: 0,
        discountAmount: 0,
        section: 'CrimsouneClub',
        hsnSacCode: '62052000',
        IGSTAmt: 0,
        division: 'Mens',
        loyaltyDiscount: 0,
        total: 949.5,
        CESSRate: 0,
        SGSTAmt: 22.61,
        price: 1899,
        productDiscount: 949.5,
        department: 'Shirt',
        CESSAmt: 0,
        CGSTAmt: 22.61,
        quantity: 1,
        articleName: 'MENS-CRIMSOUNECLUB-SHIRT',
        cname6: 'SS22',
        billDiscount: 0,
        netAmount: 949.5,
        cname1: 'JSH31181',
        mrp: 0,
        cname5: '  L/40',
        itemdesc1: 'SMIL05915',
        cname3: 'F/S',
        itemdesc2: 'DENIM',
        cname2: 'CHECK',
        itemdesc3: 'YELLOW',
        itemdesc4: 'REGULAR',
        SGSTRate: 2.5,
        itemdesc5: '1.07M',
        offerDiscount: 949.5,
        itemdesc6: '100% COTTON',
        CGSTRate: 2.5,
        name: 'MENS-CRIMSOUNECLUB-SHIRT',
        udfstring05: 'WOVEN',
        udfstring04: 'INDIGO',
        taxAmount: 45.22,
        udfstring03: 'CRIMSOUNE JEANS',
        udfstring02: 'REGULAR',
        skuCode: 'CC362148',
        udfstring01: '1N'
      },
      {
        IGSTRate: 0,
        discountAmount: 0,
        section: 'CrimsouneClub',
        hsnSacCode: '62052000',
        IGSTAmt: 0,
        division: 'Mens',
        loyaltyDiscount: 0,
        total: 799.5,
        CESSRate: 0,
        SGSTAmt: 19.04,
        price: 1599,
        productDiscount: 799.5,
        department: 'Shirt',
        CESSAmt: 0,
        CGSTAmt: 19.04,
        quantity: 1,
        articleName: 'MENS-CRIMSOUNECLUB-SHIRT',
        cname6: 'AW21',
        billDiscount: 0,
        netAmount: 799.5,
        cname1: 'MH2502',
        mrp: 0,
        cname5: '  L/40',
        itemdesc1: 'FAB26024',
        cname3: 'H/S',
        itemdesc2: 'COTTON',
        cname2: 'PRINT',
        itemdesc3: 'SKY',
        itemdesc4: 'REGULAR',
        SGSTRate: 2.5,
        itemdesc5: '1.07M',
        offerDiscount: 799.5,
        itemdesc6: '100% COTTON',
        CGSTRate: 2.5,
        name: 'MENS-CRIMSOUNECLUB-SHIRT',
        udfstring05: 'WOVEN',
        taxAmount: 38.08,
        udfstring03: 'REGULAR',
        udfstring02: 'REGULAR',
        skuCode: 'CC354569',
        udfstring01: '1N'
      },
      {
        IGSTRate: 0,
        discountAmount: 0,
        section: 'CrimsouneClub',
        hsnSacCode: '62052000',
        IGSTAmt: 0,
        division: 'Mens',
        loyaltyDiscount: 0,
        total: 899.5,
        CESSRate: 0,
        SGSTAmt: 21.42,
        price: 1799,
        productDiscount: 899.5,
        department: 'Shirt',
        CESSAmt: 0,
        CGSTAmt: 21.42,
        quantity: 1,
        articleName: 'MENS-CRIMSOUNECLUB-SHIRT',
        cname6: 'SS22',
        billDiscount: 0,
        netAmount: 899.5,
        cname1: 'MA9055',
        mrp: 0,
        cname5: '  L/40',
        itemdesc1: 'KT07511B',
        cname3: 'F/S',
        itemdesc2: 'COTTON',
        cname2: 'CHECK',
        itemdesc3: 'NAVY',
        itemdesc4: 'REGULAR',
        SGSTRate: 2.5,
        itemdesc5: '1.07M',
        offerDiscount: 899.5,
        itemdesc6: '100% COTTON',
        CGSTRate: 2.5,
        name: 'MENS-CRIMSOUNECLUB-SHIRT',
        udfstring05: 'WOVEN',
        taxAmount: 42.84,
        udfstring03: 'REGULAR',
        udfstring02: 'REGULAR',
        skuCode: 'CC370637',
        udfstring01: '1N'
      },
      {
        IGSTRate: 0,
        discountAmount: 0,
        section: 'CrimsouneClub',
        hsnSacCode: '62052000',
        IGSTAmt: 0,
        division: 'Mens',
        loyaltyDiscount: 0,
        total: 749.5,
        CESSRate: 0,
        SGSTAmt: 17.85,
        price: 1499,
        productDiscount: 749.5,
        department: 'Shirt',
        CESSAmt: 0,
        CGSTAmt: 17.85,
        quantity: 1,
        articleName: 'MENS-CRIMSOUNECLUB-SHIRT',
        cname6: 'SS20',
        billDiscount: 0,
        netAmount: 749.5,
        cname1: 'MH1850',
        mrp: 0,
        cname5: '  L/40',
        cname3: 'H/S',
        itemdesc2: 'URBAN CLUB',
        cname2: 'CHECK',
        itemdesc3: 'LEMON',
        SGSTRate: 2.5,
        itemdesc5: '1.07M',
        offerDiscount: 749.5,
        CGSTRate: 2.5,
        name: 'MENS-CRIMSOUNECLUB-SHIRT',
        taxAmount: 35.7,
        skuCode: 'CC159356'
      },
      {
        IGSTRate: 0,
        discountAmount: 0,
        section: 'Packing Accesories',
        hsnSacCode: '48191010',
        IGSTAmt: 0,
        division: 'Miscellaneous',
        loyaltyDiscount: 0,
        total: 0.05,
        CESSRate: 0,
        SGSTAmt: 0,
        price: 0.01,
        productDiscount: 0,
        department: 'Carry Bags',
        CESSAmt: 0,
        CGSTAmt: 0,
        quantity: 5,
        articleName: 'MISCELLANEOUS-PACKING ACCESORIES-CARRY BAGS',
        billDiscount: 0,
        netAmount: 0.05,
        cname1: 'CBAG001',
        mrp: 0.05,
        cname2: 'CARRRYBAG',
        SGSTRate: 9,
        offerDiscount: 0,
        CGSTRate: 9,
        name: 'MISCELLANEOUS-PACKING ACCESORIES-CARRY BAGS',
        taxAmount: 0,
        skuCode: 'A10235'
      }
    ],
    totalAmount: 3947.55,
    invoiceType: 'IN',
    newTier: 'Silver',
    storeName: 'Mangla (Kundli)',
    billNo: 'B58/531/09/22',
    storeAddress:
      'KILLA NO. 32/18/1/1/4-0, 19/1/1/3-9, G.T.ROAD HIGHWAY, (NR. HASIJA, HOSPITAL), TEHSIL, Kundli, Haryana, 131028',
    gstin: '06AAFCM0070J1ZO',
    storeMobile: null
  }
};

export default {
  stepperFields,
  defaultSections,
  stepperTypeComponentMap,
  PREVIEW_INDEX,
  standardBill,
  INVOICE_CONFIG_TYPE
};
