/* eslint-disable @typescript-eslint/explicit-function-return-type */
import config from '../config/index';
import queryString from 'query-string';
import _ from 'lodash';
import { ObjectifyService } from '../models/objectify.service';
import { Offer } from '../models/offer/Offer.model';

const objectifyService = new ObjectifyService();

function getOfferMaster(filter) {
  const url = new URL(`${config.ruleServerUrl}/users/offers/filter`);
  const {
    businessUnitId,
    page,
    limit,
    searchTerm,
    tagIds,
    status,
    sortBy,
    order
  } = filter;
  const stringQueryParams = {
    buId: businessUnitId,
    page: page,
    limit: limit,
    q: searchTerm,
    status: status,
    sortBy: sortBy,
    order: order
  };
  const searchParams = new URLSearchParams(stringQueryParams);
  for (const tagId of tagIds) {
    searchParams.append('tagIds[]', tagId);
  }
  url.search = searchParams.toString();
  return fetch(url, {
    credentials: 'include',
    method: 'GET'
  }).then(r => r.json());
}

function saveOffer(offerDetails) {
  return fetch(`${config.ruleServerUrl}/users/offers/saveOffer`, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify({
      ...offerDetails
    }),
    headers: {
      'Content-type': 'application/json'
    }
  }).then(r => r.json());
}

function retrieveOffers() {
  return fetch(`${config.ruleServerUrl}/users/offers`, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-type': 'application/json'
    }
  })
    .then(r => r.json())
    .catch(err => {
      console.log(err);
    });
}
function getOffers() {
  return fetch(`${config.ruleServerUrl}/users/offers/offerInfo`, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-type': 'application/json'
    }
  }).then(r => r.json());
}

function getOffer(offerCode) {
  return fetch(`${config.ruleServerUrl}/users/offers/filter/${offerCode}`, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(r => {
      if (r.status === 404) {
        throw Error('NOT_FOUND');
      }
      return r;
    })
    .then(r => r.json())
    .then(jsonData => ({ ...jsonData, className: 'Offer' }))
    .then(offer => objectifyService.objectify(offer));
}

function getCurrentOffers(store, customerMobile) {
  return fetch(
    `${config.ruleServerUrl}/users/offers-raw/store/${store}/customer-mobile/${customerMobile}`,
    {
      credentials: 'include',
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    }
  )
    .then(r => r.json())
    .then(offers =>
      _.orderBy(
        _.map(offers, o => objectifyService.objectify(o)),
        ['endDate', 'asc']
      )
    );
}

// todo: deprecated, remove this and use one with store - Ragavan
function getCurrentOffer(offerCode) {
  const qs = queryString.stringify(
    {
      offerIds: [offerCode]
    },
    { arrayFormat: 'bracket' }
  );
  return fetch(`${config.ruleServerUrl}/users/offers-raw/filtered?${qs}`, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-type': 'application/json'
    }
  })
    .then(r => r.json())
    .then(r => {
      if (_.some(r)) {
        const offer = _.head(r);
        return _.extend(new Offer(), offer, {
          offerDefinition: objectifyService.objectify(offer.offerDefinition)
        });
      } else {
        return Promise.reject(`no offer for code ${offerCode}`);
      }
    });
}

function getOfferExists(offerCode) {
  return fetch(`${config.ruleServerUrl}/users/offers/filter/${offerCode}`, {
    credentials: 'include',
    method: 'HEAD',
    headers: {
      'Content-type': 'application/json'
    }
  }).then(r => r.status !== 404);
}

function changeDate(tenantBrandBusinessId, offerCodes, startDate, endDate) {
  return fetch(`${config.ruleServerUrl}/users/offers/changeDate/`, {
    credentials: 'include',
    method: 'POST',
    headers: {
      'Content-type': 'application/json'
    },
    body: JSON.stringify({
      tenantBrandBusinessId,
      offerCodes,
      startDate,
      endDate
    })
  }).then(r => r.json());
}

async function getOfferSearchList(
  tenantBrandBusinessId,
  mobile,
  offerCode,
  from = 0,
  size = 10,
  fromDate,
  toDate,
  status = ''
) {
  return await fetch(
    `${config.ruleServerUrl}/offers/businessUnit/${tenantBrandBusinessId}/all?mobile=${mobile}&offerCode=${offerCode}&from=${from}&size=${size}&fromDate=${fromDate}&toDate=${toDate}&status=${status}`,
    {
      credentials: 'include',
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    }
  ).then(r => r.json());
}

async function exportOfferSearchListAsCSV(
  tenantBrandBusinessId,
  mobile,
  offerCode,
  fromDate,
  toDate,
  status = ''
) {
  return await fetch(
    `${config.ruleServerUrl}/offers/businessUnit/${tenantBrandBusinessId}/all/exportCSV?mobile=${mobile}&offerCode=${offerCode}&fromDate=${fromDate}&toDate=${toDate}&status=${status}`,
    {
      credentials: 'include',
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    }
  ).then(r => r.blob());
}

async function getOfferSearchCount(
  tenantBrandBusinessId,
  mobile,
  offerCode,
  fromDate,
  toDate,
  status = ''
) {
  return await fetch(
    `${config.ruleServerUrl}/offers/businessUnit/${tenantBrandBusinessId}/all/count?mobile=${mobile}&offerCode=${offerCode}&fromDate=${fromDate}&toDate=${toDate}&status=${status}`,
    {
      credentials: 'include',
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    }
  ).then(r => r.json());
}

async function getOfferDefaultUrls(bu) {
  const response = await fetch(
    `${config.ruleServerUrl}/users/offers-raw/${bu}/defaults`,
    {
      credentials: 'include',
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    }
  );

  return response.json();
}

export default {
  getOfferExists,
  saveOffer,
  getOffers,
  getOffer,
  getCurrentOffers,
  getCurrentOffer,
  getOfferMaster,
  changeDate,
  retrieveOffers,
  getOfferSearchList,
  getOfferSearchCount,
  exportOfferSearchListAsCSV,
  getOfferDefaultUrls
};
