import React, { FC, useContext, useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import { BusinessUnitContext } from '../../OfferDefinition/BusinessUnitContext';
import cancelIcon from '../../Assets/images/icons/cancel_icon.svg';
import productMasterService from '../../../services/productMaster.service';
import CategoryTree from './CategoryTree';
import SelectedProducts from './SelectedProducts';

interface ProductSelectionProps {
  currentTileData: any;
  productHierarchyTreeChecked: any;
  setProductHierarchyTreeChecked: any;
  productHierarchyTreeExpanded: any;
  setProductHierarchyTreeExpanded: any;
  closeModal: any;
  selectedMatchingProducts: any;
  setSelectedMatchingProducts: any;
  productsFilteredByFirstHierarchy: any;
  setProductsFilteredByFirstHierarchy: any;
}

export const ProductSelection: FC<ProductSelectionProps> = ({
  currentTileData,
  productHierarchyTreeChecked,
  setProductHierarchyTreeChecked,
  productHierarchyTreeExpanded,
  setProductHierarchyTreeExpanded,
  closeModal,
  selectedMatchingProducts,
  setSelectedMatchingProducts,
  productsFilteredByFirstHierarchy,
  setProductsFilteredByFirstHierarchy
}) => {
  const [allProducts, setAllProducts] = useState([]);
  const businessUnitContext = useContext(BusinessUnitContext);
  const PRODUCT_SELECTION_DONE = 'done';
  const PRODUCT_SELECTION_CLOSE = 'close';
  useEffect(() => {
    productMasterService
      .listProducts(businessUnitContext.business)
      .then((allProducts: any) => {
        setAllProducts(allProducts);
      });
  }, [businessUnitContext]);

  const [selectedProductsByPath, setSelectedProductsByPath] = useState<any[]>(
    selectedMatchingProducts
  );

  return (
    <div className={'choose-products-hierarchy-modal-container text-center'}>
      <div className={'popup-header-section'}>
        <h2 className={'mail-header'}>{'Add your collections'}</h2>
        <img
          className={'popup-close-section'}
          src={cancelIcon}
          alt={'close'}
          onClick={() => closeModal(PRODUCT_SELECTION_CLOSE)}
        />
      </div>
      <div className={'product-hierarchy-selection-container'}>
        <CategoryTree
          allProducts={allProducts}
          productHierarchyTreeChecked={productHierarchyTreeChecked}
          setProductHierarchyTreeChecked={setProductHierarchyTreeChecked}
          productHierarchyTreeExpanded={productHierarchyTreeExpanded}
          setProductHierarchyTreeExpanded={setProductHierarchyTreeExpanded}
          setSelectedMatchingProducts={setSelectedMatchingProducts}
          productsFilteredByFirstHierarchy={productsFilteredByFirstHierarchy}
          setProductsFilteredByFirstHierarchy={
            setProductsFilteredByFirstHierarchy
          }
          setSelectedProductsByPath={setSelectedProductsByPath}
        />
        <SelectedProducts
          allProducts={allProducts}
          productHierarchyTreeChecked={productHierarchyTreeChecked}
          setSelectedMatchingProducts={setSelectedMatchingProducts}
          setSelectedProductsByPath={setSelectedProductsByPath}
          selectedProductsByPath={selectedProductsByPath}
          selectedMatchingProducts={selectedMatchingProducts}
        />
      </div>
      <div className={'text-center m-5'}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => closeModal(PRODUCT_SELECTION_DONE)}
          style={{ padding: '.5rem 3rem' }}
        >
          {'done'}
        </Button>
      </div>
    </div>
  );
};
export default ProductSelection;
