/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState, useEffect } from 'react';
import '../LoyaltyConfig/index.scss';
import { ParentLink } from '../../utils/ParentLink';
import { useFormik } from 'formik';
import { TextFieldComponent } from '../../reusable/TextFieldComponent';
import ButtonField from '../../reusable/ButtonField';
import {
  referralInitialState,
  formGroupRedemption
} from '../../constants/referralPoints';
import { referralToSchema } from '../../validation/referralConfiguration';
import OTPImage from '../Assets/referralconfiguration.svg';
import { referralConfiguration } from '../../constants/loyaltyConfig';
import Select from '@material-ui/core/Select';
import { Business } from '../../models/User.model';
import useCurrentUser from '../../hooks/useCurrentUser';
import MenuItem from '@material-ui/core/MenuItem';
import _ from 'lodash';
import ConfigService from '../../services/referral.service';
import { useDispatch } from 'react-redux';
import { toastMessageAction } from '../../store/action';

const ReferralConfiguration = () => {
  const {
    referredUsersPoints,
    referrerPoints,
    expireInDays,
    submitButton,
    cancelButton
  } = formGroupRedemption;
  const [business, setBusiness] = useState(new Business());
  const dispatch = useDispatch();
  const user = useCurrentUser();
  //handle submit
  const onSubmit = data => {
    Object.keys(data).forEach(key => {
      if (data[key] === '') {
        delete data[key];
      }
    });
    ConfigService.referralFriendConfig(business?.id, data).then(() => {
      dispatch(
        toastMessageAction({
          severity: 'success',
          message: `Referral points add successfully !`,
          messageStatus: true
        })
      );
    });
  };

  //to handle submit formik
  const formik = useFormik({
    initialValues: referralInitialState,
    validationSchema: referralToSchema,
    onSubmit
  });
  const {
    values,
    errors,
    handleChange,
    touched,
    resetForm,
    setValues
  } = formik;

  const onSelectFieldChange = event => {
    const selectedBusiness = _.find(user.getBusinesses(), {
      id: event.target.value
    });
    setBusiness(selectedBusiness);
  };

  useEffect(() => {
    setBusiness(
      _(user.getBusinesses())
        .orderBy(['id'])
        .first() || new Business()
    );
    if (business.id !== 0)
      setValues(business?.referralLoyaltyConfig || referralInitialState);
  }, [user, business.id]);

  return (
    <>
      <div className={'retenseeContainer'}>
        {referralConfiguration?.map((item, index) => {
          return (
            <ParentLink
              to={item.path}
              className={item.className ? item.className : 'heading'}
              key={index}
            >
              {item.title}
            </ParentLink>
          );
        })}
      </div>
      <div className={'adHocPointsContainer'}>
        <div className={'businessUnitContainer'}>
          <div className="unitTitle">Business unit</div>
          <Select
            value={business.id}
            key={business.id}
            label={'Business Uni'}
            onChange={onSelectFieldChange}
            style={{ width: '180px' }}
          >
            {_.map(user.getBusinesses(), b => (
              <MenuItem value={b.id} key={b.id}>
                {b.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <img
          src={OTPImage}
          alt="referral-configuration"
          className="redemptionImage"
        />
        <h4 className="adHocPointsTitle">Set your Referral Configuration</h4>
        <form onSubmit={formik.handleSubmit} className="formSubmitation">
          <TextFieldComponent
            label={referredUsersPoints?.label}
            variant={referredUsersPoints?.variant}
            onChange={handleChange}
            type={referredUsersPoints?.type}
            name={referredUsersPoints?.name}
            value={values.referredUsersPoints}
            error={touched.referredUsersPoints && errors.referredUsersPoints}
            className={referredUsersPoints?.className}
            placeholder={referredUsersPoints?.placeholder}
          />
          <TextFieldComponent
            label={referrerPoints?.label}
            variant={referrerPoints?.variant}
            onChange={handleChange}
            type={referrerPoints?.type}
            name={referrerPoints?.name}
            value={values.referrerPoints}
            error={touched.referrerPoints && errors.referrerPoints}
            className={referrerPoints?.className}
            placeholder={referrerPoints?.placeholder}
          />
          <TextFieldComponent
            label={expireInDays?.label}
            variant={expireInDays?.variant}
            onChange={handleChange}
            type={expireInDays?.type}
            name={expireInDays?.name}
            value={values.expireInDays}
            error={touched.expireInDays && errors.expireInDays}
            className={expireInDays?.className}
            placeholder={expireInDays?.placeholder}
          />
          <div className={'submitButtonContainer'}>
            <ButtonField
              variant={cancelButton.variant}
              label={cancelButton.label}
              type={cancelButton.type}
              onClick={() => resetForm()}
              className={cancelButton.className}
            />

            <ButtonField
              variant={submitButton.variant}
              label={submitButton.label}
              type={submitButton.type}
              className={submitButton.className}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default ReferralConfiguration;
