/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from 'react';
import StoreHealthList from '../List';
import { Typography, TablePagination } from '@material-ui/core';
import storeHealthStyle from '../index.module.scss';
import { viewStoreHealthReport } from '../../../../services/TenantReports/StoreHealthDashboard.service';
import { StoreHealthViewData } from '../../../../constants/StoreHealth';

const StoreHealthViewReport = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const columns = React.useMemo(() => StoreHealthViewData(), []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  useEffect(() => {
    viewStoreHealthReport(page, rowsPerPage).then(res => {
      setData(res);
    });
  }, [page, rowsPerPage]);
  console.log(page, 'page');

  return (
    <div>
      <Typography variant="h5" className={storeHealthStyle.title}>
        {'View Report'}
      </Typography>

      <StoreHealthList
        columns={columns}
        columnsHideStautus={false}
        data={data || []}
        offset={rowsPerPage}
        rowsPerPage={rowsPerPage}
      />
      <div className={storeHealthStyle.tablePaginationStyle}>
        <TablePagination
          count={-1}
          rowsPerPageOptions={[25, 50, 100]}
          page={page}
          rowsPerPage={rowsPerPage}
          colSpan={-1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
};

export default StoreHealthViewReport;
