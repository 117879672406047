/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { isEmpty, split, trim } from 'lodash';
import moment from 'moment';

function formatDate(val: string) {
  if (isEmpty(val)) {
    return '-';
  }

  const formattedDate = moment(val)
    .utcOffset('+05:30')
    .format('MMMM Do YYYY, h:mm:ss a');
  
  return formattedDate === 'Invalid date'
    ? val.split(':')[0] || val
    : formattedDate;
}

function formatCurrency(val: string) {
  val = val || '0';
  return '₹ ' + val;
}

function formatImage(val: string, getter: string) {
  const [width = '100', height = '40'] = split(getter, 'x', 2);
  return `<img src='${val}' width='${width}px' height='${height}px' />`;
}

function formatLink(val: string, getter: string) {
  return val.link(getter);
}

function formatHtmlContent(content: string) {
  // parse the content if
  return content;
}

function formatDefault(val: string) {
  let isValEmpty = isEmpty(trim(val));
  if (isValEmpty) {
    return '-';
  }
  const isInValidNum = isNaN(val as any);
  if (isInValidNum) {
    return val;
  }
  return parseInt(val).toString();
}

function formatComputedFields(val: string) {
  return val;
}

export default {
  formatDate,
  formatCurrency,
  formatImage,
  formatLink,
  formatHtmlContent,
  formatDefault,
  formatComputedFields
};
