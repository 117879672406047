export default interface Campaign {
  campaignId: string;
  campaignName: string;
  startDate: string;
  endDate: string;
  type: CampaignType;
  status: 'Running' | 'Stopped';
}

export enum CampaignType {
  LIVE = 'rules',
  BATCH = 'batch_rules2'
}
