import React, { FC, useContext } from 'react';
import MyCart from '../MyCart/MyCart';
import { FlipbookContext } from '../Hooks/FlipbookContext';
import isEmpty from 'lodash/isEmpty';
import { CircularProgress } from '@material-ui/core';
import {FlipbookCartContext} from "../Hooks/FlipbookCartContext";

interface DefaultInvalidRepsonse {
  data: string;
  status: 'failed';
  code: 2;
  message: string;
}

export interface Cart {
  sku: string;
  quantity: number;
  storeCode: string;
  isAvailable: boolean;
  availableStock: number;
}
interface CartInvalidResponse {
  status: 'failed';
  code: 1;
  message: string;
  data: Cart[] | string;
}

interface InvalidCartProps {
  response: DefaultInvalidRepsonse | CartInvalidResponse;
  storeDetails: any;
  currentStore: any;
  invalidCartCta: () => void;
}

const InvalidCart: FC<InvalidCartProps> = ({
  response,
  currentStore,
  storeDetails,
  invalidCartCta
}) => {
  const flipbookContext = useContext(FlipbookContext);
  const flipbookCartContext = useContext(FlipbookCartContext);
  const setIsInvalidCart = flipbookCartContext.setIsInvalidCart;

  const flipbookIdOrToken = () => {
    if (isEmpty(flipbookContext)) {
      return undefined;
    }
    if (isEmpty(flipbookContext?.flipbookContext?.flipbookToken)) {
      return flipbookContext?.flipbookContext?.id;
    }
    return flipbookContext?.flipbookContext?.flipbookToken;
  };
  function customMessageResponse() {
    return (
      <div className={'flipsell-custom-invalid-response'}>
        <div>{response.data}</div>
      </div>
    );
  }
  function cartOutOfStockResponse() {
    const token = flipbookIdOrToken();
    const cart: any = response.data;
    setIsInvalidCart(true);
    return (
      <div className={'flipsell-cart-invalid-response h-100'}>
        <div className={'h-100'}>
          <MyCart
            storeDetails={storeDetails}
            flipbookIdOrToken={token}
            currentStore={currentStore}
            cartType={'InvalidCart'}
            cartResponse={cart}
            invalidCartCTA={invalidCartCta}
          />
        </div>
      </div>
    );
  }

  function getInvalidComponentResponse() {
    switch (response.code) {
      case 1:
        return cartOutOfStockResponse();
      case 2:
        return customMessageResponse();
      default:
        return customMessageResponse();
    }
  }
  function loaderComponent() {
    return (
      <div style={{ color: '#F2755F' }}>
        <CircularProgress color="inherit" />
      </div>
    );
  }
  return isEmpty(response) ? (
    loaderComponent()
  ) : (
    <div className={'h-100'}>{getInvalidComponentResponse()}</div>
  );
};

export default InvalidCart;
