import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';
import { map } from 'lodash';
import React, { useContext } from 'react';
import { InvoiceContext } from '../../context/InvoiceContext';
import ButtonField from '../ButtonField/ButtonField';
import classes from './Header.module.scss';

const Header: React.FC<{
  switchPreview: () => void;
}> = ({ switchPreview }) => {
  const {
    saveConfig,
    handleBusinessChange,
    getBusinesses,
    getSelectedBusiness
  } = useContext(InvoiceContext);

  return (
    <div className={classes.container}>
      <Typography variant="h6">E-BILL Template Configuration</Typography>

      <div className={classes.btns}>
        <FormControl className={classes.formControl} style={{ width: '200px' }}>
          <InputLabel id="businessLabel">Business unit</InputLabel>
          <Select
            defaultValue=""
            labelId="businessLabel"
            value={getSelectedBusiness()?.id}
            onChange={handleBusinessChange}
          >
            {map(getBusinesses(), b => (
              <MenuItem value={b.id} key={b.id}>
                {b.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <ButtonField
          label="Preview"
          variant="outlined"
          color="primary"
          onClick={switchPreview}
        />
        <ButtonField label="Save" color="primary" onClick={saveConfig} />
      </div>
    </div>
  );
};
export default Header;
