import { Box, Container, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { toast } from 'react-toastify';

import { generateReport } from '../../../../../services/Campaign/campaign-report.service';
import useMutation from '../../../../../shared/hooks/useMutation';
import emptyListImg from '../../../../Assets/campaign/empty_list.svg';
import LoadingButton from '../../../shared/components/LoadingButton/LoadingButton';
import styles from './CampaignReportEmpty.module.scss';

interface CampaignReportEmptyProps {
  campaignId: string;
  onRefetch: () => void;
}

const CampaignReportEmpty: FC<CampaignReportEmptyProps> = ({
  campaignId,
  onRefetch
}) => {
  const { mutate: onGenerateReport, isLoading: isGenerating } = useMutation(
    generateReport.bind(null, campaignId)
  );

  const handleGenerateReport = (): void => {
    onGenerateReport()
      .then(() => {
        onRefetch();
        toast.success('Your report generation is started successfully.');
      })
      .catch(() => toast.error('Something went wrong.'));
  };

  return (
    <Container maxWidth="md" id={styles.campaignReportEmpty}>
      <Box>
        <img className={styles.img} src={emptyListImg} alt="" />
      </Box>
      <Typography variant="h6">
        No reports scheduled currently. Please do schedule a report to view the
        report status.
      </Typography>
      <LoadingButton
        variant="contained"
        color="primary"
        size="large"
        loading={isGenerating}
        disableElevation
        onClick={handleGenerateReport}
      >
        Generate Report
      </LoadingButton>
    </Container>
  );
};
export default CampaignReportEmpty;
