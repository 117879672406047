/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState, useRef, useEffect } from 'react';
import pwaConfigStyles from './PWAConfig.module.css';
import PWABanner from '../Assets/images/Pwa_Config.svg';
import Button from '@material-ui/core/Button';
import { AddCircle, Close, Edit, FileCopy } from '@material-ui/icons';
import CustomMenuForm from './CustomMenuForm/CustomMenuForm';

import {
  getPwaConfig,
  getvalues,
  savePwaConfig,
  saveFile
} from '../../services/pwaConfig.service';
import _ from 'lodash';
import {
  Card,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  Tooltip,
  withStyles
} from '@material-ui/core';
import { styled } from '@material-ui/styles';
import { Business } from '../../models/User.model';
import useCurrentUser from '../../hooks/useCurrentUser';
import transformToFormData from './transformFormData';
import config from '../../config/index';
import { ReactComponent as Save } from './save.svg';
import { getLoyaltySignUpValues } from '../../services/pwaLoyaltySignUp.service';
import MenuCard from '../MenuCard/MenuCard';

export const LoginSwitch = styled(props => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(() => ({
  width: 50,
  height: 25,
  padding: 0,
  marginRight: '12%',
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(25px)',
      color: 'white',
      '& + .MuiSwitch-track': {
        backgroundColor: '#F2755F',
        opacity: 1
      },
      '& .MuiSwitch-thumb': {
        width: 22,
        height: 22
      }
    }
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#E9E9EA',
    opacity: 1
  }
}));

export const SavedIcon = props => (
  <svg
    width={28}
    height={28}
    fill="none"
    style={{ margin: 12 }}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m4.813 15.313 6.125 6.124 12.25-13.125"
      stroke={props.color}
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ImageUpload = ({
  label,
  setImage,
  image,
  pageWiseView,
  isEdit,
  size
}) => {
  const hiddenFileInput = useRef(null);
  const handleClick = () => {
    hiddenFileInput.current.click();
  };
  return (
    <div className={pageWiseView === false ? pwaConfigStyles.uploadImg : ''}>
      <div
        style={
          pageWiseView !== false ? { marginBottom: 15 } : { marginBottom: 0 }
        }
      >
        {label}
      </div>
      <div>
        {image ? (
          <div>
            <span style={{ color: 'lightgray' }}>
              {image?.name ? image.name.slice(0, 8) : image.slice(0, 15)}...
            </span>
            {!isEdit && (
              <IconButton
                onClick={() => {
                  setImage();
                }}
              >
                <Close style={{ color: 'black' }} />
              </IconButton>
            )}
            {isEdit && <SavedIcon color="#0D921B" />}
          </div>
        ) : (
          <Button
            style={pageWiseView !== false ? { width: '80%' } : {}}
            color="primary"
            variant="contained"
            onClick={handleClick}
          >
            Upload
          </Button>
        )}
      </div>
      <input
        className={pwaConfigStyles.imageInput}
        type="file"
        accept="image/png, image.jpg, image/jpeg"
        ref={hiddenFileInput}
        onChange={e => {
          const imgSize = e.target.files[0].size <= 300000;
          if (imgSize && size) {
            setImage(e.target.files[0]);
          }
          if (size && !imgSize) {
            alert('Please Upload Image less than 300 kb ');
          }
          if (!size) {
            setImage(e.target.files[0]);
          }
        }}
      />
    </div>
  );
};

const Color = ({ label, setColor, setStyle, text, value, isEdit }) => {
  return (
    <div className={pwaConfigStyles.colorSelection}>
      <span className={pwaConfigStyles.label}>{label}</span>
      {text ? (
        <input
          type="color"
          value={value}
          disabled={isEdit && true}
          onChange={e => setColor(e.target.value)}
          style={setStyle}
        />
      ) : (
        <input
          type="color"
          value={value}
          onChange={e => setColor(e.target.value)}
          disabled={isEdit && true}
          style={setStyle}
        />
      )}
    </div>
  );
};

function PWAConfig() {
  const [pwaConfig, setPwaConfig] = useState();
  const [header, setHeader] = useState('');
  const [footer, setFooter] = useState('');
  const [logo, setLogo] = useState();
  const [splashScreen, setSplashScreen] = useState();
  const [thankyouScreen, setThankyouScreen] = useState();
  const [offerHeader, setOfferHeader] = useState();
  const [offerFooter, setOfferFooter] = useState();
  const [loyaltyHeader, setLoyaltyHeader] = useState();
  const [loyaltyFooter, setLoyaltyFooter] = useState();
  const [profileHeader, setProfileHeader] = useState();
  const [profileFooter, setProfileFooter] = useState();
  const [menu, setMenu] = useState({});
  const [theme, setTheme] = useState('#5e72e4');
  const [sideNavText, setSideNavText] = useState('#ffffff');
  const [sidenavTheme, setSidenavTheme] = useState('#5e72e4');
  const [fontName, setfontName] = useState('');
  const [initialPagePwa, setInitialPagePwa] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const [tooltip, setTooltip] = useState(false);
  const [isEdit, setIsEdit] = useState(true);
  const [pageImage, setPageImage] = useState(false);
  const [navmenu, setNavMenu] = useState([]);
  const [font, setFont] = useState([]);
  const [show, setShow] = useState(false);
  const [viewUrl, setViewUrl] = useState(false);
  const [business, setBusiness] = useState(null);
  const [customHtmlMenu, setCustomHtmlMenu] = useState([]);
  const [isSelected, setIsSelected] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [editMenuName, setEditMenuName] = useState('');
  const [editHtmlName, setEditHtmlName] = useState('');
  const [defaultMenuName, setDefaultMenuName] = useState('');
  const [editOpen, setEditOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [html, setHtml] = useState('');
  const [menuName, setMenuName] = useState('');
  const [permissions, setPermissions] = useState({});

  const user = useCurrentUser();
  const link = `${config.pwaUrl}/register/${user?.tenant?.esKey}/${business?.name}`;

  const CustomRadio = withStyles({
    root: {
      '&$checked': {
        color: isEdit ? 'default' : '#F26E5F'
      }
    },
    checked: {}
  })(props => <Radio color="default" {...props} />);

  const FeatureSelect = ({
    label,
    fontName,
    setfontName,
    fontNames,
    isEdit
  }) => {
    return (
      <div>
        <span>
          <b>{label}</b>
        </span>
        <RadioGroup
          row
          style={{ marginTop: 10 }}
          name="fontName"
          value={fontName}
          onChange={event => {
            setfontName(event.target.value);
          }}
        >
          {_.map(fontNames, feature => {
            return (
              <FormControlLabel
                key={feature}
                value={feature}
                disabled={isEdit && true}
                control={<CustomRadio />}
                label={feature}
                style={{ marginRight: 25 }}
              />
            );
          })}
        </RadioGroup>
      </div>
    );
  };

  useEffect(() => {
    setBusiness(
      _.chain(user.getBusinesses())
        .orderBy(['id'])
        .first()
        .value() || new Business()
    );
    getvalues().then(response => {
      setFont(response.fontFamily);
      setNavMenu(response.menu);
    });
  }, [user]);

  const doesPermissionEnabled = (permission, permissionMap) =>
    _.includes(_.get(permissionMap, 'enabled', []), permission);

  const getPwaPermissionsMap = ({ permissions = {} }) => {
    const allPermissions = _.flattenDeep(_.concat(Object.values(permissions)));
    return allPermissions.reduce((acc, permission) => {
      acc[permission] = {
        name: permission,
        enabled: doesPermissionEnabled(permission, permissions)
      };
      return acc;
    }, {});
  };

  useEffect(() => {
    if (business?.id) {
      getPwaConfig(business?.id).then(res => {
        setPwaConfig(res);
        setPermissions(() => getPwaPermissionsMap(res));
        setMenu(res.menu);
        setCustomHtmlMenu(
          _.filter(_.values(res.menu), item => {
            return item.module === 'HTML';
          })
        );
        setHeader(res.modules.HOME?.headerImage);
        setFooter(res.modules.HOME?.footerImage);
        setOfferHeader(res.modules.OFFER?.headerImage);
        setOfferFooter(res.modules.OFFER?.footerImage);
        setLoyaltyHeader(res.modules.LOYALTY?.headerImage);
        setLoyaltyFooter(res.modules.LOYALTY?.footerImage);
        setProfileHeader(res.modules.PROFILE?.headerImage);
        setProfileFooter(res.modules.PROFILE?.footerImage);
        setLogo(res.logo);
        setSplashScreen(res.splashScreen?.background);
        setThankyouScreen(res.thankYouScreen?.background);
        setInitialPagePwa(res.initialModule);
        setTheme(res.palette?.primary);
        setSidenavTheme(res.palette.sideNav?.background);
        setSideNavText(res.palette.sideNav?.textColor);
        setfontName(res.typography?.fontFamily);
      });
      getLoyaltySignUpValues(business?.id).then(res => {
        if (res.length > 0) setViewUrl(true);
      });
    }
    // eslint-disable-next-line
  }, [user, business]);

  useEffect(() => {
    setIsSelected(isSelected =>
      navmenu.reduce((acc, val) => {
        acc[val.name] = Object.values(menu).some(el => el.name === val.name);
        return acc;
      }, isSelected)
    );
  }, [navmenu, menu]);

  useEffect(() => {
    setIsSelected(isSelected =>
      customHtmlMenu.reduce((acc, val) => {
        acc[val.name] = isSelected[val.name] ?? true;
        return acc;
      }, isSelected)
    );
  }, [customHtmlMenu]);

  const convertToMenuName = name => {
    return name
      .trim()
      .toUpperCase()
      .replaceAll(' ', '_');
  };

  const handleSignUp = () => {
    return (window.top.location.href =
      config.dashboardUrl + '/pwa-config/loyalty-signUp');
  };

  const copyLink = () => {
    const text = document.getElementById('url').innerText;
    const elem = document.createElement('textarea');
    document.body.appendChild(elem);
    elem.value = text;
    elem.select();
    document.execCommand('copy');
    const copiedValue = document.body.removeChild(elem);
    setTooltip(true);
    setTimeout(() => {
      setTooltip(false);
    }, 3000);
    return copiedValue;
  };

  const handleSwitch = e => {
    e.target.name = e.target.checked;
    setPageImage(e.target.checked);
  };

  const getKeyByValue = value => {
    return Object.keys(menu).find(key => menu[key].name === value);
  };

  const addToMenu = (menuArray, value, temp) => {
    menuArray.map(val => {
      if (val.name === value) {
        temp = {
          ...temp,
          [convertToMenuName(val.name)]: val
        };
        setMenu({ ...temp });
      }
    });
  };

  const deleteFromMenu = (menuArray, value) => {
    menuArray.map(val => {
      if (val.name === value) {
        setMenu(current => {
          const copy = { ...current };
          delete copy[getKeyByValue(val.name)];
          return copy;
        });
      }
    });
  };

  const handleChecked = (menuArray, value, checked) => {
    const temp = { ...menu };
    if (checked) {
      addToMenu(menuArray, value, temp);
    } else {
      deleteFromMenu(menuArray, value);
    }
  };

  const canSave = () => {
    return header && footer && !_.isEmpty(menu);
  };

  const getAllPermissions = permissionMap =>
    _.flattenDeep(_.concat(Object.values(permissionMap))) || [];

  const getUpdatedPermissions = () => {
    const allPermissions = getAllPermissions(_.get(pwaConfig, 'permissions'));
    return allPermissions.reduce(
      (permissionMap, permission) => {
        if (permissions[permission]?.enabled)
          permissionMap.enabled.push(permission);
        else permissionMap.disabled.push(permission);
        return permissionMap;
      },
      {
        enabled: [],
        disabled: []
      }
    );
  };

  const onSave = async () => {
    setIsSaving(true);
    const data = {
      menu,
      modules: {
        HOME: {
          headerImage: header,
          footerImage: footer
        },
        OFFER: {
          headerImage: offerHeader,
          footerImage: offerFooter
        },
        PROFILE: {
          headerImage: profileHeader,
          footerImage: profileFooter
        },
        LOYALTY: {
          headerImage: loyaltyHeader,
          footerImage: loyaltyFooter
        }
      },
      palette: {
        primary: theme,
        sideNav: {
          textColor: sideNavText,
          background: sidenavTheme
        }
      },
      initialModule: initialPagePwa,
      typography: {
        fontFamily: fontName
      },
      splashScreen: {
        background: splashScreen
      },
      thankYouScreen: {
        background: thankyouScreen
      },
      logo: logo,
      permissions: getUpdatedPermissions()
    };

    const payload = transformToFormData(data);
    await savePwaConfig(payload, business.id);
    setIsSaving(false);
    setShow(true);
    setIsEdit(true);
  };

  const handleBusinessChange = event => {
    const selectedBusiness = _.find(user.getBusinesses(), {
      id: event.target.value
    });
    setBusiness(selectedBusiness);
  };

  const editTogglePopUp = () => {
    setEditOpen(!editOpen);
  };

  const togglePopUp = () => {
    setOpen(!open);
  };

  const handleOnClose = () => {
    setIsEditing(false);
    setMenuName('');
    setHtml('');
    togglePopUp();
  };

  const handleEditOnClose = () => {
    setIsEditing(false);
    setEditMenuName('');
    setEditHtmlName('');
    editTogglePopUp();
  };

  const deleteCustomMenu = name => {
    _.remove(customHtmlMenu, value => {
      return value.name.toLowerCase() === name.toLowerCase();
    });
  };

  const onDelete = menuName => {
    handleChecked(customHtmlMenu, menuName, false);
    deleteCustomMenu(menuName);
    setIsEditing(!isEditing);
    editTogglePopUp();
  };

  const getCustomMenu = fileUrl => {
    return {
      name: menuName.trim(),
      path: fileUrl,
      module: 'HTML'
    };
  };

  const createCustomMenu = (name, customMenu) => {
    deleteCustomMenu(name); //removing menu with same name to avoid duplicate
    deleteCustomMenu(customMenu.name); //removing menu with same name to avoid duplicate
    setCustomHtmlMenu(customHtmlMenu.concat(customMenu));
  };

  const handleCreate = async val => {
    const fileUrl = await saveFile(html, user.tenant.esKey);
    const customMenu = getCustomMenu(fileUrl);
    const menuAttribute = convertToMenuName(menuName);

    createCustomMenu(customMenu.name, customMenu);
    setMenu({ ...menu, [menuAttribute]: customMenu });
    setIsSelected({ ...isSelected, [menuName]: true });
    setMenuName('');
    setHtml('');
    togglePopUp();
  };

  const onEdit = menuName => {
    setDefaultMenuName(menuName);
    const editMenu = _.filter(customHtmlMenu, item => {
      if (item.name === menuName) {
        return item;
      }
    });
    const htmlObject = {
      name: editMenu[0].path
    };
    setEditHtmlName(htmlObject);
    setEditMenuName(menuName);
    setIsEditing(!isEditing);
    setEditOpen(!editOpen);
  };

  const deleteMenu = defName => {
    const convertedMenuName = convertToMenuName(defName);
    delete menu[convertedMenuName];
  };

  const getUpdatedCustomMenu = async () => {
    let fileUrl;
    editHtmlName.name.includes(
      'https://rules.casaqa.ajira.tech/public-html/fashionfolks/'
    )
      ? (fileUrl = editHtmlName.name)
      : (fileUrl = await saveFile(editHtmlName, user.tenant.esKey));
    return {
      name: editMenuName.trim(),
      path: fileUrl,
      module: 'HTML'
    };
  };

  const updateIsSelected = defName => {
    const selected = isSelected[defName];
    delete isSelected[defName];
    isSelected[editMenuName] = selected;
  };

  const handleUpdate = async defName => {
    const customMenu = await getUpdatedCustomMenu();
    const menuAttribute = convertToMenuName(editMenuName);

    createCustomMenu(defName, customMenu);
    updateIsSelected(defName);

    deleteMenu(defName);
    setMenu({ ...menu, [menuAttribute]: customMenu });

    editTogglePopUp();
    setIsEditing(!isEditing);
  };

  function renderDeleteField(menuName) {
    return (
      <div
        style={{
          border: '1px solid #CF0B0B',
          padding: '3px 5px 0px 5px',
          borderRadius: '5px',
          cursor: 'pointer'
        }}
        onClick={() => onDelete(menuName)}
      >
        <svg
          width="22"
          height="22"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM19 4H15.5L14.5 3H9.5L8.5 4H5V6H19V4Z"
            fill="#CF0B0B"
          />
        </svg>
      </div>
    );
  }

  const handleMenuClick = e => {
    if (
      Object.keys(menu).length === 1 &&
      _.includes(_.keys(menu), convertToMenuName(e.target.name))
    )
      return;
    handleChecked(navmenu, e.target.name, !isSelected[e.target.name]);
    handleChecked(customHtmlMenu, e.target.name, !isSelected[e.target.name]);
    setIsSelected({
      ...isSelected,
      [e.target.name]: !isSelected[e.target.name]
    });
  };

  const renderPermissionToggle = ({ permission, handleToggle }) => {
    const { enabled, name } = permission;

    return (
      <div key={name} className={pwaConfigStyles.permissionsToggleContainer}>
        <p>{name}</p>
        <LoginSwitch checked={enabled} name={name} onClick={handleToggle} />
      </div>
    );
  };

  const renderPermissions = () => {
    if (!_.has(pwaConfig, 'permissions')) return <></>;
    const allPermissions = getAllPermissions(pwaConfig.permissions);

    const handleToggle = ({ target }) => {
      setPermissions(prev => ({
        ...prev,
        [target.name]: { ...prev[target.name], enabled: target.checked }
      }));
    };

    return (
      <div className={pwaConfigStyles.permissionsContainer}>
        {allPermissions.map(permission =>
          renderPermissionToggle({
            permission: _.get(permissions, permission, {
              enabled: false,
              name: ''
            }),
            handleToggle
          })
        )}
      </div>
    );
  };

  return (
    <>
      <div>
        <img src={PWABanner} alt="" className={pwaConfigStyles.pwaBanner} />
      </div>
      <div className={pwaConfigStyles.container}>
        {show && (
          <div className={pwaConfigStyles.modal}>
            <div className={pwaConfigStyles.modalcontent}>
              <div className={pwaConfigStyles.flexDisplay}>
                <Save style={{ height: 45 }} />
                <h3 className={pwaConfigStyles.saveHead}>SAVED</h3>
              </div>

              <p className={pwaConfigStyles.content}>
                PWA Configuration Changes Saved Successfully
              </p>

              <div className={pwaConfigStyles.flexDisplay}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: '#F2755F', color: 'white' }}
                  onClick={() => setShow(false)}
                >
                  OK
                </Button>
              </div>
            </div>
          </div>
        )}

        <div className={pwaConfigStyles.maindiv}>
          <p className={pwaConfigStyles.heading}>Customize PWA</p>
          <FormControl
            style={{
              width: '10%',
              position: 'absolute',
              top: 20,
              right: '10%'
            }}
          >
            <InputLabel>Business Unit</InputLabel>
            {business && (
              <Select
                labelId="businessLabel"
                value={business?.id}
                onChange={handleBusinessChange}
              >
                {_.map(user?.getBusinesses(), b => (
                  <MenuItem value={b.id} key={b.id}>
                    {b.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormControl>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <div className={pwaConfigStyles.row}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div className={pwaConfigStyles.customContainer}>
                <span style={{ marginRight: '3%', marginTop: '1%' }}>
                  <b>Set Menu Options*</b>
                </span>
                {!isEditing ? (
                  <CustomMenuForm
                    open={open}
                    togglePopUp={togglePopUp}
                    menuName={menuName}
                    setMenuName={setMenuName}
                    html={html}
                    setHtml={setHtml}
                    fieldName={'Create'}
                    handleCreate={handleCreate}
                    handleOnClose={handleOnClose}
                    isEditing={isEditing}
                    renderDeleteField={renderDeleteField}
                    editMenuName={editMenuName}
                    defaultMenuName={defaultMenuName}
                    menu={menu}
                    customHtmlMenu={customHtmlMenu}
                    convertToMenuName={convertToMenuName}
                  />
                ) : (
                  <CustomMenuForm
                    open={editOpen}
                    togglePopUp={editTogglePopUp}
                    menuName={editMenuName}
                    setMenuName={setEditMenuName}
                    html={editHtmlName}
                    setHtml={setEditHtmlName}
                    fieldName={'Save'}
                    handleCreate={handleUpdate}
                    handleOnClose={handleEditOnClose}
                    isEditing={isEditing}
                    renderDeleteField={renderDeleteField}
                    editMenuName={editMenuName}
                    defaultMenuName={defaultMenuName}
                    menu={menu}
                    customHtmlMenu={customHtmlMenu}
                    convertToMenuName={convertToMenuName}
                  />
                )}
              </div>
              {isEdit && (
                <Button
                  color="primary"
                  variant="contained"
                  // disabled={!canSave()}
                  onClick={() => setIsEdit(false)}
                >
                  Edit
                </Button>
              )}
            </div>

            <div className={pwaConfigStyles.menus}>
              {navmenu?.map(val => {
                return (
                  <MenuCard
                    key={val.name}
                    menuName={val.name}
                    isSelected={isSelected[val.name]}
                    isEdit={isEdit}
                    isCustom={false}
                    onClick={handleMenuClick}
                    onEdit={undefined}
                  />
                );
              })}
              {customHtmlMenu &&
                _.map(customHtmlMenu, val => {
                  return (
                    <MenuCard
                      key={val.name}
                      menuName={val.name}
                      isSelected={isSelected[val.name]}
                      isEdit={isEdit}
                      isCustom={true}
                      onClick={handleMenuClick}
                      onEdit={onEdit}
                    />
                  );
                })}
              {!isEdit && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={togglePopUp}
                  style={{
                    fontWeight: 'normal',
                    height: '37px'
                  }}
                >
                  <AddCircle
                    style={{
                      width: '16px',
                      height: '16px'
                    }}
                  />
                </Button>
              )}
            </div>

            <div>
              <span>
                <b>Set Pwa Landing Page</b>
              </span>
              <br />
              <RadioGroup
                row
                name="landingPage"
                value={initialPagePwa}
                onChange={e => {
                  setInitialPagePwa(e.target.value);
                }}
              >
                {menu &&
                  Object.values(menu).map((item, index) => {
                    if (item.module !== 'HTML') {
                      return (
                        <>
                          <FormControlLabel
                            key={index}
                            control={<CustomRadio key={item.module} />}
                            label={item.name}
                            disabled={isEdit && true}
                            value={item.module}
                            checked={
                              initialPagePwa && initialPagePwa === item.module
                            }
                          />
                        </>
                      );
                    }
                  })}
              </RadioGroup>
            </div>
            <br />

            <div className={pwaConfigStyles.headerFooterDiv}>
              <span>
                <b> Do you want to set header and footer for specific pages?</b>
              </span>
              <div style={{ marginRight: '12%' }}>
                <LoginSwitch
                  checked={pageImage}
                  name="setPageWiseImage"
                  onClick={handleSwitch}
                  disabled={isEdit && true}
                />
              </div>
            </div>
            {pageImage === false ? (
              <Card
                style={{ padding: 20, boxShadow: '-2px 0px 25px 6px #EAEEF2' }}
              >
                <span>
                  <b>Common Header and Footer*</b>
                </span>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: 20
                  }}
                >
                  <ImageUpload
                    label="Set Header (300px*50px)"
                    image={header}
                    setImage={setHeader}
                    pageWiseView={pageImage}
                    isEdit={isEdit}
                    size={true}
                  />

                  <ImageUpload
                    label="Set Footer (300px*50px)"
                    image={footer}
                    setImage={setFooter}
                    pageWiseView={pageImage}
                    isEdit={isEdit}
                    size={true}
                  />
                </div>
              </Card>
            ) : (
              <Grid container style={{ margin: 0 }}>
                <Grid xs={6}>
                  <Card
                    style={{
                      padding: 20,
                      boxShadow: '-2px 0px 25px 6px #EAEEF2',
                      margin: 10,
                      paddingLeft: 50,
                      paddingRight: 50
                    }}
                  >
                    <div style={{ textAlign: 'center' }}>
                      <b>Home*</b>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: 20
                      }}
                    >
                      <ImageUpload
                        label="Set Header (300px*50px)"
                        image={header}
                        setImage={setHeader}
                        size={true}
                      />
                      <ImageUpload
                        label="Set Footer (300px*50px)"
                        image={footer}
                        setImage={setFooter}
                        size={true}
                      />
                    </div>
                  </Card>
                </Grid>
                <Grid xs={6}>
                  <Card
                    style={{
                      padding: 20,
                      boxShadow: '-2px 0px 25px 6px #EAEEF2',
                      margin: 10,
                      paddingLeft: 50,
                      paddingRight: 50
                    }}
                  >
                    <div style={{ textAlign: 'center' }}>
                      <b>Offer For You</b>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: 20
                      }}
                    >
                      <ImageUpload
                        label="Set Header (300px*50px)"
                        image={offerHeader}
                        setImage={setOfferHeader}
                        size={true}
                      />
                      <ImageUpload
                        label="Set Footer (300px*50px)"
                        image={offerFooter}
                        setImage={setOfferFooter}
                        size={true}
                      />
                    </div>
                  </Card>
                </Grid>

                <Grid xs={6}>
                  <Card
                    style={{
                      padding: 20,
                      boxShadow: '-2px 0px 25px 6px #EAEEF2',
                      margin: 10,
                      paddingLeft: 50,
                      paddingRight: 50
                    }}
                  >
                    <div style={{ textAlign: 'center' }}>
                      <b>Loyalty Details</b>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: 20
                      }}
                    >
                      <ImageUpload
                        label="Set Header (300px*50px)"
                        image={loyaltyHeader}
                        setImage={setLoyaltyHeader}
                        size={true}
                      />
                      <ImageUpload
                        label="Set Footer (300px*50px)"
                        image={loyaltyFooter}
                        setImage={setLoyaltyFooter}
                        size={true}
                      />
                    </div>
                  </Card>
                </Grid>
                <Grid xs={6}>
                  <Card
                    style={{
                      padding: 20,
                      boxShadow: '-2px 0px 25px 6px #EAEEF2',
                      margin: 10,
                      paddingLeft: 50,
                      paddingRight: 50
                    }}
                  >
                    <div style={{ textAlign: 'center' }}>
                      <b>Profile Page</b>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: 20
                      }}
                    >
                      <ImageUpload
                        label="Set Header (300px*50px)"
                        image={profileHeader}
                        setImage={setProfileHeader}
                        size={true}
                      />
                      <ImageUpload
                        label="Set Footer (300px*50px)"
                        image={profileFooter}
                        setImage={setProfileFooter}
                        size={true}
                      />
                    </div>
                  </Card>
                </Grid>
              </Grid>
            )}
          </div>
          <br />

          <div className={pwaConfigStyles.row}>
            <span>
              <b>Set the Screen and Logo</b>
            </span>
            <Card
              style={{
                padding: 20,
                boxShadow: '-2px 0px 25px 6px #EAEEF2',
                marginTop: 20
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: 20,
                  marginRight: 90,
                  marginLeft: 90
                }}
              >
                <ImageUpload
                  label="Set Splash Screen"
                  image={splashScreen}
                  setImage={setSplashScreen}
                  isEdit={isEdit}
                />

                <ImageUpload
                  label="Set Thank You Screen"
                  image={thankyouScreen}
                  setImage={setThankyouScreen}
                  isEdit={isEdit}
                />

                <ImageUpload
                  label="Set PWA Logo Image"
                  image={logo}
                  setImage={setLogo}
                  isEdit={isEdit}
                />
              </div>
            </Card>
          </div>
          <br />

          <div className={pwaConfigStyles.row}>
            <FeatureSelect
              label="Choose Font"
              fontName={fontName}
              setfontName={setfontName}
              fontNames={font}
              isEdit={isEdit}
            />
          </div>
          <br />

          <div className={pwaConfigStyles.row}>
            <div style={{ marginBottom: 10 }}>
              <b>Set Theme</b>
            </div>
            <Color
              label="Set Primary Color theme"
              value={theme}
              setColor={setTheme}
              setStyle={{
                height: 21,
                padding: 0,
                border: '5px solid #D9D9D9',
                borderRadius: 3
              }}
              isEdit={isEdit}
            />
            <Color
              label="Set Side-Nav theme"
              value={sidenavTheme}
              setColor={setSidenavTheme}
              setStyle={{
                height: 21,
                padding: 0,
                border: '5px solid #D9D9D9',
                borderRadius: 3
              }}
              isEdit={isEdit}
            />
            <Color
              text="true"
              label="Set Side-Nav Text Theme"
              value={sideNavText}
              setColor={setSideNavText}
              setStyle={{
                height: 21,
                padding: 0,
                border: '5px solid #D9D9D9',
                borderRadius: 3
              }}
              isEdit={isEdit}
            />
          </div>
          <br />

          {permissions && (
            <div className={pwaConfigStyles.row}>
              <div style={{ marginBottom: 10 }}>
                <b>Permissions</b>
              </div>
              {renderPermissions()}
            </div>
          )}

          <br />
          <div className={pwaConfigStyles.rowhead}>
            <b>Loyalty Signup</b>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {isEdit ? (
                <IconButton onClick={handleSignUp}>
                  <Edit />
                </IconButton>
              ) : (
                <Tooltip
                  title={
                    'Save the current changes and then edit loyalty signup'
                  }
                >
                  <IconButton>
                    <Edit />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </div>
          <br />

          {viewUrl && (
            <div className={pwaConfigStyles.rowhead}>
              <b>Loyalty Signup URL</b>
              <div style={{ display: 'flex' }}>
                <p id="url">{link}</p>
                <Tooltip title={tooltip ? 'Copied' : 'Copy'}>
                  <FileCopy
                    style={{
                      color: 'grey',
                      cursor: 'pointer',
                      marginLeft: '20px'
                    }}
                    onClick={copyLink}
                  />
                </Tooltip>
              </div>
            </div>
          )}
        </div>

        <div className={pwaConfigStyles.save}>
          {isSaving ? (
            <div className={pwaConfigStyles.loader}></div>
          ) : (
            !isEdit && (
              <Button
                color="primary"
                variant="contained"
                disabled={!canSave()}
                onClick={onSave}
              >
                SAVE
              </Button>
            )
          )}
        </div>
      </div>
    </>
  );
}

export default PWAConfig;
