/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/jsx-key */
import * as React from 'react';
import { Grid } from '@material-ui/core';
import { moduleSetting } from '../../constants/moduleSetting';
import { Checkmark } from 'react-checkmark';
import { ParentLink } from '../../utils/ParentLink';
import makeStyles from '@material-ui/core/styles/makeStyles';
import moduleBanner from '../Assets/product_guide.png';
import { Typography } from '@material-ui/core';
import { moduleSetup } from '../../constants/generalSetting';

const useStyles = makeStyles(() => ({
  productGuide: {
    backgroundImage: `url(${moduleBanner})`,
    color: '#fff',
    width: '97%',
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 30,
    height: 213,
    marginTop: 15,
    justifyContent: 'center',
    '& .MuiTypography-h4': {
      fontSize: 26,
      fontWeight: 700,
      fontFamily: 'Nunito'
    }
  },
  OptionContainer: {
    paddingTop: 20
  },
  configure: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '97%',
    '& .MuiTypography-h4': {
      fontSize: 20,
      fontWeight: 700,
      fontFamily: 'Nunito'
    }
  }
}));
const ModuleSetup = () => {
  const { productGuide, OptionContainer, configure } = useStyles();
  const { bannerTitle, bannerSubTitle, moduleTitle } = moduleSetup;
  const mockData = [
    { id: 1, name: 'Retensee' },
    { id: 2, name: 'Offerio' }
  ];

  return (
    <div>
      <div className={productGuide}>
        <Typography variant="h4">{bannerTitle}</Typography>
        <Typography variant="subTitle1" style={{ fontSize: 18 }}>
          {bannerSubTitle}
        </Typography>
      </div>
      <div className={OptionContainer}>
        <div className={configure}>
          <Typography variant="h4">{moduleTitle}</Typography>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: 7 }}>1 of 8 Configured</span>
            <Checkmark size={16} />
          </div>
        </div>
        <Grid container spacing={2} style={{ paddingTop: 20 }}>
          {moduleSetting.map(data => {
            const found = mockData.filter(
              filtered => filtered.name === data.title
            );

            return (
              <Grid
                item
                xs={4}
                lg={4}
                className={found.length ? 'gridClass' : ' disabledColor'}
              >
                <img
                  src={found.length ? data.imgSrc : data.disabledImgSrc}
                  alt="store icon"
                />
                <div className="options">
                  <ParentLink
                    className={
                      found.length ? 'createOfferLink' : 'disabledColor'
                    }
                    to={`${data.pathName}`}
                  >
                    {data.title}
                  </ParentLink>

                  <p
                    className={
                      found.length ? 'listParagaraph' : ' disabledColor'
                    }
                  >
                    {data.subtitle}
                  </p>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </div>
  );
};

export default ModuleSetup;
