import React, { Context } from 'react';
import _ from 'lodash';
import noop from "lodash/noop";

type FlipbookCartContext = {
  cartItems: [];
  storeCode: string;
  setCartItems: (cartItems: any) => void;
  removedItems: any;
  setRemovedItems: (items: any) => void;
  isInvalidCart: boolean;
  setIsInvalidCart: (flag: boolean) => void;
  isSessionActivated: boolean;
};
export const FlipbookCartContext: Context<FlipbookCartContext> = React.createContext<
  FlipbookCartContext
>({
  cartItems: [],
  storeCode: '',
  setCartItems: _.noop,
  isSessionActivated: false,
  removedItems: [],
  setRemovedItems: noop,
  isInvalidCart: false,
  setIsInvalidCart: noop
});
