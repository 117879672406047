import { assign, cloneDeep, get, isEmpty, map, reduce } from 'lodash';
import { TenantConfigTypes } from '../../../constants/tenantConfigTypes';
import configService from '../../../services/config.service';
import { SectionType } from '../../DynamicInvoiceGenerator/InvoiceGenerator';
import SectionField from '../../DynamicInvoiceGenerator/models/SectionField';
import baseConfigs from '../utils/configs';
import { AdditionalConfigFields, SectionConfig } from './../utils/configs';
import config from '../../../config';

type Configs = [SectionConfig, AdditionalConfigFields];
type GetInvoiceConfigs = (businessUnitId: number) => Promise<Configs>;
type FieldProcessor = (
  base: SectionField[],
  incomming: SectionField[]
) => SectionField[];
type FieldsProcessor = (
  sectionType: string,
  baseFields: SectionField[],
  incommingFields: SectionField[]
) => [SectionField[], SectionField[] | null];
type ConfigProcessor = (tenantConfigs: SectionConfig) => Configs;

const getBaseConfigs = (): Configs => {
  return [baseConfigs.sectionConfig, baseConfigs.additionalConfigFields];
};

const getProcessedConfigFields: FieldProcessor = (base, incomming) => {
  const reducedIncommingFieldsByIdx = reduce(
    incomming,
    (acc, f) => {
      acc[f.id!] = { ...f, req: true };
      return acc;
    },
    {} as { [key: string]: SectionField }
  );
  return map(base, (baseField: SectionField) =>
    assign(
      {},
      baseField,
      get(reducedIncommingFieldsByIdx, baseField.id!, {
        req: baseField.req ?? false
      })
    )
  );
};

const getProcessedFields: FieldsProcessor = (
  sectionType,
  baseFields,
  incommingFields
) => {
  const configField =
    sectionType === SectionType.MISC_INFO
      ? map(incommingFields, f => ({ ...f, req: true }))
      : getProcessedConfigFields(baseFields, incommingFields);
  const baseExtraFields = baseConfigs.additionalConfigFields[sectionType];
  if (!baseExtraFields) return [configField, null];

  const additionalFields = getProcessedAdditionalFields(
    baseExtraFields,
    incommingFields
  );
  return [configField, additionalFields];
};

const processConfigs: ConfigProcessor = tenantConfigs => {
  const processedConfigs = {} as SectionConfig;
  const processedAdditionalConfigs = {} as AdditionalConfigFields;
  Object.entries(baseConfigs.sectionConfig).forEach(
    ([sectionType, baseConfig]) => {
      const { fields: baseFields } = baseConfig;
      const [processedFields, additionalFields] = getProcessedFields(
        sectionType,
        baseFields,
        get(tenantConfigs[sectionType], 'fields', [])
      );

      processedConfigs[sectionType] = assign(tenantConfigs[sectionType], {
        fields: processedFields,
        heading: get(tenantConfigs[sectionType], 'heading', baseConfig.heading),
      });
      if (additionalFields)
        processedAdditionalConfigs[sectionType] = additionalFields;
    }
  );
  console.log('processed', processedConfigs, processedAdditionalConfigs);
  return [processedConfigs, processedAdditionalConfigs];
};

const getProcessedAdditionalFields: FieldProcessor = (
  base,
  incommingFields
) => {
  if (!base) {
    return [];
  }
  const incommingFieldsMap = reduce(
    incommingFields,
    (acc, f) => {
      acc[f.id!] = { ...f, req: true };
      return acc;
    },
    {} as { [key: string]: SectionField }
  );
  return map(base, baseField =>
    get(incommingFieldsMap, baseField.id!, baseField)
  ) as SectionField[];
};

export const getInvoiceConfigs: GetInvoiceConfigs = async businessUnitId => {
  const invoiceConfig = await configService.getConfigDetails(
    TenantConfigTypes.InvoiceGenerator,
    { businessUnitId }
  );

  if (isEmpty(invoiceConfig)) return getBaseConfigs();

  return processConfigs(cloneDeep(invoiceConfig));
};

export const upsertRequestHandler = async (tenant: string) => {
  const invoiceRequestConfigUrl = new URL(
    `${config.ruleServerUrl}/users/config/upsert-invoice-request-handlers`
  );
  invoiceRequestConfigUrl.searchParams.set('tenant', tenant);

  return fetch(invoiceRequestConfigUrl.toString(), {
    credentials: 'include',
    method: 'POST',
    headers: {
      'Content-type': 'application/json'
    }
  });
};
