import React from 'react';
import { ReactComponent as RoleConfigEditIcon } from '../../Assets/images/user-list-edit.svg';
import Checkbox from '@material-ui/core/Checkbox';
import MaUTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import {
  useAsyncDebounce,
  useGlobalFilter,
  useFilters,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable
} from 'react-table';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { PaginationBar } from '../../PaginationBar/PaginationBar';
import { ExportCSV } from './ExportCSV';
import RoleFilters from './RoleFilters';
import { updateUserStatus } from '../../../services/userlist.service';
import userListClasses from '../userList.module.scss';
import { DownloadTemplate } from './DownloadTemplate';

const renderCheckbox = ({ indeterminate, ...rest }, ref) => {
  // const defaultRef = React.useRef();
  const resolvedRef = ref;

  // React.useEffect(() => {
  //   resolvedRef.current.indeterminate = indeterminate;
  // }, [resolvedRef, indeterminate]);

  return (
    <>
      <Checkbox ref={resolvedRef} {...rest} color="primary" disabled />
    </>
  );
};

const IndeterminateCheckbox = React.forwardRef(renderCheckbox);

const EnhancedTable = props => {
  const {
    defaultColumn,
    columns,
    data,
    getUserData,
    setEditData,
    setShowEditForm,
    filter,
    setFilter,
    setResetPasswordFlag,
    setShowFilterReset
  } = props;
  const defaultFilters = [
    { id: 'isActive', value: ' ' },
    { id: 'role', value: ' ' },
    { id: 'stores', value: ' ' }
  ];
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    gotoPage,
    pageCount,
    setPageSize,
    setAllFilters,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      defaultColumn,
      data
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      hooks.allColumns.push(columns => [
        {
          id: 'selection',
          Header: function renderHeadingCheckbox({
            getToggleAllRowsSelectedProps
          }) {
            return (
              <span style={{ width: 20, cursor: 'not-allowed' }}>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </span>
            );
          },
          Cell: function renderRowCheckboxes({ row }) {
            return (
              <span style={{ width: 20, cursor: 'not-allowed' }}>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </span>
            );
          }
        },
        ...columns,
        {
          id: 'Action',
          Header: 'Action',
          Cell: function renderActions({ row }) {
            return (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <span
                  style={{ color: '#F07663', cursor: 'pointer' }}
                  onClick={() => {
                    setEditData({
                      ...row.original,
                      stores: [...row.original.stores]
                    });
                    setShowEditForm(true);
                  }}
                >
                  <RoleConfigEditIcon
                    style={{ marginBottom: 5, marginRight: 10 }}
                  />
                  <span>Edit</span>
                </span>

                <span
                  style={{
                    cursor: 'pointer',
                    border: '2px solid rgb(221, 221, 221)',
                    color: 'rgb(240, 118, 99)',
                    boxSizing: 'border-box',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '55px',
                    width: '55%'
                  }}
                  onClick={() => {
                    setAllFilters(defaultFilters);
                    setShowFilterReset(true);
                    updateUserStatus({
                      email: row.original.email,
                      status: !row.original.isActive
                    }).then(() => getUserData());
                  }}
                >
                  {row.original.isActive ? 'Deactivate' : 'Activate'}
                </span>
                <span>
                  <VpnKeyIcon
                    style={{ color: '#f07663', cursor: 'pointer' }}
                    onClick={() => {
                      setEditData({
                        ...row.original
                      });
                      setShowEditForm(true);
                      setResetPasswordFlag(true);
                    }}
                  />
                </span>
              </div>
            );
          }
        }
      ]);
    }
  );

  const handleOnClickNextTable = () => {
    gotoPage(pageIndex + 1);
  };

  const handleOnClickPreviousTable = () => {
    gotoPage(pageIndex - 1);
  };

  const handleChangeRowsPerPage = event => {
    setPageSize(Number(event.target.value));
  };
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: 25
        }}
      >
        <div
          style={{
            fontSize: 16,
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          TOTAL USERS: {rows.length}
        </div>
        <div
          style={{
            justifyContent: 'space-between',
            display: 'flex',
            width: '28em'
          }}
        >
          <DownloadTemplate />
          <ExportCSV users={data} />
        </div>
      </div>
      <div>
        <RoleFilters
          filter={filter}
          setFilter={setFilter}
          preGlobalFilteredRows={preGlobalFilteredRows}
          setGlobalFilter={setGlobalFilter}
          useAsyncDebounce={useAsyncDebounce}
          setAllFilters={setAllFilters}
          setShowFilterReset={setShowFilterReset}
          defaultFilters={defaultFilters}
          getUserData={getUserData}
        />
      </div>
      <TableContainer>
        <MaUTable {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup, index) => (
              <TableRow {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <TableCell
                    className={userListClasses.userListTableCell}
                    style={{
                      fontWeight: 'bold',
                      width: column.id === 'selection' && 100,
                      textAlign: column.id === 'Action' && 'center',
                      paddingBottom: column.canFilter && 38
                    }}
                    key={index}
                  >
                    <span
                      {...(column.id === 'selection'
                        ? column.getHeaderProps()
                        : column.getHeaderProps(column.getSortByToggleProps()))}
                      style={{ marginBottom: 10 }}
                    >
                      {column.render('Header')}
                      {column.id !== 'selection' ? (
                        <TableSortLabel
                          active={column.isSorted}
                          direction={column.isSortedDesc ? 'desc' : 'asc'}
                        />
                      ) : null}
                    </span>
                    {column.canFilter ? column.render('Filter') : null}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    return (
                      <TableCell
                        className={userListClasses.userListTableCell}
                        {...cell.getCellProps()}
                        style={{
                          maxWidth: '250px',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap'
                        }}
                        key={index}
                      >
                        {cell.render('Cell')}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </MaUTable>
        {page.length === 0 && (
          <div className={userListClasses.noDataFoundContainer}>
            No Data Found
          </div>
        )}
        <PaginationBar
          currentPage={pageIndex}
          totalPages={pageCount}
          handleOnClickNext={handleOnClickNextTable}
          handleOnClickPrevious={handleOnClickPreviousTable}
          rowsPerPage={pageSize}
          rowsPerPageOptions={[10, 25, 50, 100]}
          handleOnChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
};

export default EnhancedTable;
