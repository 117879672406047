/* eslint-disable @typescript-eslint/explicit-function-return-type */
import config from '../../config/index';
import moment from 'moment';

export const getStoreHealthReport = (
  startDate: string,
  endDate: string,
  page: number,
  offset: number,
  stores: [],
  sorting: { column: ''; type: '' }
) => {
  const storesData = { stores: stores, sorting: sorting };
  return fetch(
    `${
      config.ruleServerUrl
    }/users/reports/createList/store_health_dashboard?fromDate=${moment(
      startDate
    ).format('YYYY-MMM-DD')}&endDate=${moment(endDate).format(
      'YYYY-MMM-DD'
    )}&offset=${offset}&page=${page}
    `,
    {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify(storesData)
    }
  )
    .then(r => r.json())
    .catch(err => {
      console.log(err);
    });
};

export const viewStoreHealthReport = (page: number, offset: number) => {
  return fetch(
    `${config.ruleServerUrl}/users/reports/view/downloads/store_health_dashboard?offset=${offset}&page=${page}
    `,
    {
      credentials: 'include',
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    }
  )
    .then(r => r.json())
    .catch(err => {
      console.log(err);
    });
};

export const storeHealthDashboardGenerateReport = (
  startDate: string,
  endDate: string,
  stores: []
) => {
  const storesData = { stores: stores };
  return fetch(
    `${
      config.ruleServerUrl
    }/users/reports/download/store_health_dashboard?fromDate=${moment(
      startDate
    ).format('YYYY-MMM-DD')}&endDate=${moment(endDate).format('YYYY-MMM-DD')}
      `,
    {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify(storesData)
    }
  ).then(r => r.json());
};
