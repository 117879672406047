import React from 'react'
import {
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField
  } from '@material-ui/core';
import Select from 'react-select';
import _ from 'lodash';
import update from 'immutability-helper';
import discountDark from '../../../Assets/Offers/discountDark.svg';
import {
    ProductDiscount,
    DiscountType
} from '../../../../models/offer/OfferRule.model';
import {OptionType} from './'
import { applicationMethodValueFields } from './ApplicationMethodValue';
import DiscountProducts from './DiscountProducts';
import {
    NumericalOperators
  } from '../../../../constants';

const labelValueForCamelCase = (val = '', def = '') => {
    if (val) return { label: _.capitalize(val), value: val };
    return { label: _.capitalize(def), value: def };
};

const Discount = (props: {discount: ProductDiscount, readOnly: boolean, updateDiscount: any, productFieldOptions: any}) => {
    let {discount, readOnly, updateDiscount, productFieldOptions} = props

    const applicationMethodComponent = applicationMethodValueFields(
        discount,
        updateDiscount
      );

      const amountField = (
        <>
          Amount
          <TextField
            type="number"
            className={'col-3 ms-3'}
            value={discount.value}
            onChange={event => {
              const updatedDiscount = update(discount, {
                  value: {
                    $set: parseFloat(event.target.value.toString().slice(0, 8))
                  }
              });
              updateDiscount(updatedDiscount);
            }}
            disabled={readOnly}
            defaultValue={1}
          />{' '}
          INR,
        </>
      );

      const percentField = (
        <>
          <TextField
            className={'col-2'}
            type="number"
            onChange={event => {
              const updatedDiscount = update(discount, {
                  value: {
                    $set: parseFloat(event.target.value)
                  }
              });
    
              updateDiscount(updatedDiscount);
            }}
            disabled={readOnly}
            value={
              discount.discountType === DiscountType.percent.value
                ? discount.value
                : ''
            }
          />
          % of bill value. Max Discount
          <TextField
            className={'col-2 px-2'}
            type="number"
            onChange={event => {
              const updatedDiscount = update(discount, {
                  maxDiscount: {
                    $set:
                    parseFloat(event.target.value) >= 0
                        ? parseFloat(event.target.value)
                        : 0
                  }
              });
    
              updateDiscount(updatedDiscount);
            }}
            value={
              discount.maxDiscount > 0 ? discount.maxDiscount : ''
            }
            disabled={readOnly}
          />
          INR
        </>
      );
    
    const discountForm = (discountField: JSX.Element) => {
        return (
          <React.Fragment>
            {discountField}
            <div className="mx-3">Apply to</div>
            <Select
              className="aggregateSelect my-3 me-3"
              value={labelValueForCamelCase(
                discount?.applicationMethod?.method,
                'all'
              )}
              isSearchable={false}
              options={[
                { label: 'All Items', value: 'all' },
                { label: 'Multiples of Items', value: 'multiple' }
              ]}
              onChange={v => {
                const optionValue = v as OptionType;
                const value = optionValue.value;
                const updatedDiscount = update(discount, {
                    applicationMethod: {
                      method: {
                        $set: value
                      }
                    }
                });
                updateDiscount(updatedDiscount);
              }}
              isDisabled={readOnly}
            />
            {applicationMethodComponent}
          </React.Fragment>
        );
    };

    return (
        <div className={'d-flex flex-column mt-1'}>
        <div className={'d-flex align-items-center'}>
          <img
            src={discountDark}
            alt="Discount"
            className={'pr-1'}
            style={{ marginRight: 6 }}
          />
          Give Discount
        </div>
        <div className="pl-2">
          <RadioGroup
            name="discountType"
            value={discount.discountType}
            onChange={event => {
              const value = event.target.value;
              const updatedDiscount = update(discount, {
                  discountType: {
                    $set: value
                  },
                  value: {
                    $set: 0
                  },
                  maxDiscount: {
                    $set: -1
                  }
              });
              updateDiscount(updatedDiscount);
            }}
          >
            <div className={'d-flex align-items-center'}>
              <FormControlLabel
                className={'col-3'}
                style={{ textAlign: 'right' }}
                value={DiscountType.fixed.value}
                control={<Radio color="primary" disabled={readOnly} />}
                label={DiscountType.fixed.presentation}
              />
              <div
                className={
                  discount.discountType === DiscountType.fixed.value
                    ? 'col-9 d-flex align-items-center flex-wrap'
                    : 'd-none'
                }
              >
                {discountForm(amountField)}
              </div>
            </div>
            <div className={'d-flex align-items-start'}>
              <FormControlLabel
                className={'col-3'}
                style={{ textAlign: 'right' }}
                value={DiscountType.percent.value}
                control={<Radio color="primary" disabled={readOnly} />}
                label={DiscountType.percent.presentation}
              />
              <div
                className={
                  discount.discountType === DiscountType.percent.value
                    ? 'col-9 d-flex align-items-center flex-wrap'
                    : 'd-none'
                }
              >
                {discountForm(percentField)}
              </div>
            </div>
            <div className="d-flex align-items-center">
              <FormControlLabel
                className={'col-3'}
                style={{ textAlign: 'right' }}
                value={DiscountType.slash.value}
                control={<Radio color="primary" disabled={readOnly} />}
                label={DiscountType.slash.presentation}
              />
              <div
                className={
                  discount.discountType === DiscountType.slash.value
                    ? 'col-9 d-flex align-items-center flex-wrap'
                    : 'd-none'
                }
              >
                {discountForm(amountField)}
              </div>
            </div>
          </RadioGroup>
          <DiscountProducts
            discount={discount}
            readOnly={readOnly}
            updateDiscount={updateDiscount}
            productFieldOptions={productFieldOptions}
          />
          {discount.discountType === 'percent' && (
            <div
              style={{
                display: 'flex',
                paddingTop: '20px',
                alignItems: 'center'
              }}
            >
              <span style={{ marginRight: '20px' }}>Apply to products of</span>
              <Select
                className="aggregateSelect"
                value={labelValueForCamelCase(
                  discount?.rule?.type,
                  'all'
                )}
                options={[
                  { label: 'All', value: 'all' },
                  { label: 'Lowest', value: 'lowest' }
                ]}
                isSearchable={false}
                onChange={v => {
                  const optionValue = v as OptionType;
                  const value = optionValue.value as keyof typeof NumericalOperators;
                  const rule = discount?.rule || {
                    type: 'all',
                    value: 1
                  };
                  const discountRule = { ...rule, type: value };
                  const updatedDiscount = update(discount, {
                      rule: {
                        $set: discountRule
                      }
                  });
                  updateDiscount(updatedDiscount);
                }}
                isDisabled={readOnly}
              />
              {discount?.rule?.type !== 'all' && (
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '200px'
                  }}
                >
                  <label style={{ margin: '0 20px' }}>prices for</label>
                  <TextField
                    className={'col-2'}
                    type="number"
                    value={discount?.rule?.value}
                    onChange={event => {
                      const { value } = event.target;
                      if (
                        !value ||
                        (value[value.length - 1].match('[0-9]') &&
                          value[0].match('[1-9]'))
                      ) {
                        const rule = discount?.rule || {
                          type: 'all'
                        };
                        const discountRule = {
                          ...rule,
                          value: parseFloat(value)
                        };
                        const updatedDiscount = update(discount, {
                            rule: {
                              $set: discountRule
                            }
                        });
                        updateDiscount(updatedDiscount);
                      }
                    }}
                    disabled={readOnly}
                    defaultValue={1}
                  />
                  <span>units</span>
                </span>
              )}
            </div>
          )}
        </div>
      </div>
      
    )
}

export default Discount