/* eslint-disable @typescript-eslint/explicit-function-return-type */
import config from '../config';
function saveTenentStoreDetails(businessUnitId, data) {
  const url = new URL(
    `${config.ruleServerUrl}/users/store/businessUnit/${businessUnitId}/upsert`
  );

  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-type': 'application/json'
    }
  })
    .then(r => r.json())
    .catch(() => {
      return {};
    });
}

function saveProductDetails(data) {
  const url = new URL(
    `${config.ruleServerUrl}/users/module-settings-pim/categories`
  );

  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify({
      ...data
    }),
    headers: {
      'Content-type': 'application/json'
    }
  })
    .then(r => r.json())
    .catch(err => {
      return {};
    });
}

function updateTenentStoreDetails(data) {
  const url = new URL(`${config.ruleServerUrl}/users/tenant/tenant-stores`);

  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-type': 'application/json'
    }
  })
    .then(r => r.json())
    .catch(err => {
      return {};
    });
}

function getTenentStore(offset, rowsPerPage, business) {
  const url = new URL(
    `${config.ruleServerUrl}/users/tenant/paginated-stores/${offset}/${rowsPerPage}?businessUnit=${business}`
  );

  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-type': 'application/json'
    }
  })
    .then(r => r.json())
    .catch(err => {
      return {};
    });
}

function filterSearchDetails(offset, rowsPerPage, querySearch) {
  const url = new URL(
    `${config.ruleServerUrl}/users/tenant/paginated-stores/${offset}/${rowsPerPage}?${querySearch}`
  );

  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-type': 'application/json'
    }
  })
    .then(r => r.json())
    .catch(err => {
      return {};
    });
}

function exportCSVDetails() {
  const url = new URL(`${config.ruleServerUrl}/users/tenant/filteredstores`);

  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-type': 'application/json'
    }
  })
    .then(r => r.blob())
    .catch(err => {
      return {};
    });
}

function getStoreTags() {
  const url = new URL(`${config.ruleServerUrl}/users/store/tags`);

  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-type': 'application/json'
    }
  })
    .then(r => r.json())
    .catch(err => {
      return {};
    });
}

function uploadBulkStoreTags(businessUnitId, data) {
  return fetch(
    `${config.ruleServerUrl}/users/store/businessUnit/${businessUnitId}/file import`,
    {
      credentials: 'include',
      method: 'POST',
      body: data
    }
  )
    .then(r => r.json())
    .catch(err => {
      return console.log(err);
    });
}

const getTenentStoreConfig = (offset, rowsPerPage, business) => {
  return getTenentStore(offset, rowsPerPage, business);
};

const saveTenentStoreConfig = (businessUnitId, data) => {
  return saveTenentStoreDetails(businessUnitId, data);
};

const updateTenentStoreConfig = data => {
  return updateTenentStoreDetails(data);
};

const saveProductConfig = data => {
  return saveProductDetails(data);
};
const filterSearchConfig = (offset, rowsPerPage, querySearch) => {
  return filterSearchDetails(offset, rowsPerPage, querySearch);
};
const exportCSVConfig = () => {
  return exportCSVDetails();
};

const storeTagsOption = () => {
  return getStoreTags();
};

const bulkStoreTagsConfig = (businessUnitId, data) => {
  return uploadBulkStoreTags(businessUnitId, data);
};

export default {
  getTenentStoreConfig,
  saveTenentStoreConfig,
  updateTenentStoreConfig,
  saveProductConfig,
  filterSearchConfig,
  exportCSVConfig,
  storeTagsOption,
  bulkStoreTagsConfig
};
