/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/jsx-key */
import * as React from 'react';
import { Grid, Link } from '@material-ui/core';
import { generalSetting } from '../../constants/generalSetting';
import { Checkmark } from 'react-checkmark';
import { ParentLink } from '../../utils/ParentLink';
import './index.scss';
import { Typography } from '@material-ui/core';
import { moduleSetup } from '../../constants/generalSetting';
import makeStyles from '@material-ui/core/styles/makeStyles';
import moduleBanner from '../Assets/product_guide.png';

const useStyles = makeStyles(() => ({
  productGuide: {
    backgroundImage: `url(${moduleBanner})`,
    color: '#fff',
    width: '97%',
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 30,
    height: 213,
    marginTop: 15,
    justifyContent: 'center',
    '& .MuiTypography-h4': {
      fontSize: 26,
      fontWeight: 700,
      fontFamily: 'Nunito'
    }
  },
  OptionContainer: {
    paddingTop: 20
  },
  configure: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '97%',
    '& .MuiTypography-h4': {
      fontSize: 20,
      fontWeight: 700,
      fontFamily: 'Nunito'
    }
  },
  gridClass: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  createOfferLink: {
    textDecoration: 'none !important',
    fontWeight: 700,
    fontSize: 18
  },
  options: {
    marginLeft: 12,
    width: '206px'
  },
  optionTitle: {
    fontWeight: 700,
    fontSize: 16,
    marginBottom: 0,
    cursor: 'pointer',
    color: '#f3755f'
  },
  listParagaraph: {
    fontSize: 15,
    color: '#333333'
  }
}));

const GeneralSetting = () => {
  const {
    productGuide,
    OptionContainer,
    configure,
    gridClass,
    createOfferLink,
    options,
    optionTitle,
    listParagaraph
  } = useStyles();
  const { bannerTitle, bannerSubTitle, generalTitle } = moduleSetup;
  return (
    <div>
      <div className={productGuide}>
        <Typography variant="h4">{bannerTitle}</Typography>
        <Typography variant="subTitle1" style={{ fontSize: 18 }}>
          {bannerSubTitle}
        </Typography>
      </div>
      <div className={OptionContainer}>
        <div className={configure}>
          <Typography variant="h4">{generalTitle}</Typography>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: 7 }}>1 of 7 Configured</span>
            <Checkmark size={16} />
          </div>
        </div>
        <Grid container spacing={2} style={{ paddingTop: 20 }}>
          {generalSetting.map(item => {
            return (
              <Grid item xs={4} lg={4} className={gridClass}>
                <img src={item.imgSrc} alt="store icon" />
                <div className={options}>
                  {item.external ? (
                    <Link
                      href={item.pathName}
                      target="_blank"
                      className={createOfferLink}
                    >
                      <h6 className={optionTitle}>{item.title}</h6>
                    </Link>
                  ) : (
                    <ParentLink to={item.pathName} className={createOfferLink}>
                      <h6 className={optionTitle}>{item.title}</h6>
                    </ParentLink>
                  )}
                  <p className={listParagaraph}>{item.subtitle}</p>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </div>
  );
};

export default GeneralSetting;
