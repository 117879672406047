import config from '../config/index';
import _ from 'lodash';
import { Business, TenantStore } from '../models/User.model';
import queryString from 'query-string';

function storeHasTags(selectedStoreTags: any) {
  return (storeOption: any) =>
    _.some(_.intersectionBy(storeOption.tags, selectedStoreTags, 'value'));
}

export function getStores(business: Business): Promise<TenantStore[]> {
  return fetch(
    `${config.ruleServerUrl}/users/tenant/tenant-brand-business/${business.id}/stores`,
    {
      credentials: 'include',
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    }
  )
    .then(r => r.json())
    .then(stores =>
      _.map(stores, s => _.extend(new TenantStore(), s)).filter(v => v.isActive)
    );
}
export function getUserStores(business: Business): Promise<TenantStore[]> {
  return fetch(
    `${config.ruleServerUrl}/users/tenant/tenant-brand-business/${business.id}/user-stores`,
    {
      credentials: 'include',
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    }
  )
    .then(r => r.json())
    .then(stores =>
      _.map(stores, s => _.extend(new TenantStore(), s)).filter(v => v.isActive)
    );
}

export function getStoresByToken(Token: string): Promise<TenantStore[]> {
  const qs = queryString.stringify(
    {
      token: Token
    },
    { arrayFormat: 'bracket' }
  );
  return fetch(
    `${config.ruleServerUrl}/tenant/tenant-brand-business/stores/token?${qs}`,
    {
      credentials: 'include',
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    }
  )
    .then(r => r.json())
    .then(stores =>
      _.map(stores, s => _.extend(new TenantStore(), s)).filter(v => v.isActive)
    );
}

export function getStoreTagOptionsByStoreOptions(storesOptions: any) {
  const uniqueStoreTags = _.orderBy(
    _.uniq(_.flatMap(storesOptions, o => o.tags))
  );
  const storeTagOptions = _.map(uniqueStoreTags, tag => ({
    label: _.capitalize(tag.value),
    value: tag.value,
    type: tag.type
  }));
  const storeTagOptionsWithGroupName = _.map(
    _.groupBy(storeTagOptions, 'type'),
    (g, i) => {
      const group = _.uniqBy(g, 'value');
      return (
        i !== 'undefined' && {
          label: i,
          options: _.map(group, a => {
            return {
              label: a.value,
              value: a.value,
              type: a.type
            };
          })
        }
      );
    }
  );

  return storeTagOptionsWithGroupName;
}

export function getStoresWithAnyTags(storeOptions: any, storeTagOptions: any) {
  const selectedStoreTags = storeTagOptions.map((s: any) => {
    return { value: s.value };
  });
  const filteredStoreOptions = _.filter(
    storeOptions,
    storeHasTags(selectedStoreTags)
  );

  return filteredStoreOptions;
}
