import { Box } from '@material-ui/core';
import DoubleArrow from '@material-ui/icons/Height';
import React, { FC, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import {
  getCampaignDetail,
  getCampaignSmsStatus
} from '../../../../../../services/Campaign/campaign-details.service';
import {
  generateReport,
  generateROIReport
} from '../../../../../../services/Campaign/campaign-report.service';
import useFetch from '../../../../../../shared/hooks/useFetch';
import useMutation from '../../../../../../shared/hooks/useMutation';
import CampaignCard from '../../../../shared/components/CampaignCard/CampaignCard';
import LoadingButton from '../../../../shared/components/LoadingButton/LoadingButton';
import calculateRoi from '../../../../shared/utils/calculateRoi';
import formatNumber from '../../../../shared/utils/formatNumber';
import CalculateRoiForm from '../CalculateRoiForm/CalculateRoiForm';
import styles from './CampaignDetail.module.scss';
import useCurrentUser from '../../../../../../hooks/useCurrentUser';
import { isEqual } from 'lodash';
import { CampaignType } from '../../../../../../models/campaign/Campaign';
import { CampaignFilterContext } from '../../Context/CampaignFilterContext';
import { CampaignReportType } from '../../../../shared/utils/contants';

interface CampaignDetailProps {
  campaignId: string;
  type: CampaignType;
  setReportType: (reportType: any) => void;
  messageCost: number;
  setMessageCost: (messageCost: number) => void;
}

const CampaignDetail: FC<CampaignDetailProps> = ({
  campaignId,
  type,
  setReportType,
  messageCost,
  setMessageCost
}) => {
  const [returnOfInvestment, setReturnOfInvestment] = useState(0);
  const { data } = useFetch(() => getCampaignDetail(campaignId));
  const user = useCurrentUser();
  const [defaultBusinessId, setDefaultBusinessId] = useState<any>(-1);
  const { data: smsStatus } = useFetch(() => getCampaignSmsStatus(campaignId));
  const campaignFilterContext = useContext(CampaignFilterContext);
  const { mutate: onGenerateReport, isLoading: isGenerating } = useMutation(
    generateReport.bind(null, campaignId)
  );
  const { mutate: onGenerateROI, isLoading: isGeneratingROI } = useMutation(
    generateROIReport.bind(
      null,
      campaignId,
      defaultBusinessId,
      messageCost,
      campaignFilterContext.startDate,
      campaignFilterContext.endDate
    )
  );

  const { targeted = 0, converted = 0, revenue = 0 } = data || {};

  useEffect(() => {
    if (isEqual(defaultBusinessId, -1) && user.isLoggedIn) {
      const id = user.getDefaultBusinessId();
      setDefaultBusinessId(id);
    }
  }, [defaultBusinessId, setDefaultBusinessId, user]);

  const handleCalculateRoi = (messageCost: number): void => {
    setReturnOfInvestment(calculateRoi({ messageCost, revenue, targeted }));
  };
  console.log(smsStatus);

  const handleGenerateReport = (): void => {
    setReportType(CampaignReportType.DEFAULT);
    onGenerateReport()
      .then(() =>
        toast.success('Your report generation is started successfully.')
      )
      .catch(() => toast.error('Something went wrong.'));
  };

  const handleGenerateROIReport = (): void => {
    setReportType(CampaignReportType.ROI);
    onGenerateROI()
      .then(() =>
        toast.success('Your ROI report generation is started successfully.')
      )
      .catch(() => toast.error('Something went wrong.'));
  };

  const smsSentPercentage =
    ((smsStatus?.sentCount || 0) / (smsStatus?.targetCount || 100)) * 100 || 0;

  const campaignDetails = [
    {
      label: 'Campaign live status',
      value: (
        <span
          className={styles.progressContainer}
          title={`${smsSentPercentage}%`}
        >
          <span
            className={styles.progress}
            style={{
              width: `${smsSentPercentage}%`
            }}
          ></span>
        </span>
      )
    },
    {
      label: 'No of people targeted',
      value: formatNumber(targeted)
    },
    {
      label: 'No of people converted',
      value: formatNumber(converted)
    },
    {
      label: 'Conversion percentage',
      value: `${+formatNumber((converted / targeted) * 100) || 0}%`
    },
    { label: 'Campaign revenue', value: formatNumber(revenue) }
  ];

  const isRoiDisabled = () => {
    if (messageCost === null) {
      return true;
    }
    return !(messageCost > 0);
  };
  return (
    <Box id={styles.campaignDetailContainer}>
      <Box className={styles.campaignTableRowCards}>
        <Box className={styles.campaignDetail}>
          {campaignDetails.map(campaign => (
            <CampaignCard
              key={campaign.label}
              {...campaign}
              valueTypographyVariant="h6"
            />
          ))}
        </Box>
        <Box className={styles.calculateRoiForm}>
          <CalculateRoiForm
            onCalculate={handleCalculateRoi}
            setMessageCost={setMessageCost}
          />
        </Box>
        <Box className={styles.campaignDetailIcon}>
          <DoubleArrow color="disabled" className={styles.doubleArrowIcon} />
        </Box>
        <CampaignCard
          label="Return of Investment"
          value={formatNumber(returnOfInvestment)}
          valueTypographyVariant="h5"
        />
      </Box>
      <Box className={styles.ctaContainer}>
        <LoadingButton
          className={styles.reportGenerateBtn}
          variant="contained"
          color="primary"
          size="small"
          disableElevation
          loading={isGenerating}
          onClick={handleGenerateReport}
        >
          Generate Report
        </LoadingButton>
        {type === CampaignType.BATCH && (
          <LoadingButton
            className={styles.reportGenerateBtn}
            variant="contained"
            color="primary"
            size="small"
            disableElevation
            disabled={isRoiDisabled()}
            loading={isGeneratingROI}
            onClick={handleGenerateROIReport}
          >
            Generate ROI Report
          </LoadingButton>
        )}
      </Box>
    </Box>
  );
};
export default CampaignDetail;
