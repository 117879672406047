import { Box } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import { debounce } from 'lodash';
import React, { FC } from 'react';

import CampaignFiltersType from '../../../../../models/campaign/CampaignFilters';
import Select from '../../../shared/components/Select/Select';
import styles from './CampaignFilters.module.scss';
import DateFilter from './DateFilter/DateFilter';

const campaignTypeFilter = [
  { label: 'Live', value: 'live' },
  { label: 'Batch', value: 'batch' }
];

const campaignStatusFilter = [
  { label: 'Running', value: 'running' },
  { label: 'Stopped', value: 'stopped' }
];

const sortOptions = [
  {
    label: 'Ascending',
    value: 'asc'
  },
  {
    label: 'Descending',
    value: 'desc'
  }
];

interface CampaignFilterProps {
  filters: CampaignFiltersType;
  onFilterChange: <T>(filter: { [key: string]: T }) => void;
}

const CampaignFilters: FC<CampaignFilterProps> = ({
  filters,
  onFilterChange
}) => {
  const debouncedSearch = debounce((searchTerm: string) => {
    onFilterChange({ search: searchTerm });
  }, 750);

  const handleSearchChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    debouncedSearch(event.target.value);
  };

  const {
    search,
    campaignType,
    campaignStatus,
    startDate,
    endDate,
    sort
  } = filters;

  return (
    <Box id={styles.campaignFilters}>
      <TextField
        className={styles.searchFormControl}
        placeholder="Search with Campaign"
        variant="outlined"
        defaultValue={search}
        onChange={handleSearchChange}
        InputProps={{
          startAdornment: <SearchIcon color="disabled" />
        }}
      />
      <DateFilter
        fromDateValue={startDate}
        toDateValue={endDate}
        onChange={onFilterChange}
      />
      <Select
        label="Campaign Status"
        name="campaignStatus"
        value={campaignStatus}
        options={campaignStatusFilter}
        onChange={onFilterChange}
        empty={{ label: 'All', value: '' }}
      />
      <Select
        label="Campaign Type"
        name="campaignType"
        value={campaignType}
        options={campaignTypeFilter}
        onChange={onFilterChange}
        empty={{ label: 'All', value: '' }}
      />
      <Select
        label="Sort By"
        name="sort"
        value={sort}
        options={sortOptions}
        onChange={onFilterChange}
        empty={{ label: 'None', value: '' }}
      />
    </Box>
  );
};
export default CampaignFilters;
