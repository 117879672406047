import _, { head } from 'lodash';

export class ProductFilter {
  public hierarchy: any;
  public productFields: any;
  public hierarchyNew: ProductCategory[] = [];
  public productFieldsNew: ProductCategory[] = [];
}

export interface ProductCategory {
  displayName: string;
  casaFieldName: string;
}

interface StoreTag {
  type: string;
  value: string;
}

export class TenantStore {
  public id = 0;
  public store = '';
  public tags: StoreTag[] = [];
  public latitude = 0;
  public longitude = 0;
  public name = '';
  public addressLineOne = '';
  public addressLineTwo = '';

  public get isDefined() {
    return this.id !== 0;
  }

  public get city() {
    return this.getTagValue('city');
  }

  public get state() {
    return this.getTagValue('state');
  }

  public getTagValue(tagType: string) {
    return _.get(_.find(this.tags, { type: tagType }), 'value');
  }
}

export class Business {
  public id = 0;
  public name = '0';
  public productFilter: ProductFilter = new ProductFilter();
  public isValid(): boolean {
    return this.id !== 0;
  }
}

export class Brand {
  public businesses: Business[] = [];
}

export class Tenant {
  public brands: Brand[] = [];
}

export interface TenantStore {
  store: string;
}

export class User {
  public id = 0;
  public email = '';
  public tenant: Tenant = new Tenant();
  public tenant_stores: TenantStore[] = [];

  public get isLoggedIn(): boolean {
    return this.id !== 0;
  }

  public get firstStore(): string {
    return _.get(_.head(this.tenant_stores), 'store') as string;
  }
  public getBusinesses(): Business[] {
    if (this.isLoggedIn)
      return _.flatMap(this.tenant.brands, b => b.businesses);
    else return [];
  }
  public getDefaultBusinessId(): number {
    const businesses = this.getBusinesses();
    const defaultBusiness = head(businesses);
    return defaultBusiness?.id as number;
  }

  public getPermissions(): string[] {
    const roleGroups: any = _.get(this, 'tenant_role_groups', []);
    let userPerms: any = [];

    for (let i = 0; i < roleGroups.length; i++) {
      const currPerms: any = _.get(roleGroups[i], 'permissions');
      if (Array.isArray(currPerms)) {
        userPerms = _.concat(userPerms, currPerms);
      } else {
        userPerms = _.concat(
          userPerms,
          _.flatMap(
            _.map(Object.values(currPerms), (perm: any) => perm.enabled)
          )
        );
      }
    }

    return userPerms;
  }

  public hasPermission(permission: string): boolean {
    const permissions = this.getPermissions();
    console.log(permissions);
    return permissions.includes(permission);
  }
}
