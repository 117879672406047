import React, { FC, useState } from 'react';
import OfferFilter from './Filter';
import OfferDownloadTable from './List';
import usePagination from '../../../shared/hooks/usePagination';
import { getOfferReportDownload } from '../../../services/TenantReports/OfferReport.service';
import { OfferReportFilter } from './FilterType';
import moment from 'moment';

export const GroupingColumn = {
  BillOffer: 'billsOfferCode',
  ProductOffer: 'productsOfferCode'
};

const offerInitialState: OfferReportFilter = {
  startDate: moment()
    .subtract(1, 'month')
    .subtract(1, 'day')
    .format('YYYY-MM-DD'),
  endDate: moment()
    .subtract(1, 'day')
    .format('YYYY-MM-DD'),
  businessId: undefined,
  groupingColumn: GroupingColumn.BillOffer
};

const OfferTenantReport: FC = () => {
  const [refreshView, setRefreshView] = useState<string>('');
  const [filters, setFilters] = useState<OfferReportFilter>(offerInitialState);
  const { data, isLoading, page, offset, setPage, setOffset } = usePagination(
    getOfferReportDownload,
    [refreshView],
    filters
  );
  return (
    <div>
      <OfferFilter
        setRefreshView={setRefreshView}
        filters={filters}
        setFilters={setFilters}
      />
      <OfferDownloadTable
        reports={data || []}
        isLoading={isLoading}
        page={page}
        offset={offset}
        setPage={setPage}
        setOffset={setOffset}
      />
    </div>
  );
};

export default OfferTenantReport;
