/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { FC, useEffect, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { getKpiReport } from '../../../services/TenantReports/KpiReport.service';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { get, head, isEqual, isUndefined, map, upperCase } from 'lodash';
import moment from 'moment';

export const KPI_REPORT_TYPE = {
  ANNUAL: 'Annual',
  QUARTERLY: 'Quarterly',
  DEFAULT: 'Default'
};

const REPORT_FILTER = {
  ANNUAL: ['year'],
  QUARTERLY: ['year', 'month'],
  DEFAULT: ['year', 'month']
};

export interface KPIReportInterface {
  reportFilterDate: string;
  columnName: string;
  businessId?: number;
}

const kpiReportInitialState: KPIReportInterface = {
  reportFilterDate: moment()
    .subtract(1, 'day')
    .format('YYYY-MM-DD'),
  columnName: 'type',
  businessId: undefined
};

interface ReportActionsProps {
  setRefreshView: (id: string) => void;
  setColumnValue: (value: string) => void;
  columnValue: string;
}

const KpiReport: FC<ReportActionsProps> = ({
  setRefreshView,
  setColumnValue,
  columnValue
}) => {
  const [filters, setFilters] = useState<KPIReportInterface>(
    kpiReportInitialState
  );
  const user = useCurrentUser();

  const handleChange = (value: any, name: string) => {
    setFilters({ ...filters, [name]: value });
  };

  const handleColumnValueChange = (value: any) => {
    setColumnValue(value);
  };
  const getOptions = () => {
    return map(KPI_REPORT_TYPE, value => {
      return value;
    });
  };

  useEffect(() => {
    if (isUndefined(filters.businessId) && !isEqual(user.id, 0)) {
      const business = user.getBusinesses();
      const headBusiness = head(business);
      const defaultBusinessId = get(headBusiness, 'id', 0);
      setFilters({ ...filters, businessId: defaultBusinessId });
    }
  }, [filters, setFilters, user]);

  const handleGenerateReport = (): void => {
    getKpiReport(filters, columnValue).then(res => {
      const jobId = get(res, 'jobId', '');
      setRefreshView(jobId);
    });
  };
  return (
    <div
      style={{ margin: 20, display: 'flex', justifyContent: 'space-between' }}
    >
      <div style={{ display: 'flex', padding: '2px' }}>
        <FormControl style={{ minWidth: 180, marginRight: '10px' }}>
          <InputLabel id="kpi-report-select-helper-label">
            KPI Report Type
          </InputLabel>
          <Select
            labelId="select-kpi-report-type-label"
            id="select-kpi-report-type"
            value={columnValue}
            label="Report Type"
            onChange={e => handleColumnValueChange(e.target.value)}
          >
            {map(getOptions(), (value, key) => {
              return (
                <MenuItem
                  value={value}
                  key={key}
                  style={{ textTransform: 'capitalize' }}
                >
                  {value}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            views={get(
              REPORT_FILTER,
              upperCase(columnValue),
              REPORT_FILTER.DEFAULT
            )}
            label="Start Date"
            value={filters.reportFilterDate}
            onChange={e => handleChange(e, 'reportFilterDate')}
            variant="inline"
          />
        </MuiPickersUtilsProvider>
      </div>
      <Button
        variant="contained"
        color="primary"
        disableElevation
        onClick={handleGenerateReport}
      >
        Generate Report
      </Button>
    </div>
  );
};

export default KpiReport;
