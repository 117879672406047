/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { NodeModel } from '@projectstorm/react-diagrams';
import { AdvancedPortModel } from '../Links/AdvancedLinkFactory';
import { PortTypes } from './PortModels';
import * as PortModels from '../BaseNodeModel/PortModels';

import _ from 'lodash';
export class BaseNodeModel extends NodeModel {
  constructor(options) {
    super(options);
    this.type = options.type;
    this.name = options.name || '';
    this.icon = options.icon;
    this.maximumLinks = 1;
    this.specs = options.specs || [];
    this.nextNodes = options.nextNodes || [];
    this.description = options.description;
    this.showDetails = true;
    this.isDetailsSaved = false;
    this.nodeId = '';
  }

  setNodeId(id) {
    this.nodeId = id;
  }
  addInPort(name) {
    this.addPort(
      new AdvancedPortModel({
        in: true,
        name: name || PortTypes.IN_PORT,
        maximumLinks: this.maximumLinks
      })
    );
  }

  addOutPort(name) {
    this.addPort(
      new AdvancedPortModel({
        in: false,
        name: name || PortTypes.OUT_PORT,
        nextNodes: this.nextNodes,
        maximumLinks: this.maximumLinks
      })
    );
  }

  buildPorts(portList) {
    _.forEach(portList, port => {
      if (port.type === PortTypes.IN_PORT) {
        this.addInPort(port.name);
      } else {
        this.addOutPort(port.name);
      }
    });
  }

  getParentNodes() {
    const links = this.getPort(PortTypes.IN_PORT).links;
    if (links) {
      return _.map(Object.values(links), link => link.sourcePort.parent);
    }
    return [];
  }

  isAllDetailsFilled(specs) {
    return _.every(
      _.map(specs, spec => {
        if (
          _.isEmpty(spec.value) &&
          !_.includes(['nodeId', 'journeyId', 'date'], spec.name)
        ) {
          return false;
        }
        return true;
      })
    );
  }

  serialize() {
    return {
      ...super.serialize(),
      type: this.type,
      color: this.color,
      name: this.name,
      specs: this.specs,
      showDetails: this.showDetails,
      isDetailsSaved: this.isDetailsSaved,
      icon: this.icon,
      nodeId: this.nodeId
    };
  }

  deserialize(ob, engine) {
    super.deserialize(ob, engine);
    const data = ob.data;
    this.color = data.color;
    this.name = data.name;
    this.type = data.type;
    this.specs = data.specs;
    this.showDetails = false;
    this.isDetailsSaved = data.isDetailsSaved;
    this.icon = data.icon;
    this.nodeId = data.nodeId;
  }
  getLinks() {
    return this.ports[PortModels.PortTypes.OUT_PORT].links;
  }
  remove() {
    _.forEach(this.getPort(PortTypes.OUT_PORT)?.links, link =>
      link.targetPort.parent.remove()
    );

    this.showDetails = false;

    super.remove();
    _.forEach(this.ports, port => {
      _.forEach(port.getLinks(), link => {
        link.remove();
      });
    });
  }
}
