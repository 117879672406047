export const configurationInitialState = {
  priorityOrder: '',
  authkey: '',
  sender: '',
  from: '',
  showCampaignNameInReport: false
};

export const karixInitialState = {
  priorityOrder: '',
  authkey: '',
  sender: '',
  from: ''
};

export const msg91InitialState = {
  priorityOrder: '',
  authkey: '',
  sender: '',
  showCampaignNameInReport: false
};

export const valueFirstInitialState = {
  priorityOrder: '',
  username: '',
  password: '',
  sender: '',
  showCampaignNameInReport: false
};

export const stmInitialState = {
  priorityOrder: '',
  authkey: '',
  sender: '',
  principleEntityId: '',
  clientId: '',
  showCampaignNameInReport: false
};

export const oneXtelInitialState = {
  priorityOrder: '',
  authkey: '',
  sender: '',
  promotionalSender: '',
  entityId: '',
  authKeyServiceExplicit: '',
  authKeyPromotional: '',
  showCampaignNameInReport: false
};

export const Mesg91InitialState = {
  priorityOrder: '',
  authkey: '',
  fromName: '',
  domain: '',
  sender: '',
  showCampaignNameInReport: false
};

export const commcryptInitialState = {
  priorityOrder: '',
  user: '',
  password: '',
  sender: '',
  country: '',
  clientsmsid: '',
  entityId: ''
};

export const reportInitialState = {
  to: '',
  cc: []
};

export const amazonInitialState = {
  priorityOrder: '',
  sender: '',
  awsAccessKeyId: '',
  awsSecretAccessKey: '',
  awsRegion: ''
};

export const ameyoInitialState = {
  priorityOrder: '',
  authkey: ''
};
