import config from '../../config';
import formatDate from '../../shared/utils/formatDate';
import { isEmpty } from 'lodash';
import { getProcessedFilters } from './common';
import { BillReport } from '../../components/TenantReports/MemberReport/BillWise/Filter';
import TransactionReportDownload from '../../models/TenantReports/TransactionReportDownload';
import TransactionWiseReport from '../../models/TenantReports/TransactionWiseReport';

export const _getTransactionWiseReport = (
  filters: any
): Promise<TransactionWiseReport[]> => {
  return fetch(
    `${config.ruleServerUrl}/users/reports/download/transaction/${
      filters?.businessId
    }?startDate=${formatDate(
      filters.startDate,
      'YYYY-MMM-DD'
    )}&endDate=${formatDate(filters.endDate, 'YYYY-MMM-DD')}`,
    {
      credentials: 'include',
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    }
  ).then(r => r.json());
};

export const getTransactionWiseReportViaPOST = async (
  filters: BillReport
): Promise<TransactionWiseReport[]> => {
  return fetch(
    `${config.ruleServerUrl}/users/reports/download/transaction/${
      filters?.businessId
    }?startDate=${formatDate(
      filters.startDate,
      'YYYY-MMM-DD'
    )}&endDate=${formatDate(filters.endDate, 'YYYY-MMM-DD')}`,
    {
      credentials: 'include',
      method: 'POST',
      body: JSON.stringify(getProcessedFilters(filters)),
      headers: {
        'Content-type': 'application/json'
      }
    }
  ).then(r => r.json());
};

export const getTransactionWiseReport = (filters: any): Promise<TransactionWiseReport[]> => {
  if (isEmpty(filters.additionalFields))
    return _getTransactionWiseReport(filters);
  return getTransactionWiseReportViaPOST(filters);
};

export const createTransactionWiseReport = ({
  businessId,
  offset,
  page,
  startDate,
  endDate
}: any): Promise<{ message: string; jobId: string }> => {
  return fetch(
    `${
      config.ruleServerUrl
    }/users/reports/download/${businessId}?offset=${offset}&page=${page}&startDate=${formatDate(
      startDate,
      'YYYY-MMM-DD'
    )}&endDate=${formatDate(endDate, 'YYYY-MMM-DD')}`,
    {
      credentials: 'include',
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    }
  ).then(r => r.json());
};

export const getTransactionWiseReportDownload = ({
  page,
  offset
}: {
  page: number;
  offset: number;
}): Promise<TransactionReportDownload[]> => {
  return fetch(
    `${config.ruleServerUrl}/users/reports/view/downloads/transaction?offset=${offset}&page=${page}`,
    { credentials: 'include' }
  ).then(res => res.json());
};
