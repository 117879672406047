import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { FlipbookLayoutTile } from '../../../models/FlipbookV2/FlipbookV2LayoutTile.model';
import { TileDataCollection } from '../../../models/FlipbookV2/TileData/TileDataCollection.model';
import ProductCard from './ProductCard';
import { LayoutType } from '../../../models/FlipbookV2/FlipbookV2Layout.model';
import _, { flatten, isEmpty, isUndefined, map } from 'lodash';
import CasaProductMaster from '../../../services/productMaster.service';
import { getStockItems } from '../../../services/ecomCart.service';
import { setProductsWithStockItems } from '../Utiles/FlipbookUtiles';
import { UserContext } from '../../../hooks/UserContext';
import { FlipbookCartContext } from '../Hooks/FlipbookCartContext';
import { BusinessUnitContext } from '../../OfferDefinition/BusinessUnitContext';
import { FlipbookContext } from '../Hooks/FlipbookContext';

interface ProductCards {
  isCustomerView: boolean;
  flipbookLayoutTile: FlipbookLayoutTile;
  productHierarchy: any;
  layoutType: LayoutType;
}
export const ProductCards: FC<ProductCards> = ({
  isCustomerView,
  flipbookLayoutTile,
  productHierarchy,
  layoutType
}) => {
  const [allProducts, setAllProducts] = useState<any>();
  const [isStockLoaded, setIsStockLoaded] = useState<boolean>(false);
  const userInfo = useContext(UserContext);
  const flipbookCartContext = useContext(FlipbookCartContext);
  const flipbookContext = useContext(FlipbookContext);
  const flipbookToken = flipbookContext.flipbookContext?.flipbookToken || '';
  const businessUnitContext = useContext(BusinessUnitContext);
  const storeCode = flipbookCartContext.storeCode;
  const tenantName =
    _.get(userInfo, 'userInfo.esKey') ||
    _.get(userInfo, 'userInfo.tenant.esKey');
  const updateProductDataWithStock = useCallback(
    (result: any, arrayOfSkus: any) => {
      if (isEmpty(result)) {
        return;
      }
      const isSplitShipment = _.get(
        businessUnitContext,
        'business.flipbookConfig.isSplitShipment',
        false
      );
      getStockItems(tenantName, storeCode, arrayOfSkus).then(
        stockItems => {
          const updatedProducts = setProductsWithStockItems(
            result,
            stockItems,
            isSplitShipment
          );
          setIsStockLoaded(true);
          setAllProducts(updatedProducts);
        },
        err => {
          setIsStockLoaded(true);
          setAllProducts(
            setProductsWithStockItems(result, [], isSplitShipment)
          );
        }
      );
    },
    [businessUnitContext, storeCode, tenantName]
  );
  useEffect(() => {
    if (!isUndefined(allProducts)) {
      return;
    }
    const tileData: any = flipbookLayoutTile?.tileData;
    const currentTileSKUs = map(tileData?.currentPageSKUS, 'sku');
    const flattenSkus = flatten(currentTileSKUs);
    if (isCustomerView) {
      CasaProductMaster.listProductsBySkuAndToken(
        flipbookToken,
        flattenSkus
      ).then(res => {
        setAllProducts(res);
      });
    } else {
      CasaProductMaster.listProductsBySku(flattenSkus).then(res => {
        setAllProducts(res);
      });
    }
  }, [allProducts, flipbookLayoutTile, flipbookToken, isCustomerView]);

  useEffect(() => {
    const isSessionActivated = flipbookCartContext.isSessionActivated;
    if (!isSessionActivated || isStockLoaded || isUndefined(allProducts)) {
      return;
    }
    const tileData: any = flipbookLayoutTile?.tileData;
    const currentTileSKUs = map(tileData?.currentPageSKUS, 'sku');
    const flattenSkus = flatten(currentTileSKUs);
    updateProductDataWithStock(allProducts, flattenSkus);
  }, [
    allProducts,
    flipbookCartContext.isSessionActivated,
    flipbookLayoutTile,
    isStockLoaded,
    updateProductDataWithStock
  ]);
  const productCard = () => {
    const tileData: any = flipbookLayoutTile?.tileData;
    const currentPageSKUS = tileData?.currentPageSKUS;
    return map(currentPageSKUS, skuWithPath => {
      const tileDataCollection = new TileDataCollection(
        tileData?.title,
        tileData?.skus,
        tileData?.isShowTitleOnFlipbook,
        tileData?.productHierarchyTreeChecked,
        tileData?.productHierarchyTreeExpanded,
        tileData?.productsFilteredByFirstHierarchy,
        tileData?.productCardTemplate,
        [skuWithPath]
      );
      return (
        <div>
          <ProductCard
            isStockLoaded={isStockLoaded}
            isProductLoaded={!isUndefined(allProducts)}
            tileDataCollection={tileDataCollection}
            productHierarchy={productHierarchy}
            allProducts={allProducts}
          />
        </div>
      );
    });
  };
  return (
    <div className={'center-align'}>
      <div>
        <div
          className={`${
            layoutType === LayoutType.SINGLE
              ? 'product-card-container-4x4'
              : 'product-card-container-2x2'
          }`}
        >
          {productCard()}
        </div>
      </div>
    </div>
  );
};
