/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from 'react';
import storeClass from './index.module.scss';
import { ParentLink } from '../../utils/ParentLink';
import _, { isEmpty } from 'lodash';
import {
  TableRow,
  TableCell,
  TableHead,
  Table,
  TableBody,
  FormControl,
  MenuItem,
  InputLabel,
  Select
} from '@material-ui/core';
import { useTable } from 'react-table';
import { Breadcrumbs, Typography, Paper } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import Pagination from './Pagination';
import CreateStore from './CreateStore';
import { styled } from '@material-ui/styles';
import EditStore from './EditStore';
import TableSwitch from './TableSwitch';
import ButtonField from '../../reusable/ButtonField';
import TenentStoreService from '../../services/tenentStore.service';
import useCurrentUser from '../../hooks/useCurrentUser';
import { Business } from '../../models/User.model';
import Papa from 'papaparse';
import { keyMapping } from '../../constants/storeManagement';
import { useDispatch } from 'react-redux';
import { toastMessageAction } from '../../store/action';
import { StoreTagsBulk } from './StoreTagsBulk';
import StoreTagsGrey from '../Assets/images/StoreTagsGrey.png';
import StoreTagsWhite from '../Assets/images/StoreTagsWhite.png';
import TableBodyLoader from '../../shared/components/TableBodyLoader/TableBodyLoader';
import { Alert } from '@material-ui/lab';

const StyledAppSelect = styled(Select)({
  '& .MuiOutlinedInput-input': {
    padding: 5,
    paddingTop: 0
  },
  '& .MuiMenu-paper': {
    top: '116px !important'
  }
});

const StyledTableCell = styled(TableCell)({
  '&.MuiTableCell-root': {
    border: '1px solid rgba(224, 224, 224, 1) !important'
  }
});
const StoreManagementTable = ({
  data = [],
  offset,
  rowsPerPage,
  setStoreStatus,
  storeStatus,
  businessName,
  storeTagsOption,
  business,
  loading,
  error
}) => {
  const columns = React.useMemo(
    () => [
      {
        accessor: 'storeNameAlias',
        Header: 'Store Name',
        width: 20,
        Cell: props => {
          return (
            <div
              style={{
                display: 'flex ',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <span style={{ fontSize: 18, marginLeft: 20 }}>
                {props.value}
              </span>
              <EditStore
                original={props?.row?.original}
                offset={offset}
                rowsPerPage={rowsPerPage}
                setStoreStatus={setStoreStatus}
                storeStatus={storeStatus}
                businessName={businessName}
                storeTagsOption={storeTagsOption}
                business={business}
              />
            </div>
          );
        }
      },
      {
        accessor: 'store',
        Header: 'Store Code',
        Cell: props => {
          return <div style={{ textAlign: 'center' }}> {props.value} </div>;
        }
      },
      {
        accessor: 'mobile',
        Header: 'Store Contact',
        Cell: props => {
          return <div style={{ textAlign: 'center' }}> {props.value} </div>;
        }
      },

      {
        accessor: 'blockLoyaltyEarn',
        Header: 'Loyalty Earn',
        Cell: props => {
          return (
            <div
              style={{
                display: 'flex ',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <TableSwitch
                earnlabel={'Earn'}
                blockLable={'Block'}
                checkedValue={!props.value}
                setStoreStatus={setStoreStatus}
                storeStatus={storeStatus}
                original={props?.row?.original}
                offset={offset}
                rowsPerPage={rowsPerPage}
                type={'blockLoyaltyEarn'}
                loyaltyEarn={true}
                disableSwitch={false}
              />
            </div>
          );
        }
      },
      {
        accessor: 'isActive',
        Header: 'Status',
        Cell: props => {
          return (
            <div
              style={{
                display: 'flex ',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <TableSwitch
                earnlabel={'Enabled'}
                blockLable={'Disabled'}
                checkedValue={props.value}
                statusCheck={true}
                original={props?.row?.original}
                offset={offset}
                rowsPerPage={rowsPerPage}
                setStoreStatus={setStoreStatus}
                storeStatus={storeStatus}
                type={'isActive'}
              />
            </div>
          );
        }
      }
    ],
    [businessName, storeStatus]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({
    columns,
    data
  });

  if (error && !loading)
    return (
      <Alert severity="error" style={{ margin: '1rem' }}>
        Oops! Something went wrong.
      </Alert>
    );

  if (isEmpty(data) && !loading)
    return (
      <div style={{ textAlign: 'center', padding: 15 }}>
        OOPS No Stores Found!
      </div>
    );

  return (
    <div>
      <Table {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup, index) => (
            <TableRow {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, i2) => (
                <StyledTableCell
                  {...column.getHeaderProps()}
                  style={{
                    fontWeight: 800,
                    fontSize: 18,
                    fontFamily: 'Nunito',
                    textAlign: 'center'
                  }}
                  key={index + i2}
                >
                  {column.render('Header')}
                </StyledTableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        {loading ? (
          <TableBodyLoader colSpan={5} rowsPerPage={10} width={'100%'} />
        ) : (
          <TableBody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()} key={i}>
                  {row.cells.map((cell, i2) => {
                    return (
                      <StyledTableCell
                        {...cell.getCellProps()}
                        style={{
                          width: '250px',
                          fontSize: 16,
                          fontFamily: 'Nunito'
                        }}
                        key={i + i2}
                      >
                        {cell.render('Cell')}
                      </StyledTableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        )}
      </Table>
    </div>
  );
};

const StoreManagement = () => {
  const data = [{ id: 1, name: 'test' }];
  const statusSeeds = [
    { id: true, name: 'Active' },
    { id: false, name: 'Inactive' }
  ];
  const [searchData, setSearchData] = React.useState('');
  const [allTags, setAllTags] = React.useState();
  const [status, setStatus] = React.useState();
  const [selectedTags, setSelectedTags] = React.useState(0);
  const [statusValue, setStatusValue] = React.useState('Select');
  const [storeData, SetStoreData] = React.useState([]);
  const [storeLoading, setStoreLoading] = useState(true);
  const [storeError, setStoreError] = useState(null);
  const [pageNum, setPageNum] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [maxPageCount, setMaxPageCount] = useState(0);
  const [storeTagsImage, setStoreTagsImage] = useState(StoreTagsGrey);
  const offset = rowsPerPage * (pageNum - 1);
  const [storeStatus, setStoreStatus] = useState(false);
  const user = useCurrentUser();
  const [business, setBusiness] = useState(new Business());
  const [count, setCount] = useState(0);
  const hiddenFileInput = React.useRef(null);
  const [querySearch, setQuerySearch] = useState('');
  const [storeTagsOption, setStoreTagsOption] = useState([]);
  const dispatch = useDispatch();
  const handleImportUsers = () => {
    hiddenFileInput.current.click();
  };

  useEffect(() => {
    setAllTags(data);
    setStatus(statusSeeds);
  }, []);

  const pageChangeHandler = value => {
    if (pageNum + value > 0) {
      setPageNum(pageNum + value);
    }
  };
  useEffect(() => {
    if (searchData === '' && business.name !== '0') {
      setStoreLoading(true);
      console.log(business.name, 'test', typeof business.name);
      TenentStoreService.getTenentStoreConfig(
        offset,
        rowsPerPage,
        business?.name
      )
        .then(res => {
          SetStoreData(res?.stores);
          setStoreError(null);
          setCount(res?.count[0]?.count);
          setMaxPageCount(Math.ceil(res?.count[0]?.count / rowsPerPage));
        })
        .finally(() => {
          setStoreLoading(false);
        })
        .catch(error => {
          setCount(0);
          setStoreError(error.message);
        });
    }
  }, [rowsPerPage, storeStatus, pageNum, business.name, searchData]);

  //handle business change
  const handleBusinessChange = event => {
    const selectedBusiness = _.find(user.getBusinesses(), {
      id: event.target.value
    });
    setBusiness(selectedBusiness);
  };
  useEffect(() => {
    setBusiness(
      _(user.getBusinesses())
        .orderBy(['id'])
        .first() || new Business()
    );
  }, [user]);

  const handleSearchApply = () => {
    if (searchData !== '' && statusValue !== 'Select') {
      setQuerySearch(
        `status=${statusValue}&searchkey=${searchData.trim()}&businessUnit=${
          business?.name
        }`
      );
    } else if (searchData === '' && statusValue !== 'Select') {
      setQuerySearch(`status=${statusValue}&businessUnit=${business?.name}`);
    } else if (searchData !== '' && statusValue === 'Select') {
      setQuerySearch(
        `searchkey=${searchData.trim()}&businessUnit=${business?.name}`
      );
    }
  };

  const handleStoreTagsChange = event => {
    const fileUploaded = event.target.files[0];

    if (fileUploaded?.type === 'text/csv') {
      const formData = new FormData();
      Papa.parse(fileUploaded, {
        header: true,
        skipEmptyLines: true,
        transformHeader: function(header) {
          return keyMapping[header];
        },
        complete: results => {
          if (results?.data?.length > 0) {
            formData.append('file', fileUploaded);
            TenentStoreService.bulkStoreTagsConfig(business.id, formData).then(
              () => {
                setStoreStatus(!storeStatus);
                dispatch(
                  toastMessageAction({
                    severity: 'success',
                    message: `Store Uploaded Successfully.`,
                    messageStatus: true
                  })
                );
              }
            );
          } else {
            dispatch(
              toastMessageAction({
                severity: 'error',
                message: `There is no data available in CSV file.  Please upload file with data.`,
                messageStatus: true
              })
            );
          }
        }
      });
    }
  };

  function downloadCSV(blob) {
    const data = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style = 'display: none';
    a.href = data;
    a.download = 'stores.csv';
    document.body.appendChild(a);
    a.click();

    setTimeout(function() {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(data);
    }, 100);
  }

  const exportCSVHandler = async () => {
    const file = await TenentStoreService.exportCSVConfig();
    downloadCSV(file);
  };
  const handleStatus = evt => {
    setStatusValue(evt.target.value);
    if (searchData !== '' && evt.target.value !== 'Select') {
      setQuerySearch(
        `status=${
          evt.target.value
        }&searchkey=${searchData.trim()}&businessUnit=${business?.name}`
      );
    } else if (searchData === '' && evt.target.value !== 'Select') {
      setQuerySearch(
        `status=${evt.target.value}&businessUnit=${business?.name}`
      );
    } else if (searchData !== '' && evt.target.value === 'Select') {
      setQuerySearch(
        `searchkey=${searchData.trim()}&businessUnit=${business?.name}`
      );
    }
  };
  useEffect(() => {
    if (business.name !== '0' && querySearch.length > 0) {
      setStoreLoading(true);
      TenentStoreService.filterSearchConfig(offset, rowsPerPage, querySearch)
        .then(res => {
          SetStoreData(res?.stores);
          setStoreError(null);
          setCount(res?.count[0]?.count);
          setMaxPageCount(Math.floor(res?.count[0]?.count / rowsPerPage) + 1);
        })
        .finally(() => {
          setStoreLoading(false);
        })
        .catch(error => {
          setCount(0);
          setStoreError(error.message);
        });
    }
  }, [querySearch.length, statusValue, business.name]);

  useEffect(() => {
    TenentStoreService.storeTagsOption().then(res => {
      const data = _.filter(res, function(item) {
        return _.has(item, 'type') && item.value !== '' && item;
      });
      setStoreTagsOption(_.uniqBy(data, 'type') || []);
    });
  }, []);

  return (
    <div>
      <div className={storeClass.storeImage}>
        <h4 className={storeClass.storeTitle}>Store Management</h4>
      </div>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label={storeClass.breadcrumb}
        className={storeClass.breadcrumbTitle}
      >
        <ParentLink
          color="inherit"
          to={'/general-setting'}
          className={storeClass.breadcrumbLink}
        >
          General-Setting
        </ParentLink>
        <Typography
          color="textPrimary"
          style={{ fontSize: '16px', fontFamily: 'Nunito' }}
        >
          Stores
        </Typography>
      </Breadcrumbs>

      <div className={storeClass.listPaperContainer}>
        <CreateStore
          setStoreStatus={setStoreStatus}
          storeStatus={storeStatus}
          storeTagsOption={storeTagsOption}
          business={business}
        />
        <div className={storeClass.topBox}>
          <StoreTagsBulk
            setStoreImage={setStoreTagsImage}
            StoreDownload1={StoreTagsWhite}
            StoreDownload2={StoreTagsGrey}
            storeImage={storeTagsImage}
            storeTagsOption={storeTagsOption}
          />

          <div>
            <button
              className={storeClass.uploadStoreButton}
              onClick={handleImportUsers}
            >
              <ArrowUpwardIcon
                style={{
                  marginRight: 10,
                  fontSize: 18
                }}
              />
              Upload Stores
            </button>
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleStoreTagsChange}
              style={{ display: 'none' }}
            />
          </div>
          {/* <CSVLink {...csvLink} style={{ textDecoration: 'none' }}> */}
          <button className={storeClass.exportCsv} onClick={exportCSVHandler}>
            {/* <a
              href={`data:text/csv;charset=utf-8,${escape(exportData)}`}
              download="filename.csv"
            > */}
            <ArrowDownwardIcon style={{ marginRight: 8 }} />
            <span style={{ marginRight: 8 }}>Export Stores</span>
            {/* </a> */}
          </button>
          {/* </CSVLink> */}
        </div>

        <FormControl>
          <InputLabel id="businessLabel">Business unit</InputLabel>
          <Select
            defaultValue=""
            labelId="businessLabel"
            value={business.id}
            onChange={handleBusinessChange}
            style={{ width: '200px' }}
          >
            {_.map(user.getBusinesses(), b => (
              <MenuItem value={b.id} key={b.id}>
                {b.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <Paper elevation={3} style={{ margin: '0 30px' }}>
        <div className={storeClass.searchContainer}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className={storeClass.totalStores}>
              <div style={{ marginBottom: 4, marginLeft: 10 }}>Store Name</div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className={storeClass.searchValue}>
                  <input
                    type="text"
                    value={searchData}
                    onChange={e => setSearchData(e.target.value)}
                    placeholder="Search"
                    className={storeClass.inputValue}
                  />
                  {searchData?.length === 0 ? (
                    <SearchIcon />
                  ) : (
                    <CloseIcon
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setSearchData('');
                      }}
                    />
                  )}
                </div>
                <ButtonField
                  variant="contained"
                  label={'Apply'}
                  type="button"
                  style={{
                    background: '#F07663',
                    color: 'white',
                    marginLeft: 20
                  }}
                  onClick={handleSearchApply}
                />
              </div>
            </div>
          </div>
          <div className={storeClass.filterButton}>
            <div style={{ marginLeft: 20 }}>
              <div style={{ marginBottom: 10 }}>Tags</div>
              <FormControl style={{ width: 150 }}>
                <StyledAppSelect
                  value={selectedTags}
                  variant={'outlined'}
                  onChange={evt => setSelectedTags(evt.target.value)}
                  name="selectedTags"
                  disabled
                >
                  <MenuItem disabled value={0}>
                    <span
                      style={{ marginLeft: 7, fontFamily: 'Nunito !important' }}
                    >
                      Filter by tags
                    </span>
                  </MenuItem>
                  {_.map(allTags, item => (
                    <MenuItem key={item.id} value={item.id}>
                      <span style={{ marginLeft: 7 }}>{item.name}</span>
                    </MenuItem>
                  ))}
                </StyledAppSelect>
              </FormControl>
            </div>
            <div style={{ marginLeft: 20 }}>
              <div style={{ marginBottom: 10 }}>Status</div>
              <FormControl style={{ width: 150 }}>
                <StyledAppSelect
                  value={statusValue}
                  placeholder={'Filter by tags'}
                  variant={'outlined'}
                  name={'statusValue'}
                  onChange={evt => handleStatus(evt)}
                >
                  <MenuItem disabled value={'Select'}>
                    <span>Select</span>
                  </MenuItem>
                  {_.map(status, item => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </StyledAppSelect>
              </FormControl>
            </div>
          </div>
        </div>
        <span className={storeClass.applyRef}>
          *Click apply button to search
        </span>
        <div className={storeClass.storesValue}>TOTAL STORES: {count}</div>
        <StoreManagementTable
          data={storeData || []}
          loading={storeLoading}
          offset={offset}
          rowsPerPage={rowsPerPage}
          setStoreStatus={setStoreStatus}
          storeStatus={storeStatus}
          businessName={business?.name}
          storeTagsOption={storeTagsOption}
          business={business}
          error={storeError}
        />

        <Pagination
          pageChangeHandler={pageChangeHandler}
          pageNum={pageNum}
          maxPageCount={maxPageCount}
          rowsPerPage={rowsPerPage}
          storeData={storeData}
          setPageNum={setPageNum}
          setRowsPerPage={setRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default StoreManagement;
