import config from '../../config';
import { CampaignReportResponse } from '../../models/campaign/CampaignReport';
import { has, map, set } from 'lodash';
import { CampaignReportType } from '../../components/Campaign/shared/utils/contants';
import moment from 'moment';

export const generateReport = async (
  campaignId: string
): Promise<{ scheduleDate: string }> => {
  const res = await fetch(
    `${config.ruleServerUrl}/users/published-campaigns/campaigns-data/${campaignId}/download`,
    { credentials: 'include' }
  );

  if (!res.ok) {
    throw new Error('Something went wrong!');
  }

  return res.json();
};

const formatDateForROIReport = (date: string) => {
  const format = 'YYYY-MM-DD';
  return moment(date).format(format);
};
export const generateROIReport = async (
  campaignId: any,
  businessId: any,
  costOfCampaign: any,
  startDate: any,
  endDate: any
): Promise<{ scheduleDate: string }> => {
  startDate = formatDateForROIReport(startDate);
  endDate = formatDateForROIReport(endDate);
  const res = await fetch(
    `${config.ruleServerUrl}/users/reports/download/campaign_report/${businessId}?type=IDFILTER&id=${campaignId}&campaignType=batch&costOfCampaign=${costOfCampaign}&startDate=${startDate}&endDate=${endDate}`,
    {
      credentials: 'include',
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    }
  );

  if (!res.ok) {
    throw new Error('Something went wrong!');
  }

  return res.json();
};

interface GetCampaignReports {
  campaignId: string;
  page: number;
  offset: number;
}

const getCampaignReports = async (
  data: GetCampaignReports
): Promise<CampaignReportResponse> => {
  const searchParam = new URLSearchParams(Object.entries(data)).toString();

  const response = await fetch(
    `${config.ruleServerUrl}/users/published-campaigns/campaigns-data/generated-reports/?${searchParam}`,
    {
      credentials: 'include'
    }
  );

  if (!response.ok) {
    throw new Error('Something went wrong!');
  }

  return response.json();
};

const getCampaignROIReports = async (
  data: GetCampaignReports
): Promise<CampaignReportResponse> => {
  const response = await fetch(
    `${config.ruleServerUrl}/users/reports/view/downloads/campaign_report?offset=${data.offset}&page=${data.page}&columnName=filter.filter&columnValue=${data.campaignId}`,
    { credentials: 'include' }
  );
  if (!response.ok) {
    throw new Error('Something went wrong!');
  }

  //Dead code - Just to avail backward compatibility
  const modifyResponse = (result: any) => {
    return result.then((res: any) => {
      return {
        count: result.length,
        rows: map(res, r => {
          set(r, 'data.campaignId', data.campaignId);
          if (!has(r, 'data.campaignName')) {
            set(r, 'data.campaignName', '');
          }
          return r;
        })
      };
    });
  };
  const result: any = response.json();
  return modifyResponse(result);
};

export const getHistory = (data: GetCampaignReports, type: string) => {
  if (type === CampaignReportType.ROI) {
    return getCampaignROIReports(data);
  }
  return getCampaignReports(data);
};
