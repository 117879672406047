/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useCallback, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import {
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup
} from '@material-ui/core';
import _ from 'lodash';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import useCurrentUser from '../../hooks/useCurrentUser';
import ConfigService from '../../services/config.service';
import './index.scss';
import { ParentLink } from '../../utils/ParentLink';
import IncludingTax from '../Assets/including.png';
import ExcludingTax from '../Assets/excluding.png';
import { Business } from '../../models/User.model';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch } from 'react-redux';
import { toastMessageAction } from '../../store/action';
import TextField from '@material-ui/core/TextField';

export default function LoyaltyConfig() {
  const user = useCurrentUser();
  const [offerLoyaltyType, setOfferLoyaltyType] = useState();
  const [disableSaveBtn, setDisableSaveBtn] = useState(true);
  const [business, setBusiness] = useState(new Business());
  const [exceptionRules, setExceptionRules] = useState([]);
  const [blockLoyaltyOnTax, setBlockLoyaltyOnTax] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [allowTierJumpOnFirstBill, setAllowTierJumpOnFirstBill] = useState(
    false
  );
  const [billHistoryDays, setBillHistoryDays] = useState(730);
  const [loyaltyConfig, setLoyaltyConfig] = useState({});
  const dispatch = useDispatch();

  const onSelectFieldChange = event => {
    setDisableSaveBtn(true);
    const selectedBusiness = _.find(user.getBusinesses(), {
      id: event.target.value
    });
    setBusiness(selectedBusiness);
  };

  const resetOfferLoyalty = () => {
    setOfferLoyaltyType('full_loyalty');
  };
  const saveLoyaltyConfig = () => {
    try {
      if (business.id === undefined) {
        dispatch(
          toastMessageAction({
            severity: 'error',
            message: `Business unit is missing!`,
            messageStatus: true
          })
        );
        return;
      }
      setDisableSaveBtn(true);
      const filter = { businessUnitId: business.id };
      const configObj = {
        ...loyaltyConfig,
        offerLoyaltyType: offerLoyaltyType,
        loyaltyExceptions: exceptionRules,
        blockLoyaltyOnTax: blockLoyaltyOnTax,
        allowTierJumpOnFirstBill: allowTierJumpOnFirstBill,
        billHistoryDays: +billHistoryDays || 730
      };
      ConfigService.saveLoyaltyConfig(filter, configObj).then(resp => {
        // setDisableSaveBtn(false);
        if (resp.status === 'success') {
          dispatch(
            toastMessageAction({
              severity: 'success',
              message: `Loyalty configuration saved successfully`,
              messageStatus: true
            })
          );
        }
      });
    } catch (err) {
      setDisableSaveBtn(false);
      console.error(err);
    }
  };
  const getLoyaltyConfig = useCallback(() => {
    try {
      if (business.id === undefined) {
        alert('Error: Business unit is missing!');
        return;
      }
      const filter = { businessUnitId: business.id };
      setIsLoading(true);
      ConfigService.getLoyaltyConfig(filter).then(resp => {
        setLoyaltyConfig(resp);
        if (resp) {
          if (resp.offerLoyaltyType) {
            setOfferLoyaltyType(resp.offerLoyaltyType);
          } else {
            resetOfferLoyalty();
          }
          if (resp.loyaltyExceptions) {
            setExceptionRules(resp.loyaltyExceptions);
          }
          if (resp.billHistoryDays) {
            setBillHistoryDays(resp.billHistoryDays);
          }
          setBlockLoyaltyOnTax(!!resp?.blockLoyaltyOnTax || false);
          setAllowTierJumpOnFirstBill(resp?.allowTierJumpOnFirstBill || false);
          setIsLoading(false);
        }
      });
    } catch (err) {
      console.error(err);
    }
  }, [business]);
  const setOfferLoyaltyConfigType = event => {
    setDisableSaveBtn(false);
    setOfferLoyaltyType(event.target.value);
  };

  const handleBlockLoyaltyOnTaxChange = event => {
    setDisableSaveBtn(false);
    setBlockLoyaltyOnTax(event.target.value === 'true' ? true : false);
  };

  useEffect(() => {
    setBusiness(
      _(user.getBusinesses())
        .orderBy(['id'])
        .first() || new Business()
    );
  }, [user]);

  useEffect(() => {
    if (business.id && business.id !== 0) {
      getLoyaltyConfig();
    }
  }, [business.id, getLoyaltyConfig]);

  const handleFirstBill = e => {
    setDisableSaveBtn(false);

    setAllowTierJumpOnFirstBill(e.target.value === 'true' ? true : false);
  };
  const handleTierDowngrade = e => {
    setDisableSaveBtn(false);
    setBillHistoryDays(parseInt(e.target.value));
  };
  return (
    <>
      <div className={'retenseeContainer'}>
        <ParentLink to={'/loyalty-tiers'} className={'heading'}>
          Loyalty Tier Configuration
        </ParentLink>
        <ParentLink to={'/loyalty-config'} className={'activeHeading'}>
          Loyalty Offer Configuration
        </ParentLink>
        <ParentLink to={'/loyalty-exception'} className={'heading'}>
          Loyalty exclusions Configuration
        </ParentLink>
        <ParentLink to={'/ad-hoc-points'} className={'heading'}>
          Ad Hoc Points
        </ParentLink>
        <ParentLink to={'/loyalty-redeem-configuration'} className={'heading'}>
          Loyalty Redemption Configuration
        </ParentLink>
        <ParentLink to={'/referral-configuration'} className={'heading'}>
          Referral Configuration
        </ParentLink>
      </div>
      <div style={{ margin: '20px 5%' }}>
        <div className={'fR offerBusinessUnit'}>
          <div className="unitTitle">Business unit</div>
          <Select
            value={business.id}
            key={business.id}
            onChange={onSelectFieldChange}
            style={{ width: '180px' }}
          >
            {_.map(user.getBusinesses(), b => (
              <MenuItem value={b.id} key={b.id}>
                {b.name}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      {isLoading ? (
        <CircularProgress
          color="inherit"
          style={{ height: 20, width: 20, marginLeft: 3 }}
        />
      ) : (
        <>
          <div style={{ margin: '0 5%' }}>
            <div className="formGroupStyle">
              <FormGroup>
                <h5 className="blockLoyalty"> Loyalty Offer Configuration</h5>
                <RadioGroup row>
                  <FormControlLabel
                    style={{ fontSize: '16px' }}
                    value="non_offer_bill_product_loyalty"
                    control={
                      <Radio
                        color="primary"
                        checked={
                          offerLoyaltyType === 'non_offer_bill_product_loyalty'
                        }
                        onChange={setOfferLoyaltyConfigType}
                      />
                    }
                    label={
                      <span style={{ fontSize: '16px' }}>
                        {'Bill & Product based Offers'}
                      </span>
                    }
                  />
                  <FormControlLabel
                    style={{ fontSize: '16px' }}
                    value="non_offer_bill_loyalty"
                    control={
                      <Radio
                        color="primary"
                        checked={offerLoyaltyType === 'non_offer_bill_loyalty'}
                        onChange={setOfferLoyaltyConfigType}
                      />
                    }
                    label={
                      <span style={{ fontSize: '16px' }}>
                        {'Only Bill based Offers'}
                      </span>
                    }
                  />
                  <FormControlLabel
                    style={{ fontSize: '16px' }}
                    value="non_offer_product_loyalty"
                    control={
                      <Radio
                        color="primary"
                        checked={
                          offerLoyaltyType === 'non_offer_product_loyalty'
                        }
                        onChange={setOfferLoyaltyConfigType}
                      />
                    }
                    label={
                      <span style={{ fontSize: '16px' }}>
                        {'Only Product based Offers'}
                      </span>
                    }
                  />
                  <FormControlLabel
                    style={{ fontSize: '16px !important' }}
                    value="full_loyalty"
                    control={
                      <Radio
                        color="primary"
                        checked={offerLoyaltyType === 'full_loyalty'}
                        onChange={setOfferLoyaltyConfigType}
                      />
                    }
                    label={<span style={{ fontSize: '16px' }}>{'None'}</span>}
                  />
                </RadioGroup>
              </FormGroup>
            </div>
            <div className="formGroupStyle">
              <FormGroup>
                <h5 className="blockLoyalty">
                  {'Points calculation logic for first time buyers'}
                </h5>

                <RadioGroup
                  row
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <FormControlLabel
                    style={{ fontSize: '16px' }}
                    value={false}
                    control={
                      <Radio
                        color="primary"
                        checked={allowTierJumpOnFirstBill === false}
                        onChange={handleFirstBill}
                      />
                    }
                    label={
                      <span style={{ fontSize: '16px' }}>
                        {'Based on base tier'}
                      </span>
                    }
                  />
                  <FormControlLabel
                    style={{ fontSize: '16px' }}
                    value={true}
                    control={
                      <Radio
                        color="primary"
                        checked={allowTierJumpOnFirstBill === true}
                        onChange={handleFirstBill}
                      />
                    }
                    label={
                      <span style={{ fontSize: '16px' }}>
                        {'Based on tier in which they land'}
                      </span>
                    }
                  />
                </RadioGroup>
              </FormGroup>
            </div>
            <div className="formGroupStyle">
              <FormGroup>
                <h5 className="blockLoyalty">
                  {'Loyalty Tier Downgrade Configuration'}
                </h5>
                <br />
                <div className="textFieldDiv">
                  <TextField
                    className="textFieldStyle"
                    id="outlined-helperText"
                    label="Downgrade Days"
                    placeholder="Enter the days in numeric"
                    type={'number'}
                    value={billHistoryDays}
                    variant="outlined"
                    onChange={handleTierDowngrade}
                  />
                </div>
              </FormGroup>
            </div>
          </div>
          <FormGroup>
            <h5 className="blockLoyalty">Loyalty Tax Configuration</h5>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <FormControlLabel
                style={{ display: 'flex', alignItems: 'flex-start' }}
                value={false}
                control={
                  <Radio
                    color="primary"
                    checked={blockLoyaltyOnTax === false}
                    onChange={handleBlockLoyaltyOnTaxChange}
                  />
                }
                label={
                  <div className="taxAmountStyle">
                    <span className="taxAmountTitle">Include Tax Amount</span>
                    <img
                      src={IncludingTax}
                      alt="Including Tax"
                      style={{ width: '300px', height: '300px' }}
                    />
                  </div>
                }
              />
              <FormControlLabel
                className="formControlStyle"
                style={{ display: 'flex', alignItems: 'flex-start' }}
                value={true}
                control={
                  <Radio
                    color="primary"
                    checked={blockLoyaltyOnTax === true}
                    onChange={handleBlockLoyaltyOnTaxChange}
                  />
                }
                label={
                  <div className="taxAmountStyle">
                    <span className="taxAmountTitle">Exclude Tax Amount</span>
                    <img
                      src={ExcludingTax}
                      alt="Excluding Tax"
                      style={{ width: '300px', height: '300px' }}
                    />
                  </div>
                }
              />
            </div>

            <div className="saveButtonLoyalty">
              <Button
                style={{
                  height: '40px',
                  width: '100px',
                  top: '20px',
                  fontSize: '16px'
                }}
                variant={'contained'}
                color={'primary'}
                onClick={saveLoyaltyConfig}
                disabled={disableSaveBtn}
                disableElevation
              >
                Save
              </Button>
            </div>
          </FormGroup>
        </>
      )}
    </>
  );
}
