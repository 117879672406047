import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ReactComponent as AddIcon } from '../Assets/images/icons/add_icon_round.svg';
import UtilService from '../../services/util.service';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  addImageBox: {
    display: 'flex',
    justifyContent: 'center',
    height: 200,
    border: '1px solid #DDDDDD',
    boxShadow: '0px 2px 5px #0000001A',
    margin: '20px auto',
    position: 'relative'
  },
  addImage: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  },
  imageReadOnly: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  addImageMainLabel: {
    color: theme.palette.primary.main
  },
  addImageHintLabel: {
    fontSize: '14px',
    color: '#707070'
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    width: '100%'
  },
  imageElement: {
    maxWidth: '100%',
    maxHeight: '100%',
    alignSelf: 'center'
  },
  loadingOverlay: {
    display: 'flex',
    position: 'absolute',
    background: 'rgba(34, 34, 34, 0.85)',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 1
  },
  removeOverlay: {
    display: 'flex',
    position: 'absolute',
    background: 'rgba(34, 34, 34, 0.85)',
    color: '#FFF',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    fontWeight: '700',
    opacity: 0,
    '&:hover': {
      opacity: 1
    }
  }
}));

export const ImageUpload = props => {
  const {
    onUpload,
    onRemove,
    mainLabel,
    hintLabel,
    imageUrl,
    readOnly
  } = props;

  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const inputFile = useRef(null);

  const handleImageUpload = async e => {
    setLoading(true);
    const files = Array.from(e.target.files);
    const formData = new FormData();
    files.forEach(file => {
      formData.append('image', file);
    });
    try {
      const response = await UtilService.uploadImage(formData);
      const { publicImageUrls } = response;
      onUpload(publicImageUrls[0]);
    } catch (e) {
      console.error('Something went wrong while uploading');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.addImageBox}>
      {loading && (
        <div className={classes.loadingOverlay}>
          <CircularProgress />
        </div>
      )}
      {!loading && !imageUrl && !readOnly && (
        <div
          className={classes.addImage}
          onClick={() => inputFile.current.click()}
        >
          <div>
            <AddIcon />
          </div>
          <div className={classes.addImageMainLabel}>{mainLabel}</div>
          {hintLabel && (
            <div className={classes.addImageHintLabel}>{hintLabel}</div>
          )}
          <input
            type="file"
            style={{ display: 'none' }}
            ref={inputFile}
            onChange={handleImageUpload}
          />
        </div>
      )}
      {!loading && !imageUrl && readOnly && (
        <div className={classes.imageReadOnly}>None</div>
      )}
      {!loading && imageUrl && (
        <div className={classes.imageContainer}>
          <img src={imageUrl} className={classes.imageElement} alt={'offer'} />
          {!readOnly && (
            <div className={classes.removeOverlay} onClick={onRemove}>
              <div>REMOVE</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

ImageUpload.propTypes = {
  mainLabel: PropTypes.string,
  hintLabel: PropTypes.string,
  imageUrl: PropTypes.string,
  onRemove: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  readOnly: PropTypes.bool
};

ImageUpload.defaultProps = {
  mainLabel: 'Upload Image',
  readOnly: false
};
