module.exports = {
  dashboardUrl: 'https://dashboard.casa.ajira.tech',
  ruleServerUrl: 'https://api.casa.ajira.tech',
  casaWebAppUrl: 'https://web.casa.ajira.tech',
  casaEcomUrl: 'https://ecom.casa.ajira.tech',
  casaBeatUrl: 'https://beat-api.casa.ajira.tech',
  pwaUrl: 'https://shoppersdelight.casa.ajira.tech',
  dashboardUrlV2: 'https://v2dashboard.casa.ajira.tech',
  ruleUiUrl: 'https://rules.casa.ajira.tech',
  turnedOffFeatures: [],
  keycloakConfig: {
    url: `https://auth.casa.ajira.tech/auth`,
    realm: `casa`,
    clientId: `casa_web_app`
  }
};
