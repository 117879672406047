/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-unused-expressions */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  TableRow,
  TableCell,
  TableHead,
  Table,
  TableBody,
  CircularProgress,
  createStyles
} from '@material-ui/core';
import moment from 'moment';
import { ReactComponent as GreenRect } from '../../components/Assets/images/icons/green_rounded_rect.svg';
import { ReactComponent as RedRect } from '../../components/Assets/images/icons/red_rounded_rect.svg';
import { ReactComponent as RightArrowRounded } from '../../components/Assets/Offers/rightArrowRounded.svg';
import classes from './OfferTable.module.css';
import Backdrop from '@material-ui/core/Backdrop';
import makeStyles from '@material-ui/core/styles/makeStyles';
import styled from '@material-ui/core/styles/styled';
import Checkbox from '@material-ui/core/Checkbox';
import { IndeterminateCheckBox } from '@material-ui/icons';
import { ParentLink } from '../../utils/ParentLink';

const useBackdropStyles = makeStyles(theme =>
  createStyles({
    root: {
      color: '#fff',
      position: 'absolute',
      zIndex: theme.zIndex.drawer - 1,
      backgroundColor: 'rgba(222, 222, 222, 0.5)',
      opacity: 0.5
    }
  })
);

const BorderedTableCell = styled(TableCell)({
  paddingLeft: 5,
  paddingRight: 5,
  border: '1px solid rgba(224, 224, 224, 1) !important'
});

const OfferTableRow = props => {
  const { offer, selected, handleSelect } = props;
  const {
    code,
    name,
    description,
    startDate,
    endDate,
    imageUrl,
    tags,
    status
  } = offer;
  const imageThumbnailBackground = imageUrl ? `url(${imageUrl})` : '#EEEEEE';
  const tagPills = _.map(tags, tag => (
    <div
      style={{
        background: '#F9F9F9',
        borderRadius: 15,
        padding: '5px 10px',
        marginBottom: 5,
        marginRight: 5
      }}
      key={tag.id}
    >
      {tag.name}
    </div>
  ));
  const isDrafted = status === 1;
  const isRunning =
    moment(startDate) < moment() && moment(endDate) >= moment() && !isDrafted;
  const isDisable = !isRunning && !isDrafted;
  const handleSelfSelect = event => {
    handleSelect(offer, event.target.checked);
  };
  return (
    <TableRow tabIndex={-1}>
      <BorderedTableCell>
        <Checkbox
          color={'primary'}
          onChange={handleSelfSelect}
          checked={selected}
        />
      </BorderedTableCell>
      <BorderedTableCell>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={
              isDisable
                ? {
                    opacity: 0.5,
                    background: imageThumbnailBackground,
                    height: 40,
                    width: 40,
                    backgroundSize: '100% 100%'
                  }
                : {
                    background: imageThumbnailBackground,
                    height: 40,
                    width: 40,
                    backgroundSize: '100% 100%'
                  }
            }
          />
          <div
            style={
              isDisable
                ? { color: '#dddddd', marginLeft: 15, fontWeight: 'semibold' }
                : { marginLeft: 15, fontWeight: 'semibold' }
            }
          >
            {code}
          </div>
        </div>
      </BorderedTableCell>
      <BorderedTableCell
        style={
          isDisable ? { color: '#dddddd', maxWidth: 250 } : { maxWidth: 250 }
        }
      >
        {name || ''}
      </BorderedTableCell>
      <BorderedTableCell
        style={
          isDisable ? { color: '#dddddd', maxWidth: 200 } : { maxWidth: 200 }
        }
      >
        {description || ''}
      </BorderedTableCell>
      <BorderedTableCell
        style={
          isDisable ? { color: '#dddddd', maxWidth: 250 } : { maxWidth: 250 }
        }
      >
        {moment(startDate).format('ll')}
        <br />
        {moment(startDate).format('LT')}
      </BorderedTableCell>
      <BorderedTableCell
        style={
          isDisable ? { color: '#dddddd', maxWidth: 250 } : { maxWidth: 250 }
        }
      >
        {moment(endDate).format('ll')}
        <br />
        {moment(endDate).format('LT')}
      </BorderedTableCell>
      <BorderedTableCell
        style={
          isDisable ? { color: '#dddddd', maxWidth: 350 } : { maxWidth: 350 }
        }
      >
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {tagPills.length > 0 ? tagPills : 'None'}
        </div>
      </BorderedTableCell>
      <BorderedTableCell>
        {isRunning ? (
          <div>
            <GreenRect className={classes.statusIcon} /> Running
          </div>
        ) : isDrafted ? (
          <div>
            <GreenRect className={classes.statusIcon} /> Drafted
          </div>
        ) : (
          <div>
            <RedRect className={classes.statusIcon} /> Stopped
          </div>
        )}
      </BorderedTableCell>
      <BorderedTableCell style={{ minWidth: 180 }}>
        <ParentLink
          className={classes.viewOfferLink}
          to={`/offers/view/${code}`}
        >
          View
          {!isDrafted && <span style={{ margin: '0 5px' }}>/</span>}
        </ParentLink>
        {!isDrafted && (
          <ParentLink
            className={classes.viewOfferLink}
            to={`/offers/clone-offer/${code}`}
          >
            Clone Offer
          </ParentLink>
        )}
        <span>
          <RightArrowRounded
            style={{ backgroundSize: '20px 20px', marginLeft: 8 }}
          />
        </span>
      </BorderedTableCell>
    </TableRow>
  );
};

export const OfferTable = props => {
  const {
    offers,
    selectedOffers,
    onSelectedOffersChange,
    isLoading,
    topRef
  } = props;
  const activeList = [];
  const deactiveList = [];
  offers?.map(item => {
    if (moment(item?.startDate) < moment() && moment(item?.endDate) >= moment())
      return activeList.push(item);
    else return deactiveList.push(item);
  });
  const data = activeList.concat(deactiveList);

  const selectedOfferCodesSet = new Set(_.map(selectedOffers, o => o.code));
  const isAllPagesSelected = selectedOffers.length > offers.length;

  const backdropClasses = useBackdropStyles();

  const handleRowSelect = (offer, checked) => {
    /*
      if offers from all pages are selected,
      reset the selected offers to only the current page's offers
      else use the existing selected offers
    */
    const newSelectedOfferCodesSet = isAllPagesSelected
      ? new Set(_.map(offers, o => o.code))
      : new Set(_.map(selectedOffers, o => o.code));

    if (checked) {
      newSelectedOfferCodesSet.add(offer.code);
    } else {
      newSelectedOfferCodesSet.delete(offer.code);
    }
    onSelectedOffersChange(
      _.filter(offers, o => newSelectedOfferCodesSet.has(o.code))
    );
  };

  const handlePageSelect = event => {
    if (event.target.checked) {
      onSelectedOffersChange(offers);
    } else {
      onSelectedOffersChange([]);
    }
  };

  const executeScroll = () => topRef.current.scrollIntoView();
  const resetSelectedOffers = () => onSelectedOffersChange([]);

  useEffect(executeScroll, [offers]);

  useEffect(resetSelectedOffers, [offers]);

  function checkAllOffersSelected() {
    if (selectedOfferCodesSet.size === 0) return false;
    for (const offer of offers) {
      if (!selectedOfferCodesSet.has(offer.code)) return false;
    }
    return true;
  }

  const isAllOffersSelected = checkAllOffersSelected();

  const headCells = [
    {
      id: 'select',
      content: (
        <Checkbox
          color={'primary'}
          style={{ color: '#F2755F' }}
          indeterminate={selectedOffers.length > 0 && !isAllOffersSelected}
          indeterminateIcon={<IndeterminateCheckBox color={'primary'} />}
          checked={isAllOffersSelected}
          onChange={handlePageSelect}
        />
      )
    },
    {
      id: 'code',
      content: 'Offer Code'
    },
    {
      id: 'name',
      content: 'Offer Name'
    },
    { id: 'description', content: 'Description' },
    { id: 'startDate', content: 'Start Date' },
    { id: 'endDate', content: 'End Date' },
    { id: 'tags', content: 'Mapped Tags' },
    { id: 'status', content: 'Status' },
    { id: 'viewDetail', content: 'View' }
  ];

  return (
    <div style={{ position: 'relative' }}>
      <Backdrop
        open={isLoading}
        style={{ position: 'absolute' }}
        className={backdropClasses.root}
      >
        <CircularProgress />
      </Backdrop>
      <Table>
        <TableHead>
          <TableRow>
            {_.map(headCells, headCell => (
              <BorderedTableCell
                key={headCell.id}
                style={{ fontWeight: 'bold' }}
              >
                {headCell.content}
              </BorderedTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {_.map(data, offer => (
            <OfferTableRow
              offer={offer}
              key={offer.code}
              handleSelect={handleRowSelect}
              selected={selectedOfferCodesSet.has(offer.code)}
            />
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

OfferTable.propTypes = {
  offers: PropTypes.array.isRequired,
  selectedOffers: PropTypes.array.isRequired,
  onSelectedOffersChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  topRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ]).isRequired
};
