module.exports = {
  dashboardUrl: 'https://dashboard.qa.casaretail.ai',
  ruleServerUrl: 'https://api.qa.casaretail.ai',
  casaWebAppUrl: 'https://web.qa.casaretail.ai',
  casaEcomUrl: 'https://ecom.qa.casaretail.ai',
  casaBeatUrl: 'https://beat-api.qa.casaretail.ai',
  pwaUrl: 'https://shoppersdelight.qa.casaretail.ai',
  ruleUiUrl: 'https://rules.qa.casaretail.ai',
  turnedOffFeatures: [],
  keycloakConfig: {
    url: `https://qa.auth.ginesys.one/auth`,
    realm: `GinesysOne`,
    clientId: `GinesysOne`
  }
};
