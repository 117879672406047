import React from 'react';
import config from '../config';

// Use this component for navigating the parent app (casa-dashboard)
export const ParentLink = props => {
  const { to, ...otherProps } = props;
  const toWithSlash = to.startsWith('/') ? to : `/${to}`;
  const parentTo = config.dashboardUrl + toWithSlash;

  const handleOnClick = e => {
    e.preventDefault();
    const messageData = {
      navigateTo: toWithSlash
    };
    window.parent.postMessage(messageData, config.dashboardUrl);
  };

  return (
    <a
      href={parentTo}
      target={'_parent'}
      {...otherProps}
      onClick={handleOnClick}
    >
      {props.children}
    </a>
  );
};
