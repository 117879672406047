import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import _, { find, flatten, isEmpty, map } from 'lodash';
import 'react-image-crop/dist/ReactCrop.css';
import './CustomImage.scss';
import { ImageTagger } from './ImageTagger';
import { ProductPopUpActionItem } from './ActionItem/ProductPopUp/ProductPopUpActionItem';
import { FlipbookCartContext } from '../../Hooks/FlipbookCartContext';
import { FlipbookLayoutTile } from '../../../../models/FlipbookV2/FlipbookV2LayoutTile.model';
import { ActionState } from '../../../../models/FlipbookV2/TileData/TileDataImage.model';
import { getStockItems } from '../../../../services/ecomCart.service';
import { setProductsWithStockItems } from '../../Utiles/FlipbookUtiles';
import { BusinessUnitContext } from '../../../OfferDefinition/BusinessUnitContext';
import { UserContext } from '../../../../hooks/UserContext';
import ProductService from '../../../../services/productMaster.service';
import { FlipbookContext } from '../../Hooks/FlipbookContext';

interface ViewComponentProps {
  flipbookLayoutTile: FlipbookLayoutTile | null;
}

export const ViewComponent: FC<ViewComponentProps> = ({
  flipbookLayoutTile
}) => {
  const [image, setImage] = useState('');
  const [mapArea, setMapArea] = useState<any[]>([]);
  const [currentTileProducts, setCurrentTileProducts] = useState<any[]>();
  const [isProductPopUpOpen, setIsProductPopUpOpen] = useState(false);
  const [actionItemData, setActionItemData] = useState<any>();
  const businessUnitContext = useContext(BusinessUnitContext);
  const userInfo = useContext(UserContext);
  const flipbookCartContext = useContext(FlipbookCartContext);
  const flipbookContext = useContext(FlipbookContext);
  const isCustomerView = flipbookContext?.flipbookContext?.isCustomerView;
  const flipbookToken = flipbookContext.flipbookContext?.flipbookToken || '';
  const storeCode = flipbookCartContext.storeCode;
  const tenantName =
    _.get(userInfo, 'userInfo.esKey') ||
    _.get(userInfo, 'userInfo.tenant.esKey');
  const getImageFromTile = useCallback(() => {
    return _.get(flipbookLayoutTile, `tileData.imageData.URL`);
  }, [flipbookLayoutTile]);

  const getMapAreaFromTile = useCallback(() => {
    return _.get(flipbookLayoutTile, `tileData.imageData.mapArea`, []);
  }, [flipbookLayoutTile]);

  const getSKUsFromMapArea = useCallback((mapAreas: any) => {
    const listOfSkus = map(mapAreas, mapArea => {
      return mapArea.actionState === 'SKU' ? mapArea.actionItem : [];
    });
    return flatten(listOfSkus);
  }, []);
  const updateProductDataWithStock = useCallback(
    (result: any, arrayOfSkus: any) => {
      if (isEmpty(result)) {
        return;
      }
      const isSplitShipment = _.get(
        businessUnitContext,
        'business.flipbookConfig.isSplitShipment',
        false
      );
      getStockItems(tenantName, storeCode, arrayOfSkus).then(
        stockItems => {
          const updatedProducts = setProductsWithStockItems(
            result,
            stockItems,
            isSplitShipment
          );
          setCurrentTileProducts(updatedProducts);
        },
        err => {
          setCurrentTileProducts(
            setProductsWithStockItems(result, [], isSplitShipment)
          );
        }
      );
    },
    [businessUnitContext, storeCode, tenantName]
  );
  useEffect(() => {
    const isSessionActivated = flipbookCartContext.isSessionActivated;
    if (!isSessionActivated) {
      return;
    }
    if (!_.isEmpty(flipbookLayoutTile) && _.isEmpty(image)) {
      const newImage = getImageFromTile();
      let newMapArea = getMapAreaFromTile();
      newMapArea = newMapArea || [];
      const listOfSkus = getSKUsFromMapArea(newMapArea);
      setCurrentTileProducts(listOfSkus);
      if (isCustomerView) {
        ProductService.listProductsBySkuAndToken(
          flipbookToken,
          listOfSkus
        ).then(result => {
          updateProductDataWithStock(result, listOfSkus);
        });
      } else {
        ProductService.listProductsBySku(listOfSkus).then(result => {
          updateProductDataWithStock(result, listOfSkus);
        });
      }
      setMapArea(newMapArea);
      setImage(newImage);
    }
  }, [
    image,
    flipbookLayoutTile,
    getImageFromTile,
    getMapAreaFromTile,
    getSKUsFromMapArea,
    isCustomerView,
    flipbookToken,
    updateProductDataWithStock,
    flipbookCartContext.isSessionActivated
  ]);
  const isActionItemEmpty = (area: any) => {
    return _.isEmpty(area.actionItem);
  };
  const openProductCardPopUp = (data: any) => {
    const dataPayload = find(
      currentTileProducts,
      product => product?.extendedData?.sku === data
    );
    setActionItemData(dataPayload);
    setIsProductPopUpOpen(true);
  };
  const performActionOnClick = (area: any) => {
    if (isActionItemEmpty(area)) {
      return;
    }
    const actionType = _.get(area, 'actionState', '');
    switch (actionType) {
      case ActionState.URL:
        window.open(area.actionItem, '_blank');
        break;
      case ActionState.SKU:
        openProductCardPopUp(area.actionItem);
        break;
      default:
        return '';
    }
  };
  return (
    <div className={'flipbook-map-box-edit-image'}>
      <ProductPopUpActionItem
        allProducts={actionItemData}
        setIsProductPopUpOpen={setIsProductPopUpOpen}
        isProductPopUpOpen={isProductPopUpOpen}
        cartItems={flipbookCartContext.cartItems}
        setCartItems={flipbookCartContext.setCartItems}
        setActionItemData={e => setActionItemData(e)}
      />

      <div style={{ width: '100%', height: '100%' }}>
        <img
          style={{ width: '100%', height: '100%' }}
          src={image}
          alt={'brochure'}
        />
      </div>
      <ImageTagger
        mapArea={mapArea}
        screenType={'View'}
        onClickArea={(area, index) => {
          performActionOnClick(area);
        }}
      />
    </div>
  );
};
