/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import classes from './WaitNodeWidget.module.css';
import baseNodeClasses from '../NodeWidgetHOC/BaseNodeWidget.module.css';
import { PortWidget } from '@projectstorm/react-diagrams';
import * as TrayIcons from '../Icons/svg/TrayIcons';
import { Decision } from '../Icons/svg/TrayIcons';
import { Node } from '../components/Node/Node';
import AddNode from '../Icons/svg/AddNode';
import RuleTemplatesContext from '../context/ruleTemplatesContext';
import NodeTypes from '../BaseNodeModel/NodeTypes';
import { DecisionNodeModel } from '../DecisionNode/DecisionNodeModel';
import { PortTypes } from '../BaseNodeModel/PortModels';
import _ from 'lodash';

const arrangeNode = (currentNode, link, shift, diagramEngine, forceUpdate) => {
  const node = link.targetPort.parent;
  link.remove();
  node.position.x = currentNode.position.x + shift;
  node.getPort(PortTypes.IN_PORT).position.x =
    currentNode.getPort(PortTypes.IN_PORT).position.x + shift;
  node.getPort(PortTypes.OUT_PORT).position.x =
    currentNode.getPort(PortTypes.OUT_PORT).position.x + shift;
  if (!_.isEmpty(node.getLinks())) {
    arrangeNode(
      node,
      node.getLinks()[Object.keys(node.getLinks())[0]],
      0,
      diagramEngine,
      forceUpdate
    );
  }
  const newLink = currentNode
    .getPort(PortTypes.OUT_PORT)
    .link(node.getPort(PortTypes.IN_PORT));
  diagramEngine.getModel().addAll(newLink);
  forceUpdate();
};
const arrangeConnectedNodes = (currentNode, diagramEngine, forceUpdate) => {
  const noOfLinks = currentNode.getLinksCount();
  const links = currentNode.getLinks();
  const stepDistance = 270;

  const middleNodeLinkIdx = Math.floor(Object.keys(links).length / 2);

  for (let i = 0; i < middleNodeLinkIdx; i++) {
    const shift = 0 - (middleNodeLinkIdx - i) * stepDistance;
    arrangeNode(
      currentNode,
      links[Object.keys(links)[0]],
      shift,
      diagramEngine,
      forceUpdate
    );
  }
  if (noOfLinks % 2) {
    arrangeNode(
      currentNode,
      links[Object.keys(links)[0]],
      0,
      diagramEngine,
      forceUpdate
    );
  }
  for (let i = 0; i < middleNodeLinkIdx; i++) {
    const shift = (i + 1) * stepDistance;
    arrangeNode(
      currentNode,
      links[Object.keys(links)[0]],
      shift,
      diagramEngine,
      forceUpdate
    );
  }
};

const WaitNodeWidget = props => {
  const { node, setInputFields } = props;
  const { diagramEngine, forceUpdate, setShowPopUp, isLocked } = useContext(
    RuleTemplatesContext
  );

  const showDetails = () => {
    props.showDetails(node, setInputFields, []);
    setShowPopUp(true);
  };
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    showDetails();
  }, []);
  const linkNodes = currentNode => {
    const incomingNode = new DecisionNodeModel({
      name: 'Decision Node',
      type: NodeTypes.DECISION_NODE,
      icon: NodeTypes.DECISION_NODE,
      description: 'This is a Decision Node'
    });

    incomingNode.setPosition(
      currentNode.position.x,
      currentNode.position.y + 200
    );

    const link = currentNode
      .getPort(PortTypes.OUT_PORT)
      .link(incomingNode.getPort(PortTypes.IN_PORT));
    diagramEngine.getModel().addAll(incomingNode, link);
    arrangeConnectedNodes(currentNode, diagramEngine, forceUpdate);
    forceUpdate();
  };
  const [inPorts, outPorts] = props.getPorts(node.ports);

  return (
    <div className={baseNodeClasses.container}>
      <PortWidget
        className={baseNodeClasses.inPort}
        key={inPorts[0].options.name}
        engine={props.engine}
        port={inPorts[0]}
      ></PortWidget>{' '}
      {!isLocked ? (
        <div
          className={baseNodeClasses.remove}
          onClick={e => {
            props.removeNode(e, node);
          }}
        >
          X
        </div>
      ) : (
        <></>
      )}
      <div className={classes.waitNode}>
        <Node
          isBorder={true}
          isDetailsSaved={node.isDetailsSaved}
          selected={node.isSelected() ? true : false}
          onClick={() => {
            node.showDetails = true;
            showDetails();
          }}
          name={node.name}
          IconComponent={TrayIcons[node.icon]}
        />{' '}
      </div>{' '}
      <svg
        width="30px"
        height="50px"
        viewBox="0 0 30 50"
        style={{
          position: 'relative',
          top: '-15px',
          left: '20px'
        }}
      >
        <path
          d="M15 0 v50 l-6 -6 l6 6 l6 -6"
          stroke="#F07663"
          strokeWidth="2px"
          fill="none"
        ></path>{' '}
      </svg>{' '}
      <div className={classes.node}>
        <div className={classes.nodeText}> User Decisions </div> <Decision />
        <AddNode
          onClick={() => linkNodes(node, NodeTypes.DECISION_NODE)}
          className={classes.addNode}
        />{' '}
      </div>{' '}
      {/* {node.getLinksCount() > 0 && (

                  )} */}{' '}
      <PortWidget
        className={baseNodeClasses.outPort}
        key={outPorts[0].options.name}
        engine={props.engine}
        port={outPorts[0]}
      ></PortWidget>{' '}
    </div>
  );
};

export default WaitNodeWidget;
