import React, { useEffect, useCallback } from 'react';
import { Business } from '../../models/User.model';
import UserListBanner from '../../components/Assets/images/user_management.png';
import { ReactComponent as AddIcon } from '../../components/Assets/images/icons/add_icon_round.svg';
import { ReactComponent as ImportIcon } from '../Assets/images/import_icon.svg';
import { statusFilterOptions } from './constants';
import userListClasses from './userList.module.scss';
import {
  Paper,
  Breadcrumbs,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import UserForm from './components/UserForm';
import {
  getTenantStores,
  getTenantUsersList,
  getUserRoles,
  saveUsers
} from '../../services/userlist.service';
import { ParentLink } from '../../utils/ParentLink';
import EnhancedTable from './components/UserList';
import CssBaseline from '@material-ui/core/CssBaseline';
import _, { startCase } from 'lodash';
import SelectFilter from './components/SelectFilter';
import useCurrentUser from '../../hooks/useCurrentUser';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import AlertModal from './components/AlertModal';
// import FilterAlert from './components/FilterAlert';

const RoleConfig = () => {
  const [data, setData] = React.useState([]);
  const [filter, setFilter] = React.useState({ status: '' });
  const [createUserFlag, setCreateUserFlag] = React.useState(false);
  const [seedUserRoles, setSeedUserRoles] = React.useState([]);
  const [seedTenantStores, setSeedTenantStores] = React.useState([]);
  const [showEditForm, setShowEditForm] = React.useState(false);
  const [showFilterReset, setShowFilterReset] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState({
    actual: false,
    confirm: false
  });
  const [editData, setEditData] = React.useState({ stores: [] });
  const [business, setBusiness] = React.useState(new Business());
  const [openAlert, setOpenAlert] = React.useState({
    flag: false
  });
  const [resetPasswordFlag, setResetPasswordFlag] = React.useState(false);
  const hiddenFileInput = React.useRef(null);
  const user = useCurrentUser();
  const columns = React.useMemo(
    () => [
      {
        Header: 'Email',
        accessor: 'email',
        disableFilters: true
      },
      {
        Header: 'Mobile',
        accessor: 'mobile',
        disableFilters: true
      },
      {
        Header: 'Role',
        accessor: 'role',
        Cell: ({ row }) => startCase(row.original.role),
        Filter: function renderRoleFilter(props) {
          return <SelectFilter filterOptions={seedUserRoles} {...props} />;
        }
      },
      {
        Header: 'Store',
        accessor: 'stores',
        Cell: ({ row }) => row.original.stores.join(' | '),
        Filter: function renderStoreFilter(props) {
          return <SelectFilter filterOptions={seedTenantStores} {...props} />;
        }
      },
      {
        Header: 'Status',
        accessor: 'isActive',
        Cell: function renderFlag({ row }) {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  height: 17,
                  width: 8,
                  background: row.original.isActive ? '#5FD18F' : '#CBCBCB',
                  borderRadius: 10,
                  marginRight: 10
                }}
              ></div>
              {row.original.isActive ? 'Active' : 'Inactive'}
            </div>
          );
        },
        Filter: function renderStatusFilter(props) {
          return (
            <SelectFilter filterOptions={statusFilterOptions} {...props} />
          );
        }
      }
    ],
    [seedUserRoles, seedTenantStores]
  );

  const defaultColumn = React.useMemo(
    () => ({
      Filter: ''
    }),
    []
  );

  const getUserStores = user => {
    return _.get(user, 'tenant_stores', []);
  };

  const handleBusinessUnit = stores => {
    return Array.from(
      new Set(
        stores.map(
          store =>
            store.tags.find(store => store.type === 'businessUnit')?.value
        )
      )
    ).filter(user => user);
  };

  const handleUserData = useCallback(
    userData => {
      const transformedResp = userData.map(item => {
        const businessUnit = handleBusinessUnit(getUserStores(item));
        const role = item.tenant_role_groups
          .map(roleGroup => roleGroup.name)
          .pop();
        const store = getUserStores(item).map(stores => stores.store);
        return {
          role,
          stores: store,
          id: item.id,
          email: item.email,
          mobile: item.mobile,
          isActive: item.isActive,
          businessUnit
        };
      });
      setData(transformedResp);
    },
    // eslint-disable-next-line
    []
  );

  const getUserData = useCallback(() => {
    return getTenantUsersList()
      .then(resp => handleUserData(resp))
      .catch(err => {
        console.log(err);
      });
  }, [handleUserData]);

  useEffect(() => {
    setBusiness(
      _.chain(user.getBusinesses())
        .orderBy(['id'])
        .first()
        .value() || new Business()
    );
  }, [user]);

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  useEffect(() => {
    getUserRoles()
      .then(resp => setSeedUserRoles(resp.map(item => item.name)))
      .catch(err => console.log(err));
    getTenantStores()
      .then(resp =>
        setSeedTenantStores(resp.tenant_stores.map(item => item.store))
      )
      .catch(err => console.log(err));
  }, []);

  const handleBusinessChange = event => {
    const selectedBusiness = _.find(user.getBusinesses(), {
      id: event.target.value
    });
    setBusiness(selectedBusiness);
    getUserData().then(resp => {
      const newResponse = resp.filter(item =>
        item.businessUnit.includes(selectedBusiness.name)
      );
      setData(newResponse);
    });
  };

  const handleImportUsers = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = event => {
    const fileUploaded = event.target.files[0];
    Papa.parse(fileUploaded, {
      header: true,
      skipEmptyLines: true,
      complete: results => {
        saveUsers(results.data)
          .then(resp => {
            if (resp.errorStrings) {
              const blob = new Blob(resp.errorStrings, {
                type: 'text/plain;charset=utf-8'
              });

              saveAs(blob, 'error.txt');

              setOpenAlert({
                ...openAlert,
                flag: true,
                messageTitle: 'Data Error',
                message: 'Please fill the records appropriately',
                successFlag: false
              });
              return;
            }

            setOpenAlert({
              ...openAlert,
              flag: true,
              messageTitle: 'File Upload Success',
              message: 'File Successfully uploaded',
              successFlag: true
            });
            getUserData();
          })
          .catch(err => console.error(err));
      }
    });
  };

  return (
    <div className={userListClasses.masterContainer}>
      <div>
        <img src={UserListBanner} width="100%" alt="" />
      </div>
      <div
        id={'breadcrumb'}
        style={{
          padding: 5,
          border: '1px solid #DEDEDE',
          background: '#f9f9f9'
        }}
      >
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <ParentLink
            color="inherit"
            to={'/offers'}
            className={userListClasses.breadcrumbLink}
            style={{ fontSize: '14px' }}
          >
            Configuration
          </ParentLink>
          <Typography color="textPrimary" style={{ fontSize: '14px' }}>
            User list
          </Typography>
        </Breadcrumbs>
      </div>
      <div className={userListClasses.listPaperContainer}>
        <div className={userListClasses.topBox}>
          <div style={{ display: 'flex' }}>
            <div
              className={userListClasses.createUser}
              onClick={() => {
                setShowEditForm(true);
                setCreateUserFlag(true);
              }}
            >
              <AddIcon />
              <span>Create User</span>
            </div>
            <div className={userListClasses.importUserButtonContainer}>
              <button
                className={userListClasses.importUserButton}
                onClick={handleImportUsers}
              >
                <ImportIcon
                  style={{
                    marginRight: 10
                  }}
                />
                Import users
              </button>
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: 'none' }}
              />
            </div>
          </div>
          <div>
            <FormControl>
              <InputLabel id={userListClasses.businessLabel}>
                Business unit
              </InputLabel>
              <Select
                labelId="businessLabel"
                value={business?.id}
                onChange={handleBusinessChange}
              >
                {_.map(user.getBusinesses(), b => (
                  <MenuItem value={b.id} key={b.id}>
                    {b.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <Paper elevation={3}>
          <div className={userListClasses.listContainer}>
            <CssBaseline />
            <EnhancedTable
              defaultColumn={defaultColumn}
              columns={columns}
              data={data}
              getUserData={getUserData}
              setEditData={setEditData}
              setShowEditForm={setShowEditForm}
              filter={filter}
              setFilter={setFilter}
              setResetPasswordFlag={setResetPasswordFlag}
              showFilterReset={showFilterReset}
              setShowFilterReset={setShowFilterReset}
            />
            <UserForm
              createUserFlag={createUserFlag}
              setCreateUserFlag={setCreateUserFlag}
              showPassword={showPassword}
              setShowPassword={setShowPassword}
              showEditForm={showEditForm}
              setShowEditForm={setShowEditForm}
              editData={editData}
              setEditData={setEditData}
              seedUserRoles={seedUserRoles}
              seedTenantStores={seedTenantStores}
              getUserData={getUserData}
              resetPasswordFlag={resetPasswordFlag}
              setResetPasswordFlag={setResetPasswordFlag}
            />
            <AlertModal state={openAlert} setState={setOpenAlert} />
            {/* <FilterAlert
              // state={showFilterReset}
              setState={setShowFilterReset}
            /> */}
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default RoleConfig;
