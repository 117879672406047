import React, { useState } from 'react';
import {
  FamilyValidator,
  ProductBucket,
  ProductFamilyBucket,
  ProductDiscount
} from '../../../../models/offer/OfferRule.model';
import CustomCheckbox from '../../CheckBox';
import { Button, TextField } from '@material-ui/core';
import update from 'immutability-helper';
import FamilySelectProducts from './FamilySelectProducts';
import Discount from './Discount'

export const FamilyConfigurations = (props: {
  bucket: ProductBucket;
  readOnly: boolean;
  replaceRootBucket: any;
  productFieldOptions: any;
}) => {
  let { bucket, readOnly, replaceRootBucket, productFieldOptions } = props;
  let isFamilyBucket = bucket.className === 'ProductFamilyBucket';
  let [currentValidator, setCurrentValidator] = useState({
    validator: new FamilyValidator(0),
    isOpen: false
  });

  let activeProductSelector = (
    <FamilySelectProducts
      readOnly={readOnly}
      productFieldOptions={productFieldOptions}
      familyCondition={currentValidator.validator}
      isDialogOpen={currentValidator.isOpen}
      setDialogOpen={setDialogOpen}
      updateValidator={updateValidator}
    />
  );

  function enableFamilySlot() {
    replaceRootBucket(ProductFamilyBucket.createFromProductBucket(bucket));
  }

  function disableFamilySlot() {
    replaceRootBucket(ProductBucket.createFromProductBucket(bucket));
  }

  function setDialogOpen(isOpen: boolean) {
    setCurrentValidator({
      ...currentValidator,
      isOpen: isOpen
    });
  }

  function openProductSelector(condition: FamilyValidator) {
    setCurrentValidator({
      validator: condition,
      isOpen: true
    });
  }

  function updateValidator(validator: FamilyValidator) {
    let familyBucket = bucket as ProductFamilyBucket;
    let updatedBucket = update(familyBucket, {
      conditions: {
        [validator.id]: { $set: validator }
      }
    });
    replaceRootBucket(updatedBucket);
  }

  function familyComponents() {
    let familyBucket = bucket as ProductFamilyBucket;

    const updateDiscount = (discount: ProductDiscount) => {
      replaceRootBucket(update(familyBucket, {
          familyDiscount: {
              $set: discount
          }
      }));
    }

    return (
      <div className="bg-light p-2 rounded-3 mb-4">
        {familyValidators(familyBucket)}
        <Discount 
          discount={familyBucket.familyDiscount}
          readOnly={readOnly}
          updateDiscount={updateDiscount}
          productFieldOptions={productFieldOptions}
        />
      </div>
    )
  }

  function familyValidators(familyBucket: ProductFamilyBucket) {
    return (
      <div className="px-3">
        {familyBucket.conditions.map((validator, index) => {
          return (
            <div className="d-flex align-items-center py-3" key={index}>
              <Button
                style={{
                  border: '1px solid #DEDEDE',
                  borderRadius: 4,
                  color: '#707070',
                  width: '128px'
                }}
                variant="outlined"
                color="primary"
                onClick={() => openProductSelector(validator)}
              >
                {validator.code()}
              </Button>
              <TextField
                className="aggregateText ms-3"
                type="number"
                inputProps={{ placeholder: 'Quantity' }}
                onChange={event => {
                  const { value } = event.target;
                  if (
                    !value ||
                    (value[value.length - 1].match('[0-9]') &&
                      value[0].match('[1-9]'))
                  ) {
                    const updatedValidator = update(validator, {
                      minimumQuantity: { $set: parseInt(value) }
                    });
                    updateValidator(updatedValidator);
                  }
                }}
                value={validator.minimumQuantity}
                disabled={readOnly}
              />
              <TextField
                className="aggregateText ms-3"
                inputProps={{ placeholder: 'Total' }}
                value={validator.total}
                type="number"
                onChange={event => {
                  const { value } = event.target;
                  let updatedValidator = update(validator, {
                    total: { $set: parseInt(value) }
                  });
                  updateValidator(updatedValidator);
                }}
                disabled={readOnly}
              />
            </div>
          );
        })}
        <div>
          <Button
            color="primary"
            variant="outlined"
            style={{ marginRight: 10 }}
            onClick={() => {
              replaceRootBucket(familyBucket.addValidator());
            }}
            disabled={readOnly}
            className="mt-2 mb-3"
          >
            + Add Family Condition
          </Button>
        </div>
        {activeProductSelector}
      </div>
    );
  }

  return (
    <>
      <div className="d-flex flex-row align-items-center">
        <CustomCheckbox
          checked={isFamilyBucket}
          onChange={event =>
            event.target.checked ? enableFamilySlot() : disableFamilySlot()
          }
          disabled={readOnly}
        />
        Treat as family
      </div>
      
      {isFamilyBucket && familyComponents()}
    </>
  );
};

export default FamilyConfigurations;
