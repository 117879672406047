import Intelliclose from '../components/Assets/moduleSetup/Intelliclose.svg';
import Retensee from '../components/Assets/moduleSetup/Retensee.svg';
import Pimkart from '../components/Assets/moduleSetup/Pimkart.svg';
import Personify from '../components/Assets/moduleSetup/Personify.svg';
import Flipsell from '../components/Assets/moduleSetup/Flipsell.svg';
import Ubicuity from '../components/Assets/moduleSetup/Ubicuity.svg';
import Journify from '../components/Assets/moduleSetup/Journify.svg';
import Offerio from '../components/Assets/moduleSetup/Offerio.svg';

import DisableUbicuity from '../components/Assets/moduleSetup/DisableUbicuity.svg';
import DisableIntelliclose from '../components/Assets/moduleSetup/DisableIntelliclose.svg';
import DisableRetensee from '../components/Assets/moduleSetup/DisableRetensee.svg';
import DisablePimkart from '../components/Assets/moduleSetup/DisablePimkart.svg';
import DisablePersonify from '../components/Assets/moduleSetup/DisablePersonify.svg';
import DisableFlipsell from '../components/Assets/moduleSetup/DisableFlipsell.svg';
import DisableJournify from '../components/Assets/moduleSetup/DisableJournify.svg';
import DisableOfferio from '../components/Assets/moduleSetup/DisableOfferio.svg';

export const moduleSetting = [
  {
    id: 8,
    imgSrc: Offerio,
    title: 'Offerio',
    subtitle: 'Bring in customer queries from various sources',
    disabledImgSrc: DisableOfferio,
    pathName: '/offerio-setup'
  },

  {
    id: 2,
    imgSrc: Retensee,
    title: 'Retensee',
    subtitle: 'Bring in customer queries from various sources',
    disabledImgSrc: DisableRetensee,
    pathName: '/loyalty-tiers'
  },
  {
    id: 1,
    imgSrc: Intelliclose,
    title: 'Intelliclose',
    subtitle: 'Bring in customer queries from various sources',
    disabledImgSrc: DisableIntelliclose
  },
  {
    id: 3,
    imgSrc: Pimkart,
    title: 'Pimkart',
    subtitle: 'Bring in customer queries from various sources',
    disabledImgSrc: DisablePimkart
  },
  {
    id: 4,
    imgSrc: Personify,
    title: 'Personify',
    subtitle: 'Bring in customer queries from various sources',
    disabledImgSrc: DisablePersonify
  },
  {
    id: 5,
    imgSrc: Flipsell,
    title: 'Flipsell',
    subtitle: 'Bring in customer queries from various sources',
    disabledImgSrc: DisableFlipsell
  },
  {
    id: 6,
    imgSrc: Ubicuity,
    title: 'Ubicuity',
    subtitle: 'Bring in customer queries from various sources',
    disabledImgSrc: DisableUbicuity
  },
  {
    id: 7,
    imgSrc: Journify,
    title: 'Journify',
    subtitle: 'Bring in customer queries from various sources',
    disabledImgSrc: DisableJournify
  }
];
