import React, { FC, useCallback, useContext, useEffect } from 'react';
import SelectWithAutoComplete from '../SelectWithAutoComplete/SelectWithAutoComplete';
import _, { map, without } from 'lodash';
import { FlipbookContext } from '../Hooks/FlipbookContext';
import { TenantStore } from '../../../models/User.model';
import { useHistory } from 'react-router-dom';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import { clearCart } from '../../../services/ecomCart.service';
import StoreIcon from '../../Assets/images/Icon material-store@2x.png';
import { FlipbookCartContext } from '../Hooks/FlipbookCartContext';
import isEmpty from 'lodash/isEmpty';
import { CheckoutOption } from '../../../models/FlipbookV2/flipbookV2.model';
import FlipbookPageGradient from '../Utiles/FlipbookPageGradient';
import { Drawer, TextField } from '@material-ui/core';
import {FlipbookStateContext} from "../Hooks/FlipbookStateContext";

interface StoreLocatorProps {
  currentStore: any;
  setCurrentStore: (data: any) => void;
  storeDetails: TenantStore[];
  isCustomerView: boolean;
}
const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  drawerPaper: {
    width: '100%',
    height: '60%'
  }
}));

export const StoreLocator: FC<StoreLocatorProps> = ({
  currentStore,
  setCurrentStore,
  storeDetails,
  isCustomerView
}) => {
  const [
    storeDetailsForView,
    setStoreDetailsForView
  ] = React.useState<{} | null>();
  const [storeDetailsByDistance, setStoreDetailsByDistance] = React.useState<
    TenantStore[]
  >([]);
  const [currentGeolocation, setCurrentGeolocation] = React.useState({
    currentLatitude: 0,
    currentLongitude: 0
  });
  const [userSelectedStore, setUserSelectedStore] = React.useState<any>({
    label: ''
  });
  const [searchField, setSearchField] = React.useState<string>('');
  const [selectedStoreMobile, setSelectedStoreMobile] = React.useState<any>({});
  const flipbookCartContext = useContext(FlipbookCartContext);
  const setCartItems = flipbookCartContext.setCartItems;
  const history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [
    storeSelectionWarningModalOpen,
    setStoreSelectionWarningModalOpen
  ] = React.useState(false);
  const [
    isStoreSelectorMobileOpen,
    setIsStoreSelectorMobileOpen
  ] = React.useState(false);
  const isMobileView = FlipbookPageGradient.isMobileView();
  const flipbookContext = useContext(FlipbookContext);
  const flipbookStateContext = useContext(FlipbookStateContext);
  const isStoreCheckout =
    flipbookContext.flipbookContext?.checkoutOption === CheckoutOption.Store;
  const isStoreValueLoaded = flipbookStateContext.isStoreLoadedFromQuery;
  const isUserStoreSelectionActive =
    flipbookContext.flipbookContext?.isUserStoreSelectionAvailable;

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      function(position) {
        const { latitude, longitude } = position.coords;
        setCurrentGeolocation({
          currentLatitude: latitude,
          currentLongitude: longitude
        });
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {},
      { enableHighAccuracy: true }
    );
  }, []);

  useEffect(() => {
    const { currentLatitude, currentLongitude } = currentGeolocation;
    const storeDetailsByDistance = _(storeDetails)
      .map(store => {
        const distance = getDistanceUsingLatLong(
          currentLatitude,
          currentLongitude,
          store.latitude,
          store.longitude,
          'K'
        );
        return _.extend(store, { distance: distance });
      })
      .orderBy(['distance'], ['asc'])
      .map((v, i) => {
        return _.extend(v, { order: i });
      })
      .value();
    setStoreDetailsForView(_.find(storeDetailsByDistance, { order: 0 }));
    setStoreDetailsByDistance(storeDetailsByDistance);
  }, [currentGeolocation, storeDetails]);

  function getDistanceUsingLatLong(
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number,
    unit: string
  ) {
    // Unit:
    // 'M' is statute miles (default)
    // 'K' is kilometers
    // 'N' is nautical miles

    if (lat1 === lat2 && lon1 === lon2) {
      return 0;
    } else {
      const radlat1 = (Math.PI * lat1) / 180;
      const radlat2 = (Math.PI * lat2) / 180;
      const theta = lon1 - lon2;
      const radtheta = (Math.PI * theta) / 180;
      let dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit === 'K') {
        dist = dist * 1.609344;
      }
      if (unit === 'N') {
        dist = dist * 0.8684;
      }
      return dist;
    }
  }

  const storesWithAddress = useCallback(() => {
    return _.map(storeDetailsByDistance, storeDetails => {
      const {
        store,
        name = '',
        addressLineTwo = '',
        city = '',
        state = ''
      } = storeDetails;
      return {
        value: store,
        label: `${name}, ${addressLineTwo}, ${city}, ${state}`
      };
    });
  }, [storeDetailsByDistance]);

  const restoreStore = useCallback(() => {
    const storeName = _.get(currentStore, 'store', '');
    const defaultStore = _.find(storesWithAddress(), { value: storeName }) || {
      label: ''
    };
    if (isEmpty(storeName)) {
      if (isMobileView) {
        setIsStoreSelectorMobileOpen(true);
      } else {
        setStoreSelectionWarningModalOpen(true);
      }
    }
    setUserSelectedStore(defaultStore);
  }, [currentStore, isMobileView, storesWithAddress]);
  useEffect(() => {
    if (!isStoreValueLoaded) {
      return;
    }
    if (isCustomerView) {
      restoreStore();
    }
  }, [
    currentStore,
    isCustomerView,
    isStoreValueLoaded,
    restoreStore,
    storesWithAddress
  ]);

  const onChangeUserStoreSelect = (value: string) => {
    setOpen(true);
    setUserSelectedStore(_.find(storesWithAddress(), { value: value }));
  };

  const isStoreSelectionAvailableForUsers = useCallback(() => {
    if (isStoreCheckout || isUserStoreSelectionActive) {
      return true;
    }
    return false;
  }, [isStoreCheckout, isUserStoreSelectionActive]);
  const updateStoreDetails = () => {
    const storeName = _.get(userSelectedStore, 'value', '');
    if (isCustomerView && !isEmpty(storeName)) {
      history.push({ search: `?store=${storeName}` });
    }
    setStoreDetailsForView(
      _.find(storeDetailsByDistance, { store: storeName }) ||
        storeDetailsForView
    );
    setCurrentStore(_.find(storeDetails, { store: storeName }));
  };
  const onClickWarning = () => {
    clearCart();
    setCartItems([]);
    setOpen(false);
    updateStoreDetails();
  };

  const handleClose = () => {
    setOpen(false);
    restoreStore();
  };

  function getStoreLocatorMobile() {
    return (
      <div className={'user-store-selection-container-mobile'}>
        <div
          className={'user-store-selection-text'}
          onClick={() => {
            setIsStoreSelectorMobileOpen(true);
          }}
        >
          {userSelectedStore.value || '-'}
        </div>
        <img
          src={StoreIcon}
          alt={'StoreIcon'}
          className={'flipbook-checkout-icon'}
          title={'Store'}
        />
      </div>
    );
  }
  function getStoreLocatorDesktop() {
    return (
      <div className={'user-store-selection-container'}>
        <div className={'flex-align-center user-store-selection-dropdown'}>
          <SelectWithAutoComplete
            options={storesWithAddress()}
            values={userSelectedStore}
            onChange={event =>
              onChangeUserStoreSelect(_.get(event, 'value', ''))
            }
            label={'Store Location'}
            autoCompleteSize={'small'}
            size={'100%'}
            isMultiple={false}
          />
        </div>
      </div>
    );
  }

  function getStoreLocator() {
    if (isMobileView) {
      return getStoreLocatorMobile();
    }
    return getStoreLocatorDesktop();
  }

  function getStoreSelectorMobileSearchField() {
    return (
      <div className={'store-locator-search-field'}>
        <TextField
          id="outlined-basic-store"
          label="Search Store"
          value={searchField}
          variant="outlined"
          onChange={(e: any) => {
            setSearchField(e.target.value);
          }}
        />
      </div>
    );
  }
  function getStoreSelectorMobileDropDown() {
    const updatedOptions = _.filter(
      storesWithAddress(),
      o => o.value !== null && o.label !== null
    );
    let filteredOptions = map(updatedOptions, option => {
      if (option.value.includes(searchField) || isEmpty(searchField)) {
        return option;
      }
      return undefined;
    });
    filteredOptions = without(filteredOptions, undefined);
    return (
      <div>
        {map(filteredOptions, option => {
          return (
            <div
              className={`store-selector-mobile-container ${
                selectedStoreMobile?.value === option?.value
                  ? 'store-selected-active'
                  : 'store-selected-inactive'
              }`}
              onClick={() => {
                setSelectedStoreMobile(option);
              }}
            >
              <div className={'store-selector-mobile-icon'}>
                <img
                  src={StoreIcon}
                  alt={'StoreIcon'}
                  className={'flipbook-checkout-icon'}
                  title={'Store'}
                />
              </div>
              <div className={'store-selector-mobile-details'}>
                <div className={'store-selector-mobile-header'}>
                  {option?.value}
                </div>
                <div className={'store-selector-mobile-content'}>
                  {option?.label}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
  function getStoreLocatorDrawerMobile() {
    return (
      <React.Fragment key={'bottom'}>
        <Drawer
          anchor={'bottom'}
          open={isStoreSelectorMobileOpen}
          classes={{ paper: classes.drawerPaper }}
          onClose={() => setIsStoreSelectorMobileOpen(true)}
        >
          <div className={'store-selector-drawer-mobile'}>
            {getStoreSelectorMobileSearchField()}
            {getStoreSelectorMobileDropDown()}
            <div className={'store-selector-cta'}>
              <Button
                variant="contained"
                disabled={isEmpty(selectedStoreMobile)}
                color="primary"
                style={{
                  fontSize: 16,
                  padding: 2,
                  width: '100%',
                  height: '100%'
                }}
                onClick={() =>
                  onChangeUserStoreSelect(selectedStoreMobile?.value)
                }
              >
                Select & Proceed
              </Button>
            </div>
          </div>
        </Drawer>
      </React.Fragment>
    );
  }
  return (
    <div>
      {isStoreSelectionAvailableForUsers() && getStoreLocator()}
      {
        <div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 400
            }}
          >
            <Fade in={open}>
              <div className={classes.paper}>
                <div id="transition-modal-description">
                  <Alert className={'change-number-alert'} severity="warning">
                    Warning — Your Current Cart Information Will Be Lost !
                  </Alert>
                  <div style={{ textAlign: 'end' }}>
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ fontSize: 12 }}
                        onClick={() => onClickWarning()}
                        className={'buy-now-btn'}
                      >
                        Okay
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          </Modal>
        </div>
      }
      {
        <div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={storeSelectionWarningModalOpen}
            onClose={() => {
              setStoreSelectionWarningModalOpen(false);
            }}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 400
            }}
          >
            <Fade in={storeSelectionWarningModalOpen}>
              <div className={classes.paper}>
                <div id="transition-modal-description">
                  <Alert className={'change-number-alert'} severity="warning">
                    Warning — Please choose a store to proceed
                  </Alert>
                  <div style={{ textAlign: 'end' }}>
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ fontSize: 12 }}
                        onClick={() => {
                          setStoreSelectionWarningModalOpen(false);
                        }}
                        className={'buy-now-btn'}
                      >
                        Okay
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          </Modal>
        </div>
      }
      {getStoreLocatorDrawerMobile()}
    </div>
  );
};
