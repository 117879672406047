import React, { FC, useState } from 'react';
import TierDowngradeFilter from './Filter';
import TierDowngradeReportDownloadTable from './List';
import usePagination from '../../../shared/hooks/usePagination';
import { getTierDowngradeReportDownload } from '../../../services/TenantReports/TierDowngradeReport.service';
import TierReportDownload from '../../../models/TenantReports/TierReportDownload';

const TierDowngradeTenantReport: FC = () => {
  const [refreshView, setRefreshView] = useState<string>('');
  const { data, isLoading, page, offset, setPage, setOffset } = usePagination<
    TierReportDownload[]
  >(getTierDowngradeReportDownload, [refreshView]);
  return (
    <div>
      <TierDowngradeFilter setRefreshView={setRefreshView} />
      <TierDowngradeReportDownloadTable
        reports={data || []}
        isLoading={isLoading}
        page={page}
        offset={offset}
        setPage={setPage}
        setOffset={setOffset}
      />
    </div>
  );
};

export default TierDowngradeTenantReport;
