import React from 'react';
import styled from '@emotion/styled';
import Tabs from '@material-ui/core/Tabs';

export const StyledTabs = styled(({ ...other }) => {
  return (
    <Tabs
      style={{
        width: '62%',
        marginTop: 30
      }}
      {...other}
      classes={{
        flexContainer: 'flexContainer',
        indicator: 'indicator'
      }}
      variant="fullWidth"
      TabIndicatorProps={{
        children: (
          <span
            style={{
              background: '#E46D35 0% 0% no-repeat padding-box',
              borderRadius: '3px 3px 0px 0px',
              opacity: 1,
              height: '10px'
            }}
          />
        )
      }}
      centered
    />
  );
})({
  '& .indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: '8px',

    '& > span': {
      maxWidth: 80,
      width: '100%'
    }
  },
  '& .flexContainer': {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'

    // width:'fit-content'
  },
  '& .Mui-selected': {
    color: '#F26E5F'
  },
  '& .MuiTab-wrapper': {
    fontSize: '16px !important',
    fontWeight: '700 !important'
  }
});
