import { Button, Collapse, TableCell, TableRow } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import VisibilityIcon from '@material-ui/icons/Visibility';
import clsx from 'clsx';
import moment from 'moment';
import _ from 'lodash';
import React, { FC, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Campaign from '../../../../../../models/campaign/Campaign';
import Chip from '../../../../../../shared/components/Chip/Chip';
import formatDate from '../../../../../../shared/utils/formatDate';
import { ParentLink } from '../../../../../../utils/ParentLink';
import CampaignDetail from '../CampaignDetail/CampaignDetail';
import styles from './CampaignTableBodyRow.module.scss';
import { CampaignReportType } from '../../../../shared/utils/contants';
import { CampaignFilterContext } from '../../Context/CampaignFilterContext';

interface CampaignTableBodyRowProps {
  campaign: Campaign;
  colSpan: number;
}

const CampaignTableBodyRow: FC<CampaignTableBodyRowProps> = ({
  campaign,
  colSpan
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [reportType, setReportType] = useState<CampaignReportType>(
    CampaignReportType.DEFAULT
  );
  const [messageCost, setMessageCost] = useState<any>(0);
  const { location } = useHistory();
  const campaignFilterContext = useContext(CampaignFilterContext);
  const {
    campaignId,
    campaignName,
    startDate,
    endDate,
    status,
    type
  } = campaign;

  const handleCollapseToggle = (): void => {
    setIsOpen(isExpanded => !isExpanded);
  };
  const getUrlParams = () => {
    const format = 'YYYY-MM-DD';
    const params = {
      startDate: moment(campaignFilterContext.startDate).format(format),
      endDate: moment(campaignFilterContext.endDate).format(format),
      roiCost: messageCost,
      reportType: reportType
    };
    return new URLSearchParams(params);
  };
  return (
    <>
      <TableRow className={styles.campaignTableBodyRow}>
        <TableCell align="left" className={styles.campaignName}>
          {_.capitalize(campaignName)}
        </TableCell>
        <TableCell className="text-center">
          {formatDate(startDate, 'DD-MMM-YYYY')}
        </TableCell>
        <TableCell className="text-center">
          {moment(new Date(endDate))
            .utc()
            .format('DD-MMM-YYYY')}
        </TableCell>
        <TableCell className="text-center">
          <Chip
            label={status}
            color={status === 'Running' ? 'success' : 'error'}
          />
        </TableCell>
        <TableCell className="text-center">
          <ParentLink
            to={`${location.pathname}/${campaignId}?${getUrlParams()}`}
            className={styles.viewBtn}
          >
            <VisibilityIcon />
          </ParentLink>
        </TableCell>
        <TableCell className="text-center">
          <Button
            className={clsx(styles.viewDetailBtn, { [styles.active]: isOpen })}
            endIcon={<ExpandMoreIcon />}
            onClick={handleCollapseToggle}
          >
            {isOpen ? 'Hide' : 'View'} Detail
          </Button>
        </TableCell>
      </TableRow>
      <TableRow id={styles.campaignCollapseRow}>
        <TableCell colSpan={colSpan} id={styles.campaignTableCell}>
          <Collapse in={isOpen} mountOnEnter>
            <CampaignDetail
              campaignId={campaignId}
              type={type}
              setReportType={setReportType}
              messageCost={messageCost}
              setMessageCost={setMessageCost}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
export default CampaignTableBodyRow;
