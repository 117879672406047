import { Container, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import moment from 'moment';
import React, { FC, useMemo, useState } from 'react';

import { getCampaigns } from '../../../../services/Campaign/campaign-details.service';
import usePagination from '../../../../shared/hooks/usePagination';
import CampaignFilters from './CampaignFilters/CampaignFilters';
import CampaignOverview from './CampaignOverview/CampaignOverview';
import CampaignTable from './CampaignTable/CampaignTable';
import { CampaignFilterContext } from './Context/CampaignFilterContext';

const initialFilterState = {
  search: '',
  startDate: moment()
    .subtract('months', 1)
    .toDate(),
  endDate: moment().toDate(),
  campaignType: '',
  campaignStatus: '',
  sort: ''
};

const CampaignDetails: FC = () => {
  const [partialFilters, setPartialFilters] = useState(initialFilterState);

  const {
    data: campaignsResponse,
    isLoading,
    error,
    page,
    offset,
    setPage,
    setOffset
  } = usePagination(
    ({ page, offset }) => getCampaigns({ ...partialFilters, page, offset }),
    [partialFilters]
  );

  const filters = useMemo(() => ({ ...partialFilters, page, offset }), [
    partialFilters,
    page,
    offset
  ]);

  if (error)
    return (
      <Alert severity="error" style={{ margin: '2rem' }}>
        {error}
      </Alert>
    );

  const { campaigns, totalRecords = 0 } = campaignsResponse || {};

  const handleFilterChange = <T,>(filter: { [key: string]: T }): void => {
    setPartialFilters(filters => {
      setPage(0);
      return { ...filters, ...filter };
    });
  };

  return (
    <Container maxWidth="xl" style={{ marginBlock: '3rem' }}>
      <CampaignFilterContext.Provider
        value={{
          startDate: partialFilters.startDate.toString(),
          endDate: partialFilters.endDate.toString()
        }}
      >
        <CampaignOverview filters={filters} />
        <CampaignFilters
          filters={filters}
          onFilterChange={handleFilterChange}
        />

        {!campaigns?.length && !isLoading && (
          <Typography align="center" variant="h5" style={{ marginTop: '6rem' }}>
            No Campaigns Found.
          </Typography>
        )}

        {(campaigns?.length || isLoading) && (
          <CampaignTable
            campaigns={campaigns || []}
            isLoading={isLoading}
            count={totalRecords}
            page={page}
            offset={offset}
            setPage={setPage}
            setOffset={setOffset}
          />
        )}
      </CampaignFilterContext.Provider>
    </Container>
  );
};

export default CampaignDetails;
