/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from 'react';
import StoreHealthList from './List';
import Filter from './Filter';
import { Typography, TablePagination } from '@material-ui/core';
import storeHealthStyle from './index.module.scss';
import { getStoreHealthReport } from '../../../services/TenantReports/StoreHealthDashboard.service';
import {
  filterInitialState,
  StoreHealthColumnData
} from '../../../constants/StoreHealth';

const StoreHealthDashboard = () => {
  const [filters, setFilters] = useState(filterInitialState);
  const [data, setData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const columns = React.useMemo(() => StoreHealthColumnData(), []);
  const [jobId, setJobId] = useState(0);
  const [stores, setStores] = useState([]);
  const [sorting, setSorting] = useState({ column: '', type: '' });
  const [isLoading, setIsLoading] = useState(true);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  useEffect(() => {
    if (filters.startDate < filters.endDate)
      getStoreHealthReport(
        filters.startDate,
        filters.endDate,
        page,
        rowsPerPage,
        stores,
        sorting
      ).then(res => {
        setData(res || []);
        setIsLoading(false);
      });
  }, [
    filters.startDate,
    filters.endDate,
    page,
    rowsPerPage,
    jobId,
    stores.length,
    sorting.type?.length
  ]);

  console.log(isLoading, 'isLoading');
  return (
    <div className={storeHealthStyle.storeContainer}>
      <Typography variant="h5" className={storeHealthStyle.title}>
        {'Store Health Dashboard'}
      </Typography>
      <Filter
        filters={filters}
        setFilters={setFilters}
        setJobId={setJobId}
        setStores={setStores}
        setIsLoading={setIsLoading}
      />
      <StoreHealthList
        columns={columns}
        columnsHideStautus={true}
        data={data || []}
        setSorting={setSorting}
        isLoading={isLoading}
      />
      <div className={storeHealthStyle.tablePaginationStyle}>
        <TablePagination
          count={-1}
          rowsPerPageOptions={[25, 50, 100]}
          page={page}
          rowsPerPage={rowsPerPage}
          colSpan={-1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
};

export default StoreHealthDashboard;
