import { Container } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { CampaignReportResponse } from '../../../../models/campaign/CampaignReport';
import { getHistory } from '../../../../services/Campaign/campaign-report.service';
import usePagination from '../../../../shared/hooks/usePagination';
import CampaignReportEmpty from './CampaignReportEmpty/CampaignReportEmpty';
import CampaignReportHeader from './CampaignReportHeader/CampaignReportHeader';
import CampaignReportTable from './CampaignReportTable/CampaignReportTable';
import { CampaignReportType } from '../../shared/utils/contants';
import { get, isUndefined } from 'lodash';
import qs from 'query-string';
import Loader from '../../shared/components/Loader/Loader';

const CampaignReports: FC = () => {
  const getReportType = (searchParams: any) => {
    switch (get(searchParams, 'reportType')) {
      case CampaignReportType.ROI:
        return CampaignReportType.ROI;
      default:
        return CampaignReportType.DEFAULT;
    }
  };
  const getReportCost = (searchParams: any) => {
    let cost: any = get(querySearchParams, 'roiCost', 0);
    cost = parseFloat(cost);
    if (isNaN(cost)) {
      return 0;
    }
    return cost;
  };
  const { campaignId } = useParams<{ campaignId: string }>();
  const url = new URL(window.location.href);
  const querySearchParams = qs.parse(url.searchParams.toString());

  const campaignReportType = getReportType(querySearchParams);
  const startDate: any = get(querySearchParams, 'startDate');
  const endDate: any = get(querySearchParams, 'endDate');
  const roiCost: number = getReportCost(querySearchParams);
  const [reportType, setReportType] = useState<any>();
  useEffect(() => {
    if (isUndefined(reportType)) {
      setReportType(campaignReportType);
    }
  }, [campaignReportType, reportType]);
  const {
    data,
    isLoading,
    error,
    page,
    offset,
    setPage,
    setOffset,
    refetch
  } = usePagination<CampaignReportResponse>(
    ({ page, offset, filters }) =>
      getHistory({ campaignId, page, offset }, filters),
    [reportType],
    reportType,
    isUndefined(reportType)
  );

  const { rows: reports, count }: CampaignReportResponse = data || ({} as any);

  if (error)
    return (
      <Alert severity="error" style={{ margin: '2rem' }}>
        {error}
      </Alert>
    );
  if (isLoading) {
    return <Loader />;
  }
  if (reports?.length === 0 && !isLoading)
    return <CampaignReportEmpty campaignId={campaignId} onRefetch={refetch} />;

  return (
    <Container maxWidth="lg" style={{ marginBlock: '2rem' }}>
      <CampaignReportHeader
        {...(reports?.[0] || { data: {} })?.data}
        isFetching={isLoading}
        page={page}
        reportType={reportType}
        startDate={startDate}
        endDate={endDate}
        roiCost={roiCost}
        setReportType={setReportType}
        refetch={refetch}
        goToFirstPage={setPage.bind(null, 0)}
      />
      <CampaignReportTable
        reports={reports}
        isLoading={isLoading}
        page={page}
        rowsPerPage={offset}
        reportType={reportType}
        count={count}
        setPage={setPage}
        setRowsPerPage={setOffset}
      />
    </Container>
  );
};
export default CampaignReports;
