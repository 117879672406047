import React, { useEffect, useState } from 'react';
import Form from './Form';
import customerGroupClass from '../index.module.scss';
import { Typography } from '@material-ui/core';
import { FormControl, MenuItem, InputLabel, Select } from '@material-ui/core';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { Business } from '../../../models/User.model';
import _ from 'lodash';
import EditCustomerGroupList from './List';
import { useParams } from 'react-router-dom';
import configService from '../../../services/customerGroup.service';
import ButtonField from '../../../reusable/ButtonField';

const EditCustomerGroup = () => {
  const [business, setBusiness] = useState(new Business());
  const [status, setStatus] = useState(false);
  const user = useCurrentUser();
  const { customerGroupId } = useParams();
  const [customerGroupData, setCustomerGroupData] = useState({});

  useEffect(() => {
    const selectedBusinessId = +sessionStorage.getItem('selectedBusinessId');
    setBusiness(business => {
      const businesses = _.chain(user.getBusinesses())
        .orderBy(['id'])
        .value();

      return selectedBusinessId && businesses.length > 0
        ? businesses.find(({ id }) => id === selectedBusinessId)
        : businesses[0] || new Business();
    });
  }, [user]);

  //handle business change
  const handleBusinessChange = event => {
    const selectedBusiness = _.find(user.getBusinesses(), {
      id: event.target.value
    });
    sessionStorage.setItem('selectedBusinessId', selectedBusiness.id);
    setBusiness(selectedBusiness);
  };

  useEffect(() => {
    if (business.name !== '0') {
      configService
        .getCustomerGroupById(business.name, customerGroupId)
        .then(({ data }) => {
          setCustomerGroupData({ ...data });
        });
    }
  }, [business.name, customerGroupId]);

  const handleRemoveGroup = () => {
    configService
      .removeCustomerGroupConfig(business.name, customerGroupId)
      .then(() => {
        window.top.location.href = `${window?.location?.ancestorOrigins[0]}/create-customer-group`;
      });
  };
  return (
    <>
      <div className={customerGroupClass.groupRoot}>
        <ButtonField
          label={'Remove Group'}
          type="button"
          className={customerGroupClass.removeGroupButton}
          style={{ background: '#F07663', color: 'white' }}
          onClick={handleRemoveGroup}
        />
      </div>
      <Typography
        variant={'h4'}
        className={customerGroupClass.customerGroupName}
      >
        {customerGroupData?.name || ''}
      </Typography>

      <div className={customerGroupClass.container}>
        <div className={customerGroupClass.businessId}>
          <FormControl>
            <InputLabel id="businessLabel" style={{ width: '200px' }}>
              Business unit
            </InputLabel>
            <Select
              defaultValue=""
              labelId="businessLabel"
              value={business.id}
              onChange={handleBusinessChange}
            >
              {_.map(user.getBusinesses(), b => (
                <MenuItem value={b.id} key={b.id}>
                  {b.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className={customerGroupClass.editCustomerContainer}>
          <Form business={business} status={status} setStatus={setStatus} />
          <EditCustomerGroupList
            business={business}
            status={status}
            setStatus={setStatus}
          />
        </div>
      </div>
    </>
  );
};

export default EditCustomerGroup;
