import config from '../../config/index';
import TransactionWiseReportDownload from '../../models/TenantReports/TransactionWiseReportDownload';
import moment from 'moment';

export const getUnblockLoyaltyReport = (filters: any) => {
  return fetch(
    `${config.ruleServerUrl}/users/reports/download/loyalty_member_unblock_report/${
      filters.businessId
    }?startDate=${moment(filters.startDate).format(
      'YYYY-MMM-DD'
    )}&endDate=${moment(filters.endDate).format('YYYY-MMM-DD')}
    `,
    {
      credentials: 'include',
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    }
  ).then(r => r.json());
};

export const getUnblockLoyaltyReportDownload = ({
  page,
  offset
}: {
  page: number;
  offset: number;
}): Promise<TransactionWiseReportDownload[]> => {
  return fetch(
    `${config.ruleServerUrl}/users/reports/view/downloads/loyalty_member_unblock_report?offset=${offset}&page=${page}`,
    { credentials: 'include' }
  ).then(res => res.json());
};
