import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import React from 'react';

function BreadCrumbs() {
  return (
    <>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link key="1" color="inherit" href="/role-assign">
          Role Management
        </Link>
        ,
        <Typography key="2" color="textPrimary">
          Roles
        </Typography>
      </Breadcrumbs>
    </>
  );
}

export default BreadCrumbs;
