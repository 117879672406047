/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import storeClass from './index.module.scss';
import { Tooltip } from '@material-ui/core';

export const StoreTagsBulk = props => {
  const {
    setStoreImage,
    StoreDownload1,
    StoreDownload2,
    storeImage,
    storeTagsOption
  } = props;

  const csvLink = useRef(null);
  const [headers, setHeaders] = useState([]);
  const [loaderMesg, setLoaderMesg] = useState(true);
  const initialHeader = [
    { key: 'storeName', label: 'name' },
    { key: 'storeNameAlias', label: 'storeNameAlias' },
    { key: 'store', label: 'store' },
    { key: 'shortCode', label: 'shortCode' },
    { key: 'storeTags_businessUnit', label: 'storeTags_businessUnit' },
    { key: 'mobile', label: 'mobile' },
    { key: 'startDate', label: 'startDate' },
    { key: 'manager', label: 'manager' },
    { key: 'addressLineOne', label: 'addressLineOne' },
    { key: 'addressLineTwo', label: 'addressLineTwo' },
    { key: 'state', label: 'state' },
    { key: 'city', label: 'city' },
    { key: 'pincode', label: 'pincode' },
    { key: 'latitude', label: 'latitude' },
    { key: 'longitude', label: 'longitude' },
    { key: 'gstin', label: 'gstin' }
  ];
  const businessCheck = ['business', 'bu', 'businessunit', 'business unit'];

  useEffect(() => {
    setHeaders(initialHeader);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const updatedHeader = [];
    storeTagsOption.forEach(item => {
      if (!businessCheck.includes(item.type.toLowerCase())) {
        updatedHeader.push({
          key: `storeTags_${item.type}`,
          label: `storeTags_${item.type}`
        });
      }
    });
    setHeaders(prev => [...prev, ...updatedHeader]);
    if (updatedHeader.length) {
      setLoaderMesg(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeTagsOption]);

  const data = [];

  const handleClick = () => {
    csvLink.current.link.click();
  };

  return (
    <div>
      {loaderMesg && (
        <Tooltip title={'Please wait to load store template'}>
          <button
            className={storeClass.uploadStoreButton}
            onMouseEnter={() => {
              setStoreImage(StoreDownload1);
            }}
            onMouseOut={() => {
              setStoreImage(StoreDownload2);
            }}
          >
            <img
              src={storeImage}
              alt="storeDownload"
              style={{ height: 16, width: 16, marginRight: 10 }}
            />
            Download Store Template
          </button>
        </Tooltip>
      )}
      {!loaderMesg && (
        <button
          className={storeClass.uploadStoreButton}
          onMouseEnter={() => {
            setStoreImage(StoreDownload1);
          }}
          onMouseOut={() => {
            setStoreImage(StoreDownload2);
          }}
          onClick={handleClick}
        >
          <img
            src={storeImage}
            alt="storeDownload"
            style={{ height: 16, width: 16, marginRight: 10 }}
          />
          Download Store Template
        </button>
      )}
      <CSVLink
        enclosingCharacter={``}
        data={data || []}
        headers={headers}
        filename={`Store_template.csv`}
        style={{ display: 'none' }}
        className="hidden"
        ref={csvLink}
        target="_blank"
      />
    </div>
  );
};
