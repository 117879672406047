export const OfferDefinitionClasses = {
  JOIN_CONDITION: 'JoinCondition',
  FIELD_CONDITION: 'FieldCondition',
  OPERATOR: 'Operator',
  DISCOUNT: 'Discount',
  JOIN_BUCKET: 'JoinBucket',
  OFFER_RULE: 'OfferRule',
  PRODUCT_BUCKET: 'ProductBucket'
};

export const OperatorTypes = {
  NUMBER: 'number',
  STRING: 'string'
};

export const LogicalOperators = {
  AND: 'AND',
  OR: 'OR'
};

export const NumericalOperators = {
  GTE: '>=',
  LTE: '<=',
  GT: '>',
  LT: '<',
  EQ: '=',
  BETWEEN: 'BETWEEN'
};

export const StringOperators = {
  MATCHES: 'matches',
  CONTAINS: 'contains',
  STARTS_WITH: 'startsWith',
  ENDS_WITH: 'endsWith',
  REGEX_IN: 'iRegexIn'
};

export const NumericalInputFormat = {
  PERCENT: 'percent',
  FIXED: 'fixed'
};

export const SocialMediaChannels = {
  FACEBOOK: 'Facebook',
  INSTAGRAM: 'Instagram'
};

export const FacebookActions = {
  // LIKE: 'Like',
  COMMENT: 'Comment'
};

export const InstagramActions = {
  LIKE: 'Like',
  COMMENT: 'Comment'
};

export const SocialMediaPlatformActions = {
  Facebook: FacebookActions,
  Instagram: InstagramActions
};

export const RewardTypes = {
  LOYALTY: 'Loyalty',
  OFFER: 'Offer'
};

export const AggregateTypes = {
  QUANTITY: 'quantity',
  TOTAL: 'total'
};

export const OfferType = {
  BILL_VALUE: 'BILL_VALUE',
  PRODUCT_IN_CART: 'PRODUCT_IN_CART'
};
Object.freeze(OfferType);

export const FACEBOOK_APP_ID = '761831721736241';
export const INSTAGRAM_APP_ID = '559856228239454';
export const INSTAGRAM_APP_SECRET = 'd87488f3ba15dac005bfa158495d9c2d';
