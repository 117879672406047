import config from "../../config";
import formatDate from "../../shared/utils/formatDate";
import OfferReportDownload from "../../models/TenantReports/OfferReportDownload";

export const createOfferReport = ({
  businessId,
  offset,
  page,
  startDate,
  endDate,
  groupingColumn
}: any): Promise<{ message: string; jobId: string }> => {
  return fetch(
    `${
      config.ruleServerUrl
    }/users/reports/download/offer_report/${businessId}?offset=${offset}&page=${page}&startDate=${formatDate(
      startDate,
      'YYYY-MMM-DD'
    )}&endDate=${formatDate(
      endDate,
      'YYYY-MMM-DD'
    )}&groupingColumn=${groupingColumn}`,
    {
      credentials: 'include',
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    }
  ).then(r => r.json());
};

export const getOfferReportDownload = ({
  page,
  offset,
  filters
}: {
  page: number;
  offset: number;
  filters?: any;
}): Promise<OfferReportDownload[]> => {
  const groupingColumn = filters.groupingColumn;
  return fetch(
    `${config.ruleServerUrl}/users/reports/view/downloads/offer_report?offset=${offset}&page=${page}&columnName=groupingColumn&columnValue=${groupingColumn}`,
    { credentials: 'include' }
  ).then(res => res.json());
};
