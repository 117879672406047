/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect } from 'react';
import './index.scss';
import { Checkmark } from 'react-checkmark';
import { Grid } from '@material-ui/core';
import whatsapp1 from '../Assets/channels/whatsapp1.svg';
import Messaing2 from '../Assets/channels/Messaing2.svg';
import Email3 from '../Assets/channels/Email3.svg';
import Ameyologo from '../Assets/channels/Ameyologo.png';
import Karixlogo from '../Assets/channels/Karixlogo.png';
import Commcryptlogo from '../Assets/channels/Commcryptlogo.png';
import MSG91logo from '../Assets/channels/MSG91logo.png';
import ValueFirstLogo from '../Assets/channels/ValueFirstLogo.png';
import Amazonlogo from '../Assets/channels/Amazonlogo.png';
import { ParentLink } from '../../utils/ParentLink';
import ConfigService from '../../services/config.service';
import { Business } from '../../models/User.model';
import useCurrentUser from '../../hooks/useCurrentUser';
import { FormControl, MenuItem, InputLabel, Select } from '@material-ui/core';
import _ from 'lodash';
import StmTechLogo from '../Assets/channels/StmTechLogo.png';
import ConfigModal from './ConfigModal';
import OneXtelLogo from '../Assets/channels/OneXtelLogo.png';
import {
  Msg91ModalToSchema,
  OneXtelModalToSchema,
  StmModalToSchema,
  amazonModalToSchema,
  ameyoValidationSchema,
  smsModalToSchema,
  valueFirstModalToSchema
} from '../../validation/channelsModal';
import {
  Mesg91InitialState,
  amazonInitialState,
  ameyoInitialState,
  karixInitialState,
  msg91InitialState,
  oneXtelInitialState,
  stmInitialState,
  valueFirstInitialState
} from '../../constants/configuration';

const baseInputFields = [
  { name: 'sender', label: 'Sender' },
  { name: 'priorityOrder', label: 'Priority Order' },
  { name: 'promotionalSender', label: 'Sender for Promotions' }
];
const inputFieldsOneXtel = [
  ...baseInputFields,
  { name: 'authkey', label: 'Auth Key' },
  { name: 'authKeyServiceExplicit', label: 'AuthKey Service Explicit' },
  { name: 'authKeyPromotional', label: 'AuthKey Promotional' },
  { name: 'entityId', label: 'Entity Id' }
];

const inputFieldsStm = [
  baseInputFields,
  { name: 'authkey', label: 'Auth Key' },
  { name: 'principleEntityId', label: 'Principle Entity Id' },
  { name: 'clientId', label: 'clientId' }
];

const inputFieldsValueFirst = [
  { name: 'username', label: 'User Name' },
  { name: 'password', label: 'Password' },
  ...baseInputFields
];

const inputFieldsMsg91 = [
  { name: 'authkey', label: 'Token' },
  ...baseInputFields
];

const inputFieldsAmazon = [
  { name: 'priorityOrder', label: 'Priority Order' },
  { name: 'sender', label: 'Sender (eg:CASA)' },
  { name: 'awsAccessKeyId', label: 'Aws Access Key Id' },
  { name: 'awsSecretAccessKey', label: 'Aws Secret Key Id' },
  { name: 'awsRegion', label: 'Aws  Region' }
];

const inputFieldsEmailMsg91 = [
  { name: 'priorityOrder', label: 'Priority Order' },
  { name: 'authkey', label: 'Auth key' },
  { name: 'fromName', label: 'fromName' },
  { name: 'domain', label: 'Domain' },
  { name: 'sender', label: 'Sender (eg:CASA)' }
];

const inputFieldsAmeyo = [
  { name: 'priorityOrder', label: 'Priority Order' },
  { name: 'authkey', label: 'Token' }
];

const inputFieldsKarix = [
  { name: 'priorityOrder', label: 'Priority Order' },
  { name: 'authkey', label: 'Token' },
  { name: 'from', label: 'Registered Mobile' },
  { name: 'sender', label: 'Sender (eg:CASA)' }
];

const mesgDetails = [
  {
    image: Commcryptlogo,
    label: '',
    priorityName: '',
    fields: [],
    initialState: {},
    schema: () => null,
    gridSpace: 12,
    campaignCheck: true,
    disabled: true
  },
  {
    image: Karixlogo,
    label: '',
    priorityName: '',
    fields: [],
    initialState: {},
    schema: () => null,
    gridSpace: 12,
    campaignCheck: true,
    disabled: true
  },
  {
    image: MSG91logo,
    label: 'SMS Configuration - MSG91',
    priorityName: 'Msg91SendSms',
    fields: inputFieldsMsg91,
    initialState: msg91InitialState,
    schema: smsModalToSchema,
    gridSpace: 12,
    campaignCheck: true,
    disabled: false
  },
  {
    image: ValueFirstLogo,
    label: 'SMS Configuration - Value First',
    priorityName: 'ValueFirstSendSms',
    fields: inputFieldsValueFirst,
    initialState: valueFirstInitialState,
    schema: valueFirstModalToSchema,
    gridSpace: 12,
    campaignCheck: true,
    disabled: false
  },
  {
    image: StmTechLogo,
    label: 'SMS Configuration - StmSendSms',
    priorityName: 'STMSendSms',
    fields: inputFieldsStm,
    initialState: stmInitialState,
    schema: StmModalToSchema,
    gridSpace: 6,
    campaignCheck: true,
    disabled: false
  },
  {
    image: OneXtelLogo,
    label: 'SMS Configuration - OneXtelSendSms',
    priorityName: 'OneXtelSendSms',
    fields: inputFieldsOneXtel,
    initialState: oneXtelInitialState,
    schema: OneXtelModalToSchema,
    gridSpace: 6,
    campaignCheck: true,
    disabled: false
  }
];

const emailDetails = [
  {
    image: Amazonlogo,
    label: 'Email Configuration - Amazon',
    priorityName: 'SesSendEmail',
    fields: inputFieldsAmazon,
    initialState: amazonInitialState,
    schema: amazonModalToSchema,
    gridSpace: 6,
    campaignCheck: true,
    disabled: false
  },
  {
    image: MSG91logo,
    label: 'Email Configuration - MSG91',
    priorityName: 'SendEmail',
    fields: inputFieldsEmailMsg91,
    initialState: Mesg91InitialState,
    schema: Msg91ModalToSchema,
    gridSpace: 6,
    campaignCheck: true,
    disabled: false
  }
];

const whatsAppDetails = [
  {
    image: Ameyologo,
    label: 'Whatsapp Configuration - Ameyo',
    priorityName: 'AmeyoWhatsAppMsg',
    fields: inputFieldsAmeyo,
    initialState: ameyoInitialState,
    schema: ameyoValidationSchema,
    gridSpace: 12,
    campaignCheck: false,
    disabled: false
  },
  {
    image: Karixlogo,
    label: 'Whatsapp Configuration - Karix',
    priorityName: 'SendWhatsAppMsg',
    fields: inputFieldsKarix,
    initialState: karixInitialState,
    schema: ameyoValidationSchema,
    gridSpace: 12,
    campaignCheck: false,
    disabled: false
  }
];

const Channels = () => {
  const [smsData, setSmsData] = React.useState();
  const [whatsappData, setWhatsappData] = React.useState();
  const [mailData, setMailData] = React.useState();
  const [checkerValue, setCheckerValue] = React.useState(0);
  const [getChannels, setGetChannels] = React.useState(0);
  const [createStatus, setCreateStatus] = React.useState(false);
  const [business, setBusiness] = React.useState(new Business());
  const user = useCurrentUser();
  const [configureStatus, setConfigureStatus] = React.useState({
    sms: 0,
    whatsapp: 0,
    mail: 0
  });
  useEffect(() => {
    ConfigService.getChannelConfig(business.name).then(({ channels }) => {
      setGetChannels(channels);
      if (channels) {
        channels.map(item => {
          if (item?.version === 'whatsapp') {
            setWhatsappData(item);
            setConfigureStatus({ ...configureStatus, whatsapp: 1 });
          }
          if (item?.version === 'sms') {
            setSmsData(item);
            setConfigureStatus({ ...configureStatus, sms: 1 });
          }
          if (item?.version === 'email') {
            setMailData(item);
            setConfigureStatus({ ...configureStatus, mail: 1 });
          }
        });
      }
    });
  }, [createStatus, business]);

  useEffect(() => {
    setCheckerValue(
      configureStatus.whatsapp + configureStatus.sms + configureStatus.mail
    );
  }, [configureStatus]);

  //handle business change
  const handleBusinessChange = event => {
    const selectedBusiness = _.find(user.getBusinesses(), {
      id: event.target.value
    });
    setBusiness(selectedBusiness);
  };

  useEffect(() => {
    setBusiness(
      _(user.getBusinesses())
        .orderBy(['id'])
        .first() || new Business()
    );
  }, [user]);

  return (
    <div className="root">
      <h2 className="title">Settings Configuration</h2>

      <div className="settingTitle">
        <ParentLink
          color="inherit"
          to={'/general-setting'}
          className={'breadcrumbLink'}
        >
          General Setting
        </ParentLink>
        &gt; Channels
      </div>
      <div className="businessUnitChannels">
        <FormControl>
          <InputLabel id="businessLabel">Business unit</InputLabel>
          <Select
            defaultValue=""
            labelId="businessLabel"
            value={business.id}
            onChange={handleBusinessChange}
            style={{ width: '200px' }}
          >
            {_.map(user.getBusinesses(), b => (
              <MenuItem value={b.id} key={b.id}>
                {b.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="channelsContainer">
        <div className="channelsTitle">
          <h3 style={{ fontSize: 28, fontWeight: 700 }}>Channels</h3>
          <div className="checkMarkConfigure">
            <span style={{ marginRight: 10 }}>
              {checkerValue} of 3 Configured
            </span>
            <Checkmark size={16} />
          </div>
        </div>
        <div className="channelsConfiguration">
          <Grid container spacing={2}>
            <Grid item xs={4} lg={4}>
              <div
                className="socialMedia"
                style={{ borderRight: '1px solid #D4D4D4', width: '100%' }}
              >
                <img src={whatsapp1} alt="whatsappIcon" />
                <div className="description">
                  <div style={{ display: 'flex' }}>
                    <h4 style={{ fontWeight: 600 }}>WhatsApp</h4>
                    <span className="checkmarkStyle">
                      {whatsappData?.handlers.length > 0 && (
                        <Checkmark size={16} />
                      )}
                    </span>
                  </div>
                  <p>
                    Integrate your WhatsApp business number to support customers
                    and offer instant resolutions
                  </p>
                </div>
              </div>
              <div className="configurationImageIcon">
                {whatsAppDetails.map((modalItem, i) => (
                  <ConfigModal
                    imgSrc={modalItem.image}
                    style={{ width: '88%', marginTop: 10 }}
                    spanStyle={{ marginTop: 5, marginLeft: '-6px' }}
                    version={'whatsapp'}
                    getVersion={whatsappData?.version}
                    handlers={whatsappData?.handlers}
                    priorityName={modalItem.priorityName}
                    campaignCheck={modalItem.campaignCheck}
                    disabled={modalItem.disabled}
                    inputFields={modalItem.fields}
                    initialState={modalItem.initialState}
                    fieldsToSchema={modalItem.schema}
                    getChannels={getChannels}
                    createStatus={createStatus}
                    setCreateStatus={setCreateStatus}
                    business={business}
                    width={{ width: '26%' }}
                    gridSpace={modalItem.gridSpace}
                    label={modalItem.label}
                    key={i}
                  />
                ))}
              </div>
            </Grid>
            <Grid item xs={4} lg={4}>
              <div
                className="socialMedia"
                style={{ borderRight: '1px solid #D4D4D4', width: '100%' }}
              >
                <img src={Messaing2} alt="messagingIcon" />
                <div className="description">
                  <div style={{ display: 'flex' }}>
                    <h4 style={{ fontWeight: 600 }}>Messaging</h4>
                    <span className="checkmarkStyle">
                      {smsData?.handlers.length > 0 && <Checkmark size={16} />}
                    </span>
                  </div>
                  <p>
                    Offer instantaneous support on your website or app with
                    Freshdesk Messaging
                  </p>
                </div>
              </div>
              <div className="configurationImageIcon">
                {mesgDetails.map((modalItem, i) => (
                  <ConfigModal
                    imgSrc={modalItem.image}
                    style={{ width: '88%', marginTop: 10 }}
                    spanStyle={{ marginTop: 5, marginLeft: '-6px' }}
                    version={'sms'}
                    getVersion={smsData?.version}
                    handlers={smsData?.handlers}
                    priorityName={modalItem.priorityName}
                    campaignCheck={modalItem.campaignCheck}
                    inputFields={modalItem.fields}
                    initialState={modalItem.initialState}
                    fieldsToSchema={modalItem.schema}
                    getChannels={getChannels}
                    createStatus={createStatus}
                    setCreateStatus={setCreateStatus}
                    business={business}
                    disabled={modalItem.disabled}
                    width={{ width: '26%' }}
                    gridSpace={modalItem.gridSpace}
                    label={modalItem.label}
                    key={i}
                  />
                ))}
              </div>
            </Grid>
            <Grid item xs={4} lg={4}>
              <div className="socialMedia">
                <img src={Email3} alt="emailIcon" />
                <div className="description">
                  <div style={{ display: 'flex' }}>
                    <h4 style={{ fontWeight: 600 }}>Email</h4>
                    <span className="checkmarkStyle">
                      {mailData?.handlers.length > 0 && <Checkmark size={16} />}
                    </span>
                  </div>
                  <p>
                    Integrate support mailboxes, configure DKIM, custom mail
                    servers, Bcc and more
                  </p>
                </div>
              </div>
              <div
                className="configurationImageIcon"
                style={{ justifyContent: 'flex-start' }}
              >
                {emailDetails.map((modalItem, i) => (
                  <ConfigModal
                    imgSrc={modalItem.image}
                    style={{ width: '88%', marginTop: 10 }}
                    spanStyle={{ marginTop: 5, marginLeft: '-6px' }}
                    version={'email'}
                    getVersion={mailData?.version}
                    handlers={mailData?.handlers}
                    priorityName={modalItem.priorityName}
                    campaignCheck={modalItem.campaignCheck}
                    inputFields={modalItem.fields}
                    initialState={modalItem.initialState}
                    fieldsToSchema={modalItem.schema}
                    getChannels={getChannels}
                    createStatus={createStatus}
                    setCreateStatus={setCreateStatus}
                    business={business}
                    disabled={modalItem.disabled}
                    width={{ width: '26%' }}
                    gridSpace={modalItem.gridSpace}
                    label={modalItem.label}
                    key={i}
                  />
                ))}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default Channels;
